import { localizePath } from '../dataImplementation/viewingRegionWeb'
import { Locale } from 'data/src/utils'

export interface UrlLink {
  as: string
  to: string
}

export function getCompanies(): UrlLink {
  return {
    as: '/virgin-companies',
    to: '/virgin-companies',
  }
}

export function getCompany(slug: string): UrlLink {
  return {
    as: `/virgin-companies/${slug}`,
    to: '/virgin-companies/[slug]',
  }
}

export function getBlogArticleUrl(authorSlug: string, articleSlug: string): UrlLink {
  return {
    as: `/branson-family/${authorSlug}/${articleSlug}`,
    to: '/branson-family/[authorSlug]/[slug]',
  }
}

export function getCareersArticleUrl(slug: string): UrlLink {
  return {
    as: `/about-virgin/virgin-careers/latest-careers/${slug}`,
    to: '/about-virgin/virgin-careers/latest-careers/[slug]',
  }
}

export function getAboutVirgin(): UrlLink {
  return {
    as: '/about-virgin',
    to: '/about-virgin',
  }
}

export function getVirginGroup(): UrlLink {
  return {
    as: '/about-virgin/virgin-group',
    to: '/about-virgin/virgin-group',
  }
}

export function getVirginGroupCareers(): UrlLink {
  return {
    as: '/about-virgin/virgin-careers',
    to: '/about-virgin/virgin-careers',
  }
}

export function getVirginGroupTimeline(): UrlLink {
  return {
    as: '/about-virgin/timeline',
    to: '/about-virgin/timeline',
  }
}

export function getVirginGroupNews(): UrlLink {
  return {
    as: '/about-virgin/virgin-group/news',
    to: '/about-virgin/virgin-group/news',
  }
}

export function getOurApproach(): UrlLink {
  return {
    as: '/about-virgin/virgin-group/our-approach',
    to: '/about-virgin/virgin-group/our-approach',
  }
}

export function getSeniorTeam(): UrlLink {
  return {
    as: '/about-virgin/virgin-group/senior-team',
    to: '/about-virgin/virgin-group/senior-team',
  }
}

export function getBeingAVirginBusiness(): UrlLink {
  return {
    as: '/about-virgin/virgin-group/being-a-virgin-business',
    to: '/about-virgin/virgin-group/being-a-virgin-business',
  }
}

export function getVirginGroupNewsArticleUrl(slug: string): UrlLink {
  return {
    as: `/about-virgin/virgin-group/news/${slug}`,
    to: '/about-virgin/virgin-group/news/[slug]',
  }
}
export function getVirginUniteWebsite(): UrlLink {
  return {
    as: 'https://unite.virgin.com',
    to: 'https://unite.virgin.com',
  }
}

export function getTimeline(): UrlLink {
  return {
    as: '/about-virgin/timeline',
    to: '/about-virgin/timeline',
  }
}

export function getAboutVirginLatest(): UrlLink {
  return {
    as: '/about-virgin/latest',
    to: '/about-virgin/latest',
  }
}
export function getAboutVirginLatestArticle(slug: string): UrlLink {
  return {
    as: `/about-virgin/latest/${slug}`,
    to: '/about-virgin/latest/[slug]',
  }
}

export function getVirginUnite(): UrlLink {
  return {
    as: '/virgin-unite',
    to: '/virgin-unite',
  }
}

export function getVirginUniteLatest(): UrlLink {
  return {
    as: '/virgin-unite/latest',
    to: '/virgin-unite/latest',
  }
}

export function getVirginUniteLatestArticle(slug: string): UrlLink {
  return {
    as: `/virgin-unite/latest/${slug}`,
    to: '/virgin-unite/latest/[slug]',
  }
}

export function getBransonFamilyHome(): UrlLink {
  return {
    as: `/branson-family`,
    to: `/branson-family`,
  }
}

export function getBransonFamilyMemberStory(slug: string): UrlLink {
  return {
    as: `/branson-family/${slug}`,
    to: `/branson-family/${slug}`,
  }
}

export function getOurStory(): UrlLink {
  return {
    as: '/about-virgin/our-story',
    to: '/about-virgin/our-story',
  }
}

export function getBooks(): UrlLink {
  return {
    as: '/branson-family/books',
    to: '/branson-family/books',
  }
}

export function getBookArticle(authorSlug: string, articleSlug: string): UrlLink {
  return {
    as: `/branson-family/books/${authorSlug}/${articleSlug}`,
    to: '/branson-family/books/[authorSlug]/[slug]',
  }
}

export function getBookABranson(): UrlLink {
  return {
    as: '/branson-family/book-a-branson',
    to: '/branson-family/book-a-branson',
  }
}

export function getTermsAndConditions(): UrlLink {
  return {
    as: '/terms-and-conditions',
    to: '/terms-and-conditions',
  }
}

export function getTaxStrategyStatment(): UrlLink {
  return {
    as: '/tax-strategy-statement',
    to: '/tax-strategy-statement',
  }
}

export function getPrivacyPolicy(): UrlLink {
  return {
    as: '/privacy-policy',
    to: '/privacy-policy',
  }
}

export function getModernSlaveryAct(): UrlLink {
  return {
    as: '/modern-slavery-act',
    to: '/modern-slavery-act',
  }
}

export function getCookiePolicy(): UrlLink {
  return {
    as: '/cookie-policy',
    to: '/cookie-policy',
  }
}

export function getContactUs(): UrlLink {
  return {
    as: '/contact-us',
    to: '/contact-us',
  }
}

export function getReportAScam(): UrlLink {
  return {
    as: '/report-a-scam',
    to: '/report-a-scam',
  }
}

export function getDarkSiteArticle(slug: string): UrlLink {
  return {
    as: `/about-virgin/latest/${slug}`,
    to: '/about-virgin/latest/[slug]',
  }
}

export function getRedHome(): UrlLink {
  return {
    as: `/virgin-red`,
    to: `/virgin-red`,
  }
}

export function getRedSignup(): UrlLink {
  return {
    as: `/virgin-red/sign-up`,
    to: `/virgin-red/sign-up`,
  }
}

export function getMyRedRewards(): UrlLink {
  return {
    as: `/virgin-red/my-rewards`,
    to: `/virgin-red/my-rewards`,
  }
}

export function getSpendVirginPoints(): UrlLink {
  return {
    as: `/virgin-red/spend-virgin-points`,
    to: `/virgin-red/spend-virgin-points`,
  }
}

export function getEarnVirginPoints(): UrlLink {
  return {
    as: `/virgin-red/earn-virgin-points`,
    to: `/virgin-red/earn-virgin-points`,
  }
}

export function getSpendVirginPointsReward(rewardId: string): UrlLink {
  return {
    as: `/virgin-red/spend-virgin-points/${rewardId}`,
    to: `/virgin-red/spend-virgin-points/${rewardId}`,
  }
}

export function getEarnVirginPointsReward(rewardId: string): UrlLink {
  return {
    as: `/virgin-red/earn-virgin-points/${rewardId}`,
    to: `/virgin-red/earn-virgin-points/${rewardId}`,
  }
}

export function getCheckout(): UrlLink {
  return {
    as: `/virgin-red/checkout`,
    to: `/virgin-red/checkout`,
  }
}

export function getCheckoutSuccess(orderId: string): UrlLink {
  return {
    as: `/virgin-red/checkout/success?orderId=${orderId}`,
    to: `/virgin-red/checkout/success`,
  }
}

export function getVoucherCheckoutSuccess(voucherId: string): UrlLink {
  return {
    as: `/virgin-red/checkout/success?voucherId=${voucherId}`,
    to: `/virgin-red/checkout/success`,
  }
}

export function getRedReward(slug: string): UrlLink {
  return {
    as: `/virgin-red/rewards/${slug}`,
    to: `/virgin-red/rewards/[slug]`,
  }
}

export function getRedLogin(): UrlLink {
  return {
    as: `/virgin-red/login`,
    to: `/virgin-red/login`,
  }
}

export function getRedMyAccount(): UrlLink {
  return {
    as: `/virgin-red/account`,
    to: `/virgin-red/account`,
  }
}
export function getRedMyActivity(): UrlLink {
  return {
    as: `/virgin-red/activity`,
    to: `/virgin-red/activity`,
  }
}

export function getRedPrivacyPolicy(): UrlLink {
  return {
    as: `/virgin-red/privacy-policy`,
    to: `/virgin-red/privacy-policy`,
  }
}

export function getRedContactUs(): UrlLink {
  return {
    as: `/virgin-red/contact-us`,
    to: `/virgin-red/contact-us`,
  }
}

export const getUrlParameter = (name: string): string => {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const replaceLocale = (url: string, locale?: Locale) => {
  return url.startsWith('/virgin-red') ? localizePath(url.replace('/virgin-red', ''), locale) : url
}
