import React, { FC, useCallback } from 'react'
import { getString } from 'data'
import { ContentfulLocale } from 'data/src/utils/contentfulPages'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { Page } from '../../components'
import H1Header from '../../components/typography/Red/H1Header'
import { DynamicContentContainer } from './DynamicContentContainer'
import { color, spacing } from '../../style/variables'

interface PrivacyPolicyProps {
  doFetchPrivacyPolicy: (viewingRegion?: ContentfulLocale | string) => void
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ doFetchPrivacyPolicy }) => {
  const viewingRegion = localeToRegion(getLocale())

  const onRender = useCallback(
    () => (viewingRegion ? doFetchPrivacyPolicy(`EN_${viewingRegion}`) : doFetchPrivacyPolicy()),
    [doFetchPrivacyPolicy, viewingRegion]
  )
  return (
    <Page
      testId="page-privacy"
      title="Privacy Policy | Virgin Red"
      navigationFocusHandler={onRender}
      metaDescription={getString('meta.description.privacy.policy')}
    >
      <H1Header
        color={color.textHeader}
        textAlign="center"
        marginTop={spacing.medium}
        marginBottom={spacing.medium}
        textTransform="uppercase"
      >
        {getString('privacyPolicy.title')}
      </H1Header>
      <DynamicContentContainer />
    </Page>
  )
}

export { PrivacyPolicy }
