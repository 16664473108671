import { connect } from 'react-redux'

import { RootState } from '../../redux/types'
import { SeoHeaders } from './SeoHeaders'

import { getSelectedPublicReward } from 'data/src/redux/rewardsPublic/selectors'
import { getSelectedPublicEarnReward } from 'data/src/redux/rewardsEarnPublic/selectors'

const mapStateToProps = (state: RootState) => ({
  selectedPublicSpendReward: getSelectedPublicReward(state),
  selectedPublicEarnReward: getSelectedPublicEarnReward(state),
})

const SeoHeadersContainer = connect(mapStateToProps)(SeoHeaders)

export { SeoHeadersContainer }
