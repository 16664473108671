import React, { useEffect, useState } from 'react'
import { color } from 'web/src/style/variables'
import { Content, H1Header, LoginBox, Page, PageError404, PageLoader, Picture, ToggleSlider } from '../../../components'
import { media } from 'web/src/style/media'
import { optimizeContentfulImageUrl } from 'web/src/helpers/images'
import { RewardsList } from './RewardsList'
import { useAppDispatch, useAppSelector } from 'web/src/redux/hooks/hooks'
import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { getAllRewardsEntities as getEarnRewards } from 'data/src/redux/rewardsEarn/selectors'
import { getAllRewardsEntities as getSpendRewards } from 'data/src/redux/rewards/selectors'
import { getRewardsEntities as getPublicEarnRewards } from 'data/src/redux/rewardsEarnPublic/selectors'
import { getRewardsEntities as getPublicSpendRewards } from 'data/src/redux/rewardsPublic/selectors'
import { getIsLoadingEarnPublic } from 'data/src/redux/discoveryEarnPublic/selectors'
import { getIsLoadingEarn } from 'data/src/redux/discoveryEarn/selectors'
import { getIsLoading as getIsLoadingSpendPublic } from 'data/src/redux/discoveryPublic/selectors'
import { getIsLoading } from 'data/src/redux/discovery/selectors'
import { doUpdateRewards } from 'data/src/redux/discovery/dispatchers'
import { doUpdateRewardsEarn } from 'data/src/redux/discoveryEarn/dispatchers'
import { doUpdateRewardsEarnPublic } from 'data/src/redux/discoveryEarnPublic/dispatchers'
import { doUpdateRewardsPublic } from 'data/src/redux/discoveryPublic/dispatchers'
import { Reward } from 'data/src/redux/rewards/types'
import { RewardEarn } from 'data/src/redux/rewardsEarn/types'
import { apiGetBrandBySlug } from 'data/src/api/brands.api'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getViewingRegion } from '../../../dataImplementation/viewingRegionWeb'

export type Tabs = 'earn' | 'spend'

const containsRewardsForBrand = (brandName: string, rewards: { [rewardId: string]: Reward | RewardEarn }) =>
  Object.values(rewards).some((reward) => reward.content.brandDetails.brandName === brandName)

export const BrandDetails = () => {
  const { brandPath = '' } = useParams()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const earnRewards = useAppSelector(getEarnRewards)
  const spendRewards = useAppSelector(getSpendRewards)
  const publicEarnRewards = useAppSelector(getPublicEarnRewards)
  const publicSpendRewards = useAppSelector(getPublicSpendRewards)
  const earnRewardsLoading = useAppSelector(getIsLoadingEarn)
  const spendRewardsLoading = useAppSelector(getIsLoading)
  const publicEarnRewardsLoading = useAppSelector(getIsLoadingEarnPublic)
  const publicSpendRewardsLoading = useAppSelector(getIsLoadingSpendPublic)
  const isLoading = [earnRewardsLoading, spendRewardsLoading, publicEarnRewardsLoading, publicSpendRewardsLoading].some(
    (loading) => loading === true
  )

  const [selectedTab, setSelectedTab] = useState<Tabs>('earn')
  const [delayRender, setDelayRender] = useState(true)
  const viewingRegion = getViewingRegion()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(doUpdateRewardsEarn())
      dispatch(doUpdateRewards())
    } else {
      dispatch(doUpdateRewardsEarnPublic())
      dispatch(doUpdateRewardsPublic())
    }
    setDelayRender(false)
  }, [isAuthenticated, dispatch])

  const { isPending, isError, data } = useQuery({
    queryKey: ['brands', isAuthenticated, brandPath, viewingRegion],
    queryFn: async () => await apiGetBrandBySlug(isAuthenticated, brandPath, viewingRegion),
  })

  if (isPending) {
    return (
      <Page>
        <PageLoader />
      </Page>
    )
  }

  if (isError) {
    return (
      <Page title={`Whoops. 404 Brand not found | Virgin Red`}>
        <PageError404 />
      </Page>
    )
  }

  const { brandLogo = '', brandName = '', brandDescription = '' } = data ?? {}

  const hasEarnRewards = containsRewardsForBrand(brandName, isAuthenticated ? earnRewards : publicEarnRewards)
  const hasSpendRewards = containsRewardsForBrand(brandName, isAuthenticated ? spendRewards : publicSpendRewards)

  const hasEarnAndSpendRewards = hasEarnRewards && hasSpendRewards
  const brandMessage = `${hasSpendRewards ? 'Spend' : 'Earn'} points with ${brandName}`

  const responsiveImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 }),
      hiResImgSrc: optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 }),
    },
  }

  const handleOnClick = (key: string) => setSelectedTab(key as Tabs)
  const tabs = [
    {
      key: 'earn',
      name: 'Earn',
      onClick: handleOnClick,
    },
    {
      key: 'spend',
      name: 'Spend',
      onClick: handleOnClick,
    },
  ]

  return (
    <>
      <style jsx>{`
        .copy {
          font-size: 18px;
          margin: 0;
        }
        .heading {
          display: flex;
          flex-flow: wrap;
          margin-bottom: 32px;
        }
        .logo {
          box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
          width: 144px;
          height: 144px;
          flex-shrink: 0;
          margin: 32px auto 0;
        }
        .content {
          width: 100%;
        }
        .login-banner {
          margin-bottom: 34px;
        }
        .login-banner :global(.header-two) {
          margin-top: 0;
        }
        .login-banner :global(.reward-cta-block) {
          padding: 40px 0 56px 0;
        }
        .brand-message {
          font-weight: 600;
          font-size: 28px;
        }
        @media ${media.tabletAndHigher} {
          .heading {
            flex-flow: row;
          }
          .copy {
            font-size: 16px;
          }
          .content {
            margin-left: 40px;
            flex-grow: 1;
          }
          .login-banner {
            margin-bottom: 42px;
          }
          .login-banner :global(.reward-cta-block) {
            padding: 64px 0;
          }
          .login-banner :global(h2),
          .login-banner :global(p),
          .login-banner :global(.reward-cta-actions) {
            max-width: 100%;
          }
          .login-banner :global(.cta-action:first-child) {
            margin-bottom: 0;
          }
        }
        @media ${media.desktopAndHigher} {
          .copy {
            font-size: 20px;
            max-width: 70%;
          }
          .heading {
            margin-bottom: 72px;
          }
          .logo {
            width: 160px;
            height: 160px;
          }
          .login-banner {
            margin-bottom: 34px;
          }
          .login-banner :global(h2),
          .login-banner :global(p),
          .login-banner :global(.reward-cta-actions) {
            max-width: 1000px;
          }
        }
      `}</style>
      <Page title={brandName}>
        <Content>
          <div className="heading">
            <div className="logo">
              <Picture
                altText={`${brandName} logo`}
                className="responsive-img--override"
                width={160}
                fallbackImg={optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 })}
                height={160}
                responsiveImages={responsiveImages}
                loading={'lazy'}
                isWebP={true}
              />
            </div>
            <div className="content">
              <H1Header
                color={color.textHeader}
                fontSize={{ desktop: 40, tablet: 28, mobile: 28 }}
                fontStyle="normal"
                marginTop={{ desktop: 32, tablet: 32, mobile: 32 }}
                marginBottom={{ desktop: 16, tablet: 16, mobile: 8 }}
                weight={600}
              >
                {brandName}
              </H1Header>
              <p className="copy">{brandDescription}</p>
            </div>
          </div>
        </Content>
        {!isAuthenticated && (
          <div className="login-banner">
            <LoginBox isEarn />
          </div>
        )}
        <Content>
          {!isLoading &&
            (hasEarnAndSpendRewards ? (
              <ToggleSlider tabs={tabs} selected={selectedTab} />
            ) : (
              <h2 className="brand-message">{brandMessage}</h2>
            ))}
          <RewardsList selectedTab={selectedTab} brandName={brandName} delayRender={delayRender} />
        </Content>
      </Page>
    </>
  )
}
