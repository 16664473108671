import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { convertToKebabCase } from '../../helpers/text'

import { FontFamilies, color } from '../../style/variables'
import { localizePath } from '../../dataImplementation/viewingRegionWeb'

export interface IPageNavItem {
  linkUrl: string
  linkText: string
}

export const InPageNavigationItem: React.FC<IPageNavItem> = ({ linkUrl, linkText }) => {
  return (
    <>
      <style jsx>{`
        .in-page-nav-item {
          margin: 10px 0;
        }

        :global(.in-page-nav-link) {
          border-left: 2px solid transparent;
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 14px;
          letter-spacing: 1.3px;
          padding-left: 8px;
          text-decoration: none;
          text-transform: uppercase;
        }

        :global(.in-page-nav-link):hover {
          color: ${color.brandPrimary};
        }

        :global(.in-page-nav-link--active) {
          border-left: 2px solid ${color.brandPrimary};
          color: ${color.brandPrimary};
        }
      `}</style>
      <li className="in-page-nav-item">
        <Link
          data-testid={convertToKebabCase(linkText)}
          to={localizePath(linkUrl)}
          title={linkText}
          aria-label={linkText}
          className={cx('in-page-nav-link', {
            'in-page-nav-link--active': window.location.href.includes(linkUrl),
          })}
        >
          {linkText}
        </Link>
      </li>
    </>
  )
}
