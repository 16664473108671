import { connect } from 'react-redux'

import {
  getIsFiltersChangedEarnPublic,
  getIsFiltersSetEarnPublic,
  getIsLastResultFilteredEarnPublic,
  getSelectedCategoriesEarnPublic,
} from 'data/src/redux/discoveryEarnPublic/selectors'
import { doUpdateRewardsEarnPublic } from 'data/src/redux/discoveryEarnPublic/dispatchers'
import { clearCategoryEarnPublicFilter, toggleCategorySelectedEarnPublic } from 'data/src/redux/discoveryEarnPublic/actions'
import { doFetchCategoriesEarnPublic } from 'data/src/redux/categoriesEarnPublic/dispatchers'
import { getCategoriesEarnPublic } from 'data/src/redux/categoriesEarnPublic/selectors'
import { resetPagination } from 'data/src/redux/discovery/actions'

import { RootState, DispatchFunction } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'

import { RewardsFilter } from './RewardsFilter'

const mapStateToProps = (state: RootState) => ({
  categories: getCategoriesEarnPublic(state),
  selectedCategories: getSelectedCategoriesEarnPublic(state),
  isFiltersChanged: getIsFiltersChangedEarnPublic(state),
  isLastResultsFiltered: getIsLastResultFilteredEarnPublic(state),
  isFiltersSet: getIsFiltersSetEarnPublic(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      toggleCategorySelected: toggleCategorySelectedEarnPublic,
      clearCategoryFilter: clearCategoryEarnPublicFilter,
      clearAllFilters: clearCategoryEarnPublicFilter,
      doFetchCategories: doFetchCategoriesEarnPublic,
      doUpdateRewards: doUpdateRewardsEarnPublic,
      clearPaginationFromView: resetPagination,
    },
    dispatch
  )

export const PublicRewardsFilterEarnContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsFilter)
