import { Reward, RewardType } from '../redux/rewards/types'
import { RewardEarn } from '../redux/rewardsEarn/types'

interface IntroCarouselTileProps {
  type: RewardType
  rewardId: string
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string
  cost?: number
  redemptionType?: string
  earnPointsText?: string
  slug?: string
  onRewardPress: (rewardId: string) => void
  isWished?: boolean
  doAddRewardToWishlist?: () => void
  doRemoveRewardFromWishlist?: () => void
}

export interface RewardTileProps {
  type: 'reward'
  rewardId: string
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string
  cost: number
  redemptionType?: string
  earnPointsText?: string
  slug?: string
  onClick?: (e: React.MouseEvent) => void
  height?: number
  isWished?: boolean
  doAddRewardToWishlist?: () => void
  doRemoveRewardFromWishlist?: () => void
}

export interface RewardEarnTileProps {
  type: 'reward' | 'earn'
  rewardId: string
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string
  earnPointsText?: string
  onClick?: (e: React.MouseEvent) => void
  height?: number
  slug?: string
  isWished?: boolean
  doAddRewardToWishlist?: () => void
  doRemoveRewardFromWishlist?: () => void
}

export const convertRewardToCard = (
  rewards: (Reward | RewardEarn)[]
): (RewardTileProps | RewardEarnTileProps | IntroCarouselTileProps)[] => {
  return rewards.map((reward) => {
    let type = 'redemptionType' in reward ? 'reward' : 'earn'
    if (reward?.type === 'subscription') type = reward.type
    const card = {
      type,
      rewardId: reward.rewardId,
      imageUrl: reward.content.imageUrl,
      brandLogoUrl: reward.content.brandDetails?.brandLogo,
      brandName: reward.content.brandDetails?.brandName,
      title: reward.content.title,
      lead: reward.content.lead || '',
      cost: reward.cost,
      redemptionType: 'redemptionType' in reward ? ((reward as Reward).redemptionType as string) : undefined,
      earnType: 'earnType' in reward ? ((reward as Reward).earnType as string) : undefined,
      slug: reward.content?.slug,
    } as RewardTileProps | RewardEarnTileProps | IntroCarouselTileProps
    if (type === 'earn') {
      card.earnPointsText = reward.content.pointsTextOverride || (reward.rewardId === '328014' ? 'Enter competition' : 'Earn points')
    }
    return card
  })
}
