import { connect } from 'react-redux'

import { doLogoutWeb } from 'data/src/redux/auth/dispatchers'

import { DispatchFunction } from '../../../../../../redux/types'
import { bindTypedActionCreators } from '../../../../../../helpers/bindTypedActionCreators'
import { CancelSignUpButton } from './CancelSignUpButton'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogout: () => doLogoutWeb(),
    },
    dispatch
  )

const CancelSignUpButtonContainer = connect(null, mapDispatchToProps)(CancelSignUpButton)

export { CancelSignUpButtonContainer }
