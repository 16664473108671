import React, { useState, FormEvent } from 'react'
import H3Header from '../../../components/typography/H3Header'
import { spacing } from '../../../style/variables'
import { media } from '../../../style/media'
import { TextField } from '@virgin-core/components'
import { getString } from 'data'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'

interface PromoCodesProps {
  isLoading?: boolean
  hasLoaded?: boolean
  errorMessage: string | undefined
  doSubmitPromoCode: (code: string) => void
  doSubmitPromoCodeConfirmed: () => void
}

const PromoCodes: React.FC<PromoCodesProps> = ({ isLoading, hasLoaded, errorMessage, doSubmitPromoCode, doSubmitPromoCodeConfirmed }) => {
  const [promoCode, setPromoCode] = useState('')
  const submitPromoCode = () => {
    doSubmitPromoCode(promoCode)
  }

  if (hasLoaded && !errorMessage) {
    doSubmitPromoCodeConfirmed()
  }

  return (
    <>
      <style jsx>{`
        @media ${media.tabletAndHigher} {
          :global(.text-field-wrap .MuiInputLabel-formControl) {
            min-width: 200px;
          }
        }
        @media ${media.mobileAndLower} {
          .text-field-wrap :global(.MuiInputLabel-formControl) {
            position: relative;
          }
        }
      `}</style>
      <Page title="Redeem a promo code | Virgin Red" testId="page-account-promo-codes">
        <MyAccountBackground>
          <H3Header
            textAlign="left"
            fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
            weight={600}
            marginTop={spacing.zero}
            marginBottom={spacing.zero}
          >
            {getString('account.promoCodes.title')}
          </H3Header>
          <form
            onSubmit={(event: FormEvent) => {
              event.preventDefault()
            }}
          >
            <div className="text-field-wrap">
              <p>{getString('account.promoCodes.description')}</p>
              <TextField
                id="promo-code"
                label={getString('account.promoCodes.field.label')}
                error={!errorMessage && hasLoaded && !isLoading}
                onChange={(event) => setPromoCode(event.target.value)}
                required
              />
            </div>
            <Spacer size={'medium'} />
            <Button
              dataAttributes={{ testid: 'promo-submit-button' }}
              buttonType="submit"
              isPrimary
              isFullWidth={false}
              display={'inline-block'}
              text={getString('account.promoCodes.submit')}
              onClick={() => submitPromoCode()}
              loading={isLoading}
            ></Button>
          </form>
        </MyAccountBackground>
      </Page>
    </>
  )
}

export { PromoCodes }
