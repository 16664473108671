import React, { PropsWithChildren } from 'react'
import { styled, YStack, Image } from '@red-ui/components/'
import { ContentfulImage } from 'data/src/utils/contentfulPages'
import { optimizeContentfulImageUrl } from '../../helpers/images'

interface DynamicLayoutProps {
  fields: { isRow?: boolean; backgroundColour?: string; backgroundImage?: ContentfulImage; roundedCorners?: boolean }
  isSection?: boolean
}

const BlockContainer = styled(YStack, {
  flex: 1,
  width: '100%',
  alignItems: 'center',
  alignSelf: 'stretch',
  variants: {
    isSection: {
      true: {
        paddingTop: '$5',
        paddingBottom: '$7',
        paddingHorizontal: '$2',
        $gtMobile: {
          paddingHorizontal: '$4',
        },
        $gtTabletLandscape: {
          paddingTop: '$8',
          paddingBottom: '$11',
          paddingHorizontal: '$7',
        },
      },
    },
    applyPadding: {
      true: {
        padding: '$2',
        $gtMobile: {
          padding: '$3',
        },
      },
    },
    roundedCorners: {
      true: {
        borderRadius: '$4',
        overflow: 'hidden',
      },
    },
  } as const,
})

const InnerWrapper = styled(YStack, {
  flex: 1,
  width: '100%',
  alignItems: 'center',
  gap: '$3',
  $gtMobile: {
    gap: '$4',
  },
  variants: {
    isRow: {
      true: {
        $gtMobile: {
          flexDirection: 'row',
          alignItems: 'flex-start',
        },
      },
      false: {
        $gtMobile: {
          flexDirection: 'column',
        },
      },
    },
  } as const,
})

export const DynamicLayout = ({ fields, isSection = false, children }: PropsWithChildren<DynamicLayoutProps>) => {
  const { isRow, backgroundColour, backgroundImage, roundedCorners } = fields
  const hasBackground = !isSection && !!(backgroundColour || backgroundImage)
  const optimizedBackgroundImageUrl = backgroundImage ? optimizeContentfulImageUrl(backgroundImage.fields.file?.url, 'fill') : undefined
  const imageDimensions = backgroundImage
    ? {
        width: backgroundImage?.fields.file?.details?.image?.width ?? 1000,
        height: backgroundImage?.fields.file?.details?.image?.height ?? 1000,
      }
    : undefined

  return (
    <BlockContainer
      isSection={isSection}
      applyPadding={hasBackground}
      backgroundColor={backgroundColour ?? 'transparent'}
      roundedCorners={roundedCorners}
    >
      {backgroundImage && (
        <Image
          source={{
            uri: optimizedBackgroundImageUrl,
            width: imageDimensions?.width,
            height: imageDimensions?.height,
          }}
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          objectFit="cover"
          resizeMode="cover"
        />
      )}
      <InnerWrapper isRow={isRow} maxWidth={isSection ? 1320 : 'unset'}>
        {children}
      </InnerWrapper>
    </BlockContainer>
  )
}
