import { Action as ReduxAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { AccountsState } from './accounts/types'
import { ArticlesPublicState } from './articlesPublic/types'
import { AuthState } from './auth/types'
import { CategoriesState } from './categories/types'
import { CategoriesEarnState } from './categoriesEarn/types'
import { CategoriesEarnPublicState } from './categoriesEarnPublic/types'
import { PagesState } from './pages/types'
import { DialogState } from './dialog/types'
import { DialogAppState } from './dialogApp/dialogApp.types'
import { IntroState } from './intro/types'
import { NetworkState } from './network/types'
import { OrdersState } from './orders/types'
import { PrivacyPolicyState } from './privacyPolicy/types'
import { ProfileState, PersonalDetails } from './profile/types'
import { RewardsEarnDiscoveryState } from './discoveryEarn/types'
import { RewardsEarnPublicState } from './rewardsEarnPublic/types'
import { RewardsEarnState } from './rewardsEarn/types'
import { RewardsPublicState } from './rewardsPublic/types'
import { RewardsSpendDiscoveryState } from './discovery/types'
import { RewardsState } from './rewards/types'
import { StatementState } from './statement/types'
import { TermsAndConditionsState } from './termsAndConditions/types'
import { ToastState } from './toast/types'
import { WalletState } from './wallet/types'
import { PromoCodesState } from './promotions/types'
import { CategoriesPublicState } from './categoriesPublic/types'
import { RewardsSpendDiscoveryPublicState } from './discoveryPublic/types'
import { RewardsEarnDiscoveryPublicState } from './discoveryEarnPublic/types'
import { SearchRewardsState } from './search/types'
import { WishlistState } from './wishlist/types'
import { BalanceState } from './balance/balance.types'
import { FeaturesState } from './features/features.types'

export const CLEAR_PRIVATE_CONTENT = 'CLEAR_PRIVATE_CONTENT'
export const CLEAR_TRANSIENT_STATE = 'CLEAR_TRANSIENT_STATE'

export interface RedDataState {
  accounts: AccountsState
  articlesPublic: ArticlesPublicState
  auth: AuthState
  balance: BalanceState
  categories: CategoriesState
  categoriesEarn: CategoriesEarnState
  categoriesEarnPublic: CategoriesEarnPublicState
  categoriesPublic: CategoriesPublicState
  dialog: DialogState
  dialogApp: DialogAppState
  discovery: RewardsSpendDiscoveryState
  discoveryEarn: RewardsEarnDiscoveryState
  discoveryEarnPublic: RewardsEarnDiscoveryPublicState
  discoveryPublic: RewardsSpendDiscoveryPublicState
  featureFlags: FeaturesState
  intro: IntroState
  network: NetworkState
  orders: OrdersState
  pages: PagesState
  personalDetails: PersonalDetails
  privacyPolicy: PrivacyPolicyState
  profile: ProfileState
  promoCodes: PromoCodesState
  rewards: RewardsState
  rewardsEarn: RewardsEarnState
  rewardsEarnPublic: RewardsEarnPublicState
  rewardsPublic: RewardsPublicState
  searchRewards: SearchRewardsState
  statement: StatementState
  termsAndConditions: TermsAndConditionsState
  toast: ToastState
  wallet: WalletState
  wishlist: WishlistState
}

export interface RedDataAction extends ReduxAction {
  type: string
  payload?: any
}

export class UserFacingError extends Error {
  constructor(
    public friendlyString: string,
    debugError?: string
  ) {
    super(`UserFacingError: ${debugError || 'no debug message'} (presented as "${friendlyString}")`)
  }
}

export type ContentfulRichText = any // Used for API documentation

export type DispatchFunction = ThunkDispatch<RedDataState, void, RedDataAction>
export type GetRedDataState = () => RedDataState
