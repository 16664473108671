import { NetworkState, NETWORK_ON, NETWORK_OFF } from './types'

const initialState: NetworkState = {
  isConnected: true,
}

const networkReducer = (state = initialState, action): NetworkState => {
  switch (action.type) {
    case NETWORK_ON: {
      return {
        ...state,
        isConnected: true,
      }
    }
    case NETWORK_OFF: {
      return {
        ...state,
        isConnected: false,
      }
    }
    default:
      return state
  }
}

export { initialState, networkReducer }
