import React, { FC } from 'react'

import { media } from '../../../style/media'
import { color, layout } from '../../../style/variables'
import { Picture } from '../../../components'
import { useMediaPredicate } from 'react-media-hook'

import { RewardImageProps } from './types'
import { brandLogoFallBackImage, brandLogoImages, optimizeContentfulImageUrl } from '../../../helpers/images'
import { EarnType } from 'data/src/redux/rewardsEarn/types'

const RewardImage: FC<RewardImageProps> = ({ rewardImageUrl, rewardImageAlt, brandLogoUrl, brandLogoAlt, isEarn, earnType }) => {
  const collinsonLogoFeature = earnType === EarnType.COLLINSON
  const isMobile = useMediaPredicate(media.mobileAndLower)
  let imageHeight

  if (isMobile) {
    imageHeight = isEarn ? 555 : 416
  } else {
    imageHeight = isEarn ? 720 : 540
  }

  const responsiveHeroImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 740, height: imageHeight }, 50),
      hiResImgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 1480, height: imageHeight * 2 }, 50),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 960, height: imageHeight }, 60),
      hiResImgSrc: optimizeContentfulImageUrl(rewardImageUrl, 'fill', { width: 1920, height: imageHeight * 2 }, 60),
    },
  }

  const renderRewardsImage = () => {
    if (collinsonLogoFeature) {
      const brandImages = {
        mobile: {
          imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 269, height: 269 }, 50),
          hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 538, height: 538 }, 50),
        },
        tablet: {
          imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 361, height: 361 }, 50),
          hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 722, height: 722 }, 50),
        },
        desktop: {
          imgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 436, height: 436 }, 50),
          hiResImgSrc: optimizeContentfulImageUrl(brandLogoUrl, 'pad', { width: 872, height: 872 }, 50),
        },
      }
      return (
        <>
          <style jsx>{`
            .collinson-reward-image-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffffff;
            }
            .collinson-reward-image {
              width: 50%;
              height: 50%;
            }
          `}</style>
          <div data-testid="collinson-reward-image" className="collinson-reward-image-container">
            <div className="collinson-reward-image">
              <Picture
                altText={rewardImageAlt.toString()}
                width={960}
                fallbackImg={brandImages.tablet.imgSrc}
                height={isEarn ? 720 : 540}
                responsiveImages={brandImages}
                loading={'eager'}
                isWebP={true}
              />
            </div>
          </div>
        </>
      )
    }
    return (
      <Picture
        altText={rewardImageAlt.toString()}
        width={960}
        fallbackImg={responsiveHeroImages.tablet.imgSrc}
        height={isEarn ? 720 : 540}
        responsiveImages={responsiveHeroImages}
        loading={'eager'}
        isWebP={true}
        className="reward-image"
      />
    )
  }

  return (
    <>
      <style jsx>{`
        .container {
          margin: 0 auto;
          position: relative;
        }
        .brand-logo {
          background-color: ${color.white};
          bottom: -32px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
          height: 64px;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
          right: 0;
          width: 64px;
        }
        :global(.reward-image) {
          background-color: #eee;
          display: block;
          height: auto;
          width: 100%;
        }
        @media ${media.tabletAndHigher} {
          .container {
            width: ${layout.heroWidth.tablet}px;
          }
          :global(.reward-image) {
            border-radius: 8px;
          }
          .brand-logo {
            bottom: -48px;
            height: 96px;
            width: 96px;
          }
        }
        @media ${media.desktopAndHigher} {
          .container {
            width: ${layout.heroWidth.desktop}px;
          }
          .brand-logo {
            bottom: -64px;
            height: 128px;
            width: 128px;
          }
        }
      `}</style>
      <div className="container">
        {renderRewardsImage()}
        {brandLogoUrl && brandLogoAlt && (
          <div className="brand-logo" data-testid="brand-logo">
            <Picture
              altText={`${brandLogoAlt} logo`}
              width={144}
              fallbackImg={brandLogoFallBackImage(brandLogoUrl)}
              height={144}
              responsiveImages={brandLogoImages(brandLogoUrl)}
              loading={'lazy'}
              isWebP={true}
            />
          </div>
        )}
      </div>
    </>
  )
}

export { RewardImage }
