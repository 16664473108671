import { connect } from 'react-redux'

import { doSubmitOnboarding } from 'data/src/redux/profile/dispatchers'
import { doFetchTermsAndConditions } from 'data/src/redux/termsAndConditions/dispatchers'
import { doFetchPrivacyPolicy } from 'data/src/redux/privacyPolicy/dispatchers'
import * as termsAndConditionsSelectors from 'data/src/redux/termsAndConditions/selectors'
import * as privacyPolicySelectors from 'data/src/redux/privacyPolicy/selectors'
import * as profileSelectors from 'data/src/redux/profile/selectors'
import { doShowDialog } from 'data/src/redux/dialog/dispatchers'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'
import { DispatchFunction, RootState } from '../../../redux/types'

import { TermsAndConditions } from './TermsAndConditions'

const mapStateToProps = (state: RootState) => {
  const termsAndConditions = termsAndConditionsSelectors.getTermsAndConditions(state)
  const privacyPolicy = privacyPolicySelectors.getPrivacyPolicy(state)

  return {
    isProfileUpdating: profileSelectors.getIsUpdating(state),
    isTermsLoading: termsAndConditionsSelectors.getIsLoading(state),
    isPrivacyPolicyLoading: privacyPolicySelectors.getIsLoading(state),
    termsTitle: termsAndConditions?.title,
    termsBody: termsAndConditions?.body,
    termsVersion: termsAndConditions?.version,
    privacyBody: privacyPolicy?.body,
    privacyVersion: privacyPolicy?.version,
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doSubmitOnboarding,
      doFetchTermsAndConditions,
      doFetchPrivacyPolicy,
      doShowDialog,
    },
    dispatch
  )

const TermsAndConditionsContainer = connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)

export { TermsAndConditionsContainer }
