import React, { FC } from 'react'
import { getString } from 'data'

interface TextProps {
  id: string
}

const TextId: FC<TextProps> = ({ id }) => {
  return <>{getString(id)}</>
}

export { TextId }
