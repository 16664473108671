import { connect } from 'react-redux'

import { getPrivacyPolicyBody, getPrivacyPolicyVersion, getIsLoading } from 'data/src/redux/privacyPolicy/selectors'

import { RootState } from '../../redux/types'
import { DynamicContent } from '../../components'

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: getIsLoading(state),
    body: getPrivacyPolicyBody(state),
    version: getPrivacyPolicyVersion(state),
  }
}

const DynamicContentContainer = connect(mapStateToProps)(DynamicContent)

export { DynamicContentContainer }
