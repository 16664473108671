import React, { useState, ChangeEvent, FocusEvent, FormEvent } from 'react'

import H3Header from '../../../components/typography/H3Header'
import { spacing } from '../../../style/variables'
import { TextField } from '@virgin-core/components'
import { getString } from 'data'
import { getStringOrNull } from 'data/src/utils/getString'
import { parseJoiErrors } from '../../../../../data/src/utils/validations'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'
import { validateUpdateEmail } from '../../../../../data/src/redux/profile/validations'
import { UpdateEmailProps, UpdateEmailForm } from './types'

const UpdateEmail: React.FC<UpdateEmailProps> = ({ isUpdating, doUpdateEmail }) => {
  const screenId = 'profile.updateEmail'
  const [formState, setFormState] = useState<UpdateEmailForm>({})
  const [errors, setErrors] = useState<UpdateEmailForm>({})

  const onChange = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target
    setErrors((prevState) => ({
      ...prevState,
      [id]: null,
    }))
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const onSubmit = () => {
    const formValidation = validateUpdateEmail(formState)
    if (formValidation.error) {
      const newErrors = parseJoiErrors(formValidation.error.details)
      const error = setErrors((prevState) => ({
        ...prevState,
        ...newErrors,
      }))
      return error
    }
    return doUpdateEmail(formState)
  }

  return (
    <>
      <style jsx>{`
        .description {
          margin-bottom: 0;
        }
      `}</style>
      <Page title="Update email | Virgin Red" testId="page-account-update-email">
        <MyAccountBackground>
          <H3Header
            textAlign="left"
            fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
            weight={600}
            marginTop={spacing.zero}
            marginBottom={spacing.zero}
          >
            {getString('profile.updateEmail.title')}
          </H3Header>
          {getString('profile.updateEmail.description')
            .split('\n')
            .map((description: string) => (
              <p className="description">{description}</p>
            ))}
          <form
            onSubmit={(event: FormEvent) => {
              event.preventDefault()
            }}
          >
            <TextField
              id="email"
              fullWidth
              label={getString('profile.labels.newEmail')}
              error={Boolean(errors.email)}
              helperText={getStringOrNull(`${screenId}.email.error.${errors.email}`)}
              onChange={onChange}
              required
            />
            <TextField
              id="emailConfirm"
              fullWidth
              label={getString('profile.labels.newEmailConfirm')}
              error={Boolean(errors.emailConfirm)}
              helperText={getStringOrNull(`${screenId}.emailConfirm.error.${errors.emailConfirm}`)}
              onChange={onChange}
              required
            />
            <Spacer size={'medium'} />
            <Button
              isPrimary
              buttonType="submit"
              isFullWidth={false}
              display={'inline-block'}
              text={getString('profile.updateEmail.submit')}
              loading={isUpdating}
              onClick={onSubmit}
            ></Button>
          </form>
        </MyAccountBackground>
      </Page>
    </>
  )
}

export { UpdateEmail }
