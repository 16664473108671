import { connect } from 'react-redux'

import { doUnsetToastMessage } from 'data/src/redux/toast/dispatchers'
import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { getProfileData } from 'data/src/redux/profile/selectors'

import { DispatchFunction, RootState } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { Page } from './Page'

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  profileData: getProfileData(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUnsetToastMessage,
    },
    dispatch
  )

const PageContainer = connect(mapStateToProps, mapDispatchToProps)(Page)

export { PageContainer }
