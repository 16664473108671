import { logger } from '../../utils'

import {
  viewReward,
  redeemReward,
  redeemRewardFailure,
  getHomePageRewards,
  getHomePageRewardsSuccess,
  getHomePageRewardsFailure,
  redeemRewardSuccess,
  removeOptedOutRewards,
} from './actions'

import { showDialog } from '../dialog/actions'
import { getSelectedReward, getRewardById } from './selectors'
import { OptOutType, RedemptionType } from './types'
import { doVoucherRedemptionFlow } from './redemptionFlows/voucher'
import { doWinesVoucherRedemptionFlow } from './redemptionFlows/winesVoucher'
import { doWinesFulfilmentRedemptionFlow } from './redemptionFlows/winesFulfilment'
import { doPhysicalSparklesRedemptionFlow } from './redemptionFlows/physicalSparkles'
import { doDigitalFulfilmentRedemptionFlow } from './redemptionFlows/digitalFulfilment'
import { doVoucherPlusUrlRedemptionFlow } from './redemptionFlows/voucherPlusUrl'
import { doSimpleLinkRedemptionFlow } from './redemptionFlows/simpleLink'
import { doDonationLinkRedemptionFlow } from './redemptionFlows/donationLink'
import { DispatchFunction, GetRedDataState } from '../types'
import { DialogType } from '../dialog/types'
import { getRedDataConfig } from '../../config'
import { isWeb } from '../../utils/helperFunctions'
import { doStepDown } from '../auth/dispatchers'
import { getIsSteppedUp } from '../auth/selectors'
import { doSubmitOrder } from '../orders/dispatchers'
import { errorHandler } from '../errorHandler'
import { apiGetSpendRewardsByCategory, apiGetRewardById } from '../../api/discovery.api'

// added a limit argument in order to optimise the number
// of rewards fetched in homepage for the Carousel
const doFetchHomePageRewards =
  (limit = 0) =>
  async (dispatch: DispatchFunction) => {
    logger.log(`doFetchHomePageRewards()`)
    dispatch(getHomePageRewards())
    try {
      const rewards = await apiGetSpendRewardsByCategory([], limit)
      dispatch(getHomePageRewardsSuccess(rewards))
    } catch (error) {
      errorHandler(dispatch, error, getHomePageRewardsFailure)
    }
  }

// in order to avoid too much boilerplate, we keep using and dispatching
// the actions for all the rewards even if we are fetching just one.
// the REWARD_SUCCESS payload will be treated as a single element array.
const doFetchRewardById = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doFetchRewardById()`)
  dispatch(getHomePageRewards())
  try {
    const rewards = [await apiGetRewardById(rewardId)]
    dispatch(getHomePageRewardsSuccess(rewards))
  } catch (error) {
    errorHandler(dispatch, error, getHomePageRewardsFailure)
  }
}

const doViewReward = (rewardId: string) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doViewReward(${rewardId})`)
  const config = getRedDataConfig()
  dispatch(viewReward(rewardId))
  return config.navigate(config.navTargets.RewardDetails, {
    rewardId,
    campaignId: getState().rewards.data?.entities[rewardId]?.campaignId,
  })
}

const doViewRewardWeb = (rewardId: string) => async (dispatch: DispatchFunction) => {
  logger.log(`doViewRewardWeb(${rewardId})`)
  dispatch(viewReward(rewardId))
}

const doRedeemRewardConfirmed = () => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doRedeemRewardConfirmed()`)
  const reward = getSelectedReward(getState())
  try {
    switch (reward?.redemptionType) {
      case RedemptionType.DIGITAL_FULFILMENT:
        await dispatch(doDigitalFulfilmentRedemptionFlow(reward.rewardId, reward.rewardParentId, reward.content))
        break
      case RedemptionType.QR_CODE:
        dispatch(doVoucherRedemptionFlow(reward))
        break
      case RedemptionType.WINES_VOUCHER:
        await dispatch(doWinesVoucherRedemptionFlow(reward))
        break
      case RedemptionType.VOUCHER_PLUS_URL:
        await dispatch(doVoucherPlusUrlRedemptionFlow(reward))
        break
      case RedemptionType.WINES_FULFILMENT:
        if (isWeb() && getIsSteppedUp(getState())) {
          await dispatch(doSubmitOrder(getState().orders.inProgress, true))
        } else {
          await dispatch(doWinesFulfilmentRedemptionFlow(reward))
        }
        break
      case RedemptionType.PHYSICAL_SPARKLES:
        if (isWeb() && getIsSteppedUp(getState())) {
          await dispatch(doSubmitOrder(getState().orders.inProgress, true))
        } else {
          await dispatch(doPhysicalSparklesRedemptionFlow(reward))
        }
        break
      case RedemptionType.SIMPLE_LINK:
        await dispatch(doSimpleLinkRedemptionFlow(reward.rewardId, reward.rewardParentId, reward.redemptionUrl as string))
        break
      case RedemptionType.DONATION_LINK:
        await dispatch(doDonationLinkRedemptionFlow(reward))
        break
      default:
        throw new Error(`Unknown redemption type ${reward.redemptionType}`)
    }
    dispatch(redeemRewardSuccess())
  } catch (error) {
    doStepDown()
    errorHandler(dispatch, error, redeemRewardFailure)
  }
  return
}

const doRedeemReward = (rewardId: string) => (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const reward = getRewardById(getState(), rewardId)
  const balance = getState().balance.data
  const isInsufficientPoints = balance.usable !== null ? balance.usable < reward.cost : false
  logger.log(`doRedeemReward(${reward.rewardId}, ${reward.rewardParentId})`)
  if (isInsufficientPoints) {
    dispatch(
      showDialog({
        dialogTestId: 'dialog-insufficient-points',
        type: DialogType.MODAL,
        titleTextId: 'rewardDetails.insufficientPoints.title',
        bodyTextId: 'rewardDetails.insufficientPoints.body',
        buttonConfirmTextId: 'actions.back',
      })
    )
    return
  }
  dispatch(redeemReward())

  const typesWithDonation = [RedemptionType.DONATION_LINK]
  const typesWithVoucher = [RedemptionType.WINES_VOUCHER, RedemptionType.VOUCHER_PLUS_URL, RedemptionType.QR_CODE]
  const skipConfirmationFor = [RedemptionType.WINES_FULFILMENT, RedemptionType.PHYSICAL_SPARKLES, RedemptionType.SIMPLE_LINK]

  const redemptionTypeCheck = (redemptionType: RedemptionType | undefined, typesArray: RedemptionType[]): boolean => {
    return Boolean(redemptionType && typesArray.includes(redemptionType))
  }

  let dialogTitle = 'rewardDetails.redeemRewardTitle'
  if (redemptionTypeCheck(reward.redemptionType, typesWithDonation)) {
    dialogTitle = 'rewardDetails.donation.redeemRewardTitle'
  }

  let dialogDescription = 'rewardDetails.digital.redeemRewardDescription'
  if (redemptionTypeCheck(reward.redemptionType, typesWithDonation)) {
    dialogDescription = 'rewardDetails.donation.redeemRewardDescription'
  } else if (redemptionTypeCheck(reward.redemptionType, typesWithVoucher)) {
    dialogDescription = 'rewardDetails.voucher.redeemRewardDescription'
  }

  if (redemptionTypeCheck(reward.redemptionType, skipConfirmationFor)) {
    dispatch(doRedeemRewardConfirmed())
  } else {
    dispatch(
      showDialog({
        callbackOnConfirm: () => dispatch(doRedeemRewardConfirmed()),
        type: DialogType.ALERT,
        callbackOnCancel: () => dispatch(redeemRewardFailure('User Cancelled')),
        titleTextId: dialogTitle,
        bodyTextId: dialogDescription,
        buttonCancelTextId: 'actions.cancel',
        buttonConfirmTextId: reward.redemptionType === RedemptionType.DONATION_LINK ? 'actions.donate' : 'actions.confirmOrder',
      })
    )
  }
}

const doRemoveOptedOutRewards = (rewardType: OptOutType) => (dispatch: DispatchFunction) => {
  dispatch(removeOptedOutRewards(rewardType))
}

export {
  doFetchHomePageRewards,
  doViewRewardWeb,
  doViewReward,
  doRedeemReward,
  doRedeemRewardConfirmed,
  doFetchRewardById,
  doRemoveOptedOutRewards,
}
