import React from 'react'
import { YStack, styled } from '@red-ui/components'
import { ContentfulEntry, getSectionBackgroundColor } from 'data/src/utils/contentfulPages'
import { DynamicPlaceholder } from './DynamicPlaceholder'
import { DynamicLayout } from './DynamicLayout'
import { DynamicImage } from './DynamicImage'
import { DynamicRichText } from './DynamicRichText'
import { DynamicButton } from './DynamicButton'
import { FaqBlock } from '../ContentfulBlock'
import { DynamicCarousel } from './DynamicCarousel'

type ComponentCreator = (content: ContentfulEntry) => JSX.Element
type FactoryConfig = Record<string, ComponentCreator | undefined>

const FaqContainer = styled(YStack, {
  paddingTop: '$5',
  paddingBottom: '$7',
  $gtTabletLandscape: {
    paddingTop: '$8',
    paddingBottom: '$11',
  },
})

const createDynamicPageContent: ComponentCreator = (content) => (
  <YStack>
    {content.fields.sections?.map((section: ContentfulEntry) => {
      const sectionType = section.sys.contentType.sys.id
      return sectionType === 'dynamicLayout' ? (
        <DynamicLayout key={section.sys.id} fields={section.fields} isSection>
          {section.fields.content?.map((x: ContentfulEntry) => <DynamicContentFactory key={x.sys.id} content={x} />)}
        </DynamicLayout>
      ) : (
        <DynamicContentFactory key={section.sys.id} content={section} />
      )
    })}
  </YStack>
)

const createDynamicLayout: ComponentCreator = (content) => (
  <DynamicLayout fields={content.fields}>
    {content.fields.content?.map((x: ContentfulEntry) => <DynamicContentFactory key={x.sys.id} content={x} />)}
  </DynamicLayout>
)

const createDynamicImage: ComponentCreator = (content) => <DynamicImage fields={content.fields} />
const createDynamicRichText: ComponentCreator = (content) => <DynamicRichText fields={content.fields} />
const createDynamicButton: ComponentCreator = (content) => <DynamicButton fields={content.fields} />
const createHomePageFaqSection: ComponentCreator = (content) => (
  <FaqContainer backgroundColor={getSectionBackgroundColor(content)}>
    <FaqBlock sectionName="faq_section" fields={content.fields} />
  </FaqContainer>
)

const dynamicComponentFactory: FactoryConfig = {
  dynamicPageContent: createDynamicPageContent,
  dynamicLayout: createDynamicLayout,
  dynamicImage: createDynamicImage,
  dynamicRichText: createDynamicRichText,
  dynamicButton: createDynamicButton,
  homePageFaqSection: createHomePageFaqSection,
  blockIntroCarousel: DynamicCarousel,
}

const contentRenderer = (content: ContentfulEntry, componentFactory: FactoryConfig, placeholderComponent: JSX.Element) => {
  const contentType = content.sys.contentType?.sys.id ?? ''
  const componentBuilder = componentFactory[contentType]
  return componentBuilder ? componentBuilder(content) : placeholderComponent
}

export const DynamicContentFactory = ({
  content,
  config = dynamicComponentFactory,
  placeholderComponent = <DynamicPlaceholder content={content} />,
}: {
  content: ContentfulEntry
  config?: FactoryConfig
  placeholderComponent?: JSX.Element
}) => contentRenderer(content, config, placeholderComponent)
