import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getString } from 'data'
import { getVoucherById } from 'data/src/redux/wallet/selectors'
import { formatIsoDateToDateWithMonthString } from 'data/src/utils/formatters'
import { RootState } from '../../redux/types'
import { PATHS } from '../../router/paths'
import { HelpCTA } from '../../components'
import { Stars } from '../../components/icons/Stars'
import { Content } from '../../components/layout'
import { Page } from '../../components/Page'
import H2Header from '../../components/typography/Red/H2Header'
import Header from '../../components/RewardDetails/Header'
import VoucherSummary from '../../components/VoucherSummary'
import { UrlCodeVoucher } from '../../components/UrlCodeVoucher'
import { media } from '../../style/media'
import { color } from '../../style/variables'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

const Voucher: FC = () => {
  let { voucherId } = useParams<{ voucherId: string }>()
  const voucher = useSelector((state: RootState) => getVoucherById(state, voucherId))
  const navigate = useRedNavigate()
  // TODO - Figure out what the fallback UX should be for used vouchers and incorrect urls
  useEffect(() => {
    if (!voucher) navigate(PATHS.MY_REWARDS_VOUCHERS)
  })

  if (!voucher) return <></>

  const {
    content: {
      title,
      brandDetails: { brandLogo },
    },
    validFrom,
    validTo,
    image,
    code,
  } = voucher
  return (
    <>
      <style jsx>{`
        .header-wrapper {
          display: block;
          margin-bottom: -234px;
        }
        .header {
          height: 200px;
        }
        .stars {
          display: flex;
          height: 40px;
          justify-content: center;
          margin: auto;
        }
        .rectangle {
          border: none;
          border-radius: none;
          background-color: ${color.white};
          padding-top: 24px;
          padding-bottom: 40px;
        }
        @media ${media.tabletAndHigher} {
          .header-wrapper {
            margin-bottom: -240px;
          }
          .header {
            height: 200px;
          }
          .rectangle {
            border-radius: 8px;
            border: solid 1px ${color.secondaryButtonBorder};
            margin: auto;
            max-width: 872px;
            padding-top: 32px;
          }
          .header-wrapper {
            margin-bottom: -220px;
          }
        }
        @media ${media.desktopAndHigher} {
          .header {
            height: 300px;
          }
          .header-wrapper {
            margin-bottom: -340px;
          }
          .stars {
            height: 60px;
          }
        }
        @media ${media.mobileAndLower} {
          .content-wrapper :global(.content) {
            margin: 0;
          }
        }
      `}</style>
      <Page testId="page-voucher" title={`${title} | Vouchers | My Rewards | Virgin`}>
        <div className="header-wrapper">
          <Header>
            <div className="header"></div>
          </Header>
        </div>
        <div className="content-wrapper">
          <Content>
            <div className="stars">
              <Stars />
            </div>
            <H2Header
              fontStyle="italic"
              marginBottom={{
                mobile: 48,
                tablet: 64,
                desktop: 64,
              }}
              marginTop={{ tablet: 16, desktop: 24 }}
              textAlign="center"
              weight={600}
            >
              {getString('myRewards.voucher.title').toUpperCase()}
            </H2Header>
            <div className="rectangle">
              {voucher.url ? (
                <UrlCodeVoucher voucher={voucher} />
              ) : (
                <VoucherSummary
                  title={title}
                  brandLogoUrl={brandLogo}
                  validFrom={formatIsoDateToDateWithMonthString(validFrom, true)}
                  validTo={formatIsoDateToDateWithMonthString(validTo, true)}
                  qrImage={image}
                  code={code}
                />
              )}
            </div>
            <HelpCTA
              heading={'myRewards.voucher.help.heading'}
              subheading={'myRewards.voucher.help.subheading'}
              linkText={'myRewards.voucher.help.linkText'}
            />
          </Content>
        </div>
      </Page>
    </>
  )
}

export { Voucher }
