import { connect } from 'react-redux'
import { getIsAuthenticating } from 'data/src/redux/auth/selectors'
import { EmailUpdated } from './EmailUpdated'
import { RootState } from '../../redux/types'

const mapStateToProps = (state: RootState) => ({
  isAuthenticating: getIsAuthenticating(state),
})

const EmailUpdatedContainer = connect(mapStateToProps)(EmailUpdated)

export { EmailUpdatedContainer }
