import React, { FC } from 'react'
import { GridContainer, GridItem, GridWrapper } from '@virgin-core/components'
import { breakpoints, color as globalColor, media } from '@virgin-core/styles'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import ContentfulCopy from '../richText/contentfulCopy'
import { Body, Heading } from '..'
import { Picture } from '../index'
import { DownloadAppBlockFields } from './types'

interface HeroBlockProps {
  sectionName: string
  fields: DownloadAppBlockFields
  fixedAspectRatio?: boolean
  textColour?: string
  topHero?: boolean
}

export const DownloadAppBlock: FC<HeroBlockProps> = ({ sectionName, fields, textColour, fixedAspectRatio, topHero }) => {
  const idPrefix = `${sectionName}_button`
  const foregroundImageDimensions: Dimensions = { width: 1000, height: 750 }
  const badgeDimensions: Dimensions = { width: 340, height: 100 }
  const foregroundUrl = optimizeContentfulImageUrl(fields?.foregroundImage?.fields?.file?.url, 'stretch', foregroundImageDimensions)
  const badge1Url = optimizeContentfulImageUrl(fields?.button1Image?.fields?.file?.url, 'stretch', badgeDimensions)
  const badge2Url = optimizeContentfulImageUrl(fields?.button2Image?.fields?.file?.url, 'stretch', badgeDimensions)

  return (
    <>
      <style jsx>{`
        .download-app-container {
          align-items: center;
          display: flex;
          flex-direction: column-reverse;
          margin: 0 auto;
          width: 100%;
        }
        .download-image-link {
          margin: 0 8px;
          height: 40px;
          margin-left: 9px;
        }
        .download-image {
          height: auto;
          width: 100%;
          align-self: flex-end;
        }
        .download-app-info {
          height: 100%;
          padding: 15px;
          text-align: center;
        }
        .download-app-info :global(.header-three) {
          font-size: 24px;
          text-align: center;
        }
        .download-app-info :global(.body) {
          font-size: 16px;
          width: auto;
        }
        .download-image-links {
          display: flex;
          justify-content: center;
          margin: 24px 0;
        }
        .download-image {
          height: auto;
        }

        @media ${media.mobileLargeAndHigher} {
          .download-image {
            height: 40px;
          }
        }

        @media ${media.tabletLargeAndHigher} {
          .download-app-container {
            flex-direction: row;
            justify-content: space-around;
            padding-top: 40px;
            ${fixedAspectRatio ? 'aspect-ratio: 2.35;' : 'height: 100%;'}
          }
          .download-app-info {
            text-align: left;
            font-size: 16px;
            padding: 0;
          }
          .download-app-image {
            align-self: flex-end;
          }
          .download-app-info :global(.header-three) {
            font-size: 28px;
            text-align: left;
          }
          .download-image-links {
            display: flex;
            justify-content: flex-start;
          }
          .download-image-link {
            margin: 0 16px 0 0;
            height: 60px;
          }
          .download-app-container :global(.rich-text-copy p) {
            font-size: 16px;
          }
        }
        @media ${media.desktopAndHigher} {
          .download-app-container {
            justify-content: left;
          }
          .download-image-link {
            height: 60px;
          }
          .download-image {
            height: 100%;
            width: auto;
          }
          .download-app-info :global(.header-three) {
            font-size: 36px;
          }
        }
      `}</style>
      <GridWrapper>
        <GridContainer>
          <div className="download-app-container" data-testid="download-app-container">
            <GridItem xs={12} md={6}>
              <div className="download-app-image">
                {fields?.foregroundImage?.fields?.file && (
                  <Picture
                    altText={fields?.foregroundImage?.fields?.description}
                    width={600}
                    fallbackImg={foregroundUrl}
                    height={600}
                    responsiveImages={{
                      mobile: { imgSrc: foregroundUrl },
                    }}
                    loading={'lazy'}
                    isWebP={true}
                  />
                )}
              </div>
            </GridItem>
            <GridItem xs={12} md={6} lg={5} className="text-area">
              <div className="download-app-info">
                <Heading
                  fontStyle="Normal"
                  color={textColour ? globalColor.white : globalColor.textStandard}
                  weight={600}
                  textAlign={window.innerWidth < breakpoints.tabletMin ? 'center' : 'left'}
                  marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                  marginBottom={{ mobile: 10, tablet: 0, desktop: 0 }}
                  fontSize={{ mobile: 28, tablet: 24, desktop: 50 }}
                  seoHeadline={topHero}
                >
                  {fields?.headline}
                </Heading>
                <Body
                  color={textColour ? globalColor.white : globalColor.textStandard}
                  marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                  marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
                  isDesktopTextBig
                >
                  <ContentfulCopy content={fields?.body} />
                </Body>
                <div className="download-image-links">
                  <a
                    id={`${idPrefix}_ios`}
                    href={fields?.button1Target}
                    data-testid="download-image-ios"
                    className="download-image-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="download-image"
                      src={badge1Url}
                      alt={fields?.button1Image?.fields?.description}
                      width={107}
                      height={60}
                      loading={'lazy'}
                    />
                  </a>
                  <a
                    id={`${idPrefix}_android`}
                    href={fields?.button2Target}
                    data-testid="download-image-android"
                    className="download-image-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="download-image"
                      src={badge2Url}
                      alt={fields?.button2Image?.fields?.description}
                      width={107}
                      height={60}
                      loading={'lazy'}
                    />
                  </a>
                </div>
              </div>
            </GridItem>
          </div>
        </GridContainer>
      </GridWrapper>
    </>
  )
}
