import { PagingResponse, PaginationState } from '../../redux/pagination/types'
import { RewardContent, RewardType } from '../rewards/types'

export const GET_HOME_PAGE_REWARDS_EARN = 'GET_HOME_PAGE_REWARDS_EARN'
export const GET_HOME_PAGE_REWARDS_EARN_SUCCESS = 'GET_HOME_PAGE_REWARDS_EARN_SUCCESS'
export const GET_HOME_PAGE_REWARDS_EARN_FAILURE = 'GET_HOME_PAGE_REWARDS_EARN_FAILURE'

export const VIEW_REWARD_EARN = 'VIEW_REWARD_EARN'
export const UPDATE_REWARDS_EARN = 'UPDATE_REWARDS_EARN'
export const UPDATE_REWARDS_EARN_PAGINATION = 'UPDATE_REWARDS_EARN_PAGINATION'
export const REDEEM_REWARD_EARN = 'REDEEM_REWARD_EARN'
export const REDEEM_REWARD_EARN_SUCCESS = 'REDEEM_REWARD_EARN_SUCCESS'
export const REDEEM_REWARD_EARN_FAILURE = 'REDEEM_REWARD_EARN_FAILURE'
export const SET_IS_WISHED_EARN = 'SET_IS_WISHED_EARN'

export interface RewardsEarnState {
  data: {
    entities: { [rewardId: string]: RewardEarn }
    selected: string | null
    ids: string[]
  }
  isLoading: boolean
  isRedeeming: boolean
  isWished?: boolean
  hasLoaded: boolean
  errorMessage: string | null
  pagination: PaginationState
  redemptionCompleted: boolean
}

export enum EarnType {
  AWIN = 'awin',
  OFFSITE_LINK_STANDARD = 'offsite_link_standard',
  COLLINSON = 'collinson',
  OFFSITE_LINK_OCTOPUS = 'offsite_link_octopus',
}
export interface RewardEarnResponse {
  rewards: RewardEarn[]
  paging: PagingResponse
}

export interface RewardEarn {
  activity?: string
  campaignId: string
  content: RewardContent
  cost: number
  earnType: EarnType
  earnUrl?: string
  name: string
  rewardId: string
  type?: RewardType
}

export interface RewardEarnOffsiteLink extends RewardEarn {
  earnUrl: string
}
