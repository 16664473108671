import React, { FC } from 'react'

import { RewardEarn } from 'data/src/redux/rewardsEarn/types'
import { Reward } from 'data/src/redux/rewards/types'
import ContentfulCopy from '../../richText/contentfulCopy'
import RewardInfoContent from '../RewardInfoContent'
import { color } from '../../../style/variables'
import { Document } from '@contentful/rich-text-types'
import { removeHtmlTags } from '../../../helpers/text'

interface RewardContentProps {
  reward: Reward | RewardEarn
}

const RewardContent: FC<RewardContentProps> = ({ reward }) => {
  return (
    <>
      <style jsx>{`
        .partner {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .partner-descriptor {
          color: ${color.textStandard};
          opacity: 0.4;
        }
        .partner-logo {
          height: 56px;
          width: 56px;
        }
      `}</style>
      <RewardInfoContent>
        {reward.content.body && <ContentfulCopy content={reward.content.body} />}
        {reward.content.termsAndConditions &&
          ((reward.content.termsAndConditions as Document).content ? (
            <ContentfulCopy content={reward.content.termsAndConditions as Document} />
          ) : (
            <RewardInfoContent>{removeHtmlTags(reward.content.termsAndConditions as string)}</RewardInfoContent>
          ))}
        {reward?.content?.partnerDetails && (
          <div className="partner">
            <div className="partner-descriptor">{reward?.content?.partnerDetails?.descriptor}</div>
            <img
              alt={reward?.content?.partnerDetails?.partnerName}
              className="partner-logo"
              src={reward?.content?.partnerDetails?.partnerLogo}
            />
          </div>
        )}
      </RewardInfoContent>
    </>
  )
}

export default RewardContent
