import { styled, View } from '@red-ui/components/'

export const PlaceholderIcon = styled(View, {
  width: '$3',
  height: '$3',
  backgroundColor: '$black3',
  marginRight: '$2',
})

export const QuestionIcon = styled(View, {
  backgroundColor: '$black10',
  display: 'inline-flex',
  width: '$2',
  height: '$2',
  bottom: '$0.75',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$4',
  cursor: 'pointer',
})
