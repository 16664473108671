import { connect } from 'react-redux'

import { getSort } from 'data/src/redux/discoveryPublic/selectors'
import { doUpdateRewardsPublic } from 'data/src/redux/discoveryPublic/dispatchers'
import { setSortPublic } from 'data/src/redux/discoveryPublic/actions'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'
import { RewardsSort } from './RewardsSort'

const mapStateToProps = (state: RootState) => ({
  selectedSort: getSort(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUpdateRewards: doUpdateRewardsPublic,
      setSort: setSortPublic,
    },
    dispatch
  )

export const PublicSpendRewardsSortContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsSort)
