import React, { PropsWithChildren } from 'react'
import { RedDataState } from 'data/src/types'
import { useAppSelector } from 'web/src/redux/hooks/hooks'
import { Spinner, SpinnerProps } from './Spinner'

interface LoadingSpinnerWrapperProps {
  getIsLoading: (state: RedDataState) => boolean
  spinner?: SpinnerProps
}

const defaultSpinner: SpinnerProps = {
  color: 'red',
  size: 'small',
  position: 'inherit',
  instantlyVisible: true,
  height: '50px',
  width: '50px',
  marginTop: '40px',
}

export const LoadingSpinnerWrapper = ({
  getIsLoading,
  spinner = defaultSpinner,
  children,
}: PropsWithChildren<LoadingSpinnerWrapperProps>) => {
  const loading = useAppSelector(getIsLoading)
  return loading ? <Spinner {...spinner} /> : <>{children}</>
}
