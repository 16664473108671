import React, { FC } from 'react'

import { getString } from 'data'
import { getConnectFailUrl } from 'data/src/redux/accounts/selectors'

import { PATHS } from '../../../../router/paths'
import { useAppSelector } from '../../../../redux/hooks/hooks'
import { localizePath } from '../../../../dataImplementation/viewingRegionWeb'
import H3Header from '../../../../components/typography/Red/H3Header'
import { Spacer } from '../../../../components'
import { Button } from '../../../../components/Buttons'
import { media } from '../../../../style/media'

const AutoLinkAccountsError: FC = () => {
  const callbackUrl = useAppSelector(getConnectFailUrl)

  return (
    <>
      <style jsx>{`
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parentContainer {
          animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
        }
        .parentContainer :global(.header-three) {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        .button-wrapper :global(button) {
          margin-top: 40px;
        }
        @media ${media.tabletAndHigher} {
          .parentContainer {
            margin: 0 100px;
          }
        }
      `}</style>
      <div className="parentContainer">
        <H3Header marginTop={{ mobile: 0 }} marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
          {getString('onboarding.autoLinkPartner.error.title')}
        </H3Header>
        <Spacer size="xLarge" />
        <p>{getString('onboarding.autoLinkPartner.error.body')}</p>
        <div className="button-wrapper">
          <Button
            onClick={() => (window.location.href = callbackUrl ? decodeURIComponent(callbackUrl) : localizePath(PATHS.HOME))}
            text="Continue"
            isPrimary
            display="block"
            dataAttributes={{ testid: `auto-link-accounts-error-button` }}
          />
        </div>
      </div>
    </>
  )
}

export { AutoLinkAccountsError }
