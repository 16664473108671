import { useAppSelector } from '../../../redux/hooks/hooks'
import { useLocation, useParams } from 'react-router-dom'
import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { getRewardById } from 'data/src/redux/rewards/selectors'
import { getRewardById as getRewardPublicById } from 'data/src/redux/rewardsPublic/selectors'
import { getRewardById as getEarnRewardById } from 'data/src/redux/rewardsEarn/selectors'
import { getRewardById as getEarnRewardPublicById } from 'data/src/redux/rewardsEarnPublic/selectors'

export const useCampaignId = () => {
  const { rewardPath = '' } = useParams() ?? {}
  const location = useLocation()
  const reward = useAppSelector((state) => {
    if (!rewardPath) return null
    const rewardId = rewardPath.split('-').pop() || rewardPath
    const isSpendPage = location.pathname.includes('/spend-virgin-points/')
    const isAuth = getIsAuthenticated(state)
    if (isSpendPage) {
      return (isAuth ? getRewardById : getRewardPublicById)(state, rewardId)
    }
    return (isAuth ? getEarnRewardById : getEarnRewardPublicById)(state, rewardId)
  })

  return reward?.campaignId || null
}
