import { connect } from 'react-redux'

import { doFetchPrivacyPolicy } from 'data/src/redux/privacyPolicy/dispatchers'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { DispatchFunction } from '../../redux/types'

import { PrivacyPolicy } from './PrivacyPolicy'

const mapDispatchToProps = (dispatch: DispatchFunction) => bindTypedActionCreators({ doFetchPrivacyPolicy }, dispatch)

const PrivacyPolicyContainer = connect(undefined, mapDispatchToProps)(PrivacyPolicy)

export { PrivacyPolicyContainer }
