import React, { FC } from 'react'

import { Order } from 'data/src/redux/orders/types'
import { media } from '../../../../style/media'
import OrderTile from './OrderTile'

interface OrdersListerProps {
  orders: Order[]
}

const OrdersLister: FC<OrdersListerProps> = ({ orders }) => {
  return (
    <>
      <style jsx>{`
        .orders-lister {
          margin: 40px 0;
        }

        @media ${media.tabletAndHigher} {
          .orders-lister {
            margin-bottom: 64px;
          }
        }

        @media ${media.desktopAndHigher} {
          .orders-lister {
            margin-bottom: 88px;
          }
        }
      `}</style>
      <div className="orders-lister" data-testid="orders-lister">
        {orders.map((order) => {
          return <OrderTile {...order} key={order.orderId} />
        })}
      </div>
    </>
  )
}

export default OrdersLister
