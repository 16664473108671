import React, { FC } from 'react'

import { getString } from 'data'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { MobileAppsSplash, RewardsFilterWrapper, RewardsListPublicSpend } from '../../components'
import { CurvedContainer } from '../../components/CurvedContainer'

export const SpendPublic: FC = () => {
  return (
    <>
      <style jsx>{`
        .hidden-seo-header {
          visibility: hidden;
          margin: 0;
          height: 0;
        }
        .pink {
          height: 210px;
          margin-top: 130px;
          margin-bottom: -350px;
          background-image: linear-gradient(to bottom, #fce6e6, #fef4f4 57%, #ffffff);
        }

        @media ${media.tabletAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -330px;
          }
        }

        @media ${media.desktopAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -350px;
          }
        }
      `}</style>
      <CurvedContainer position="bottom" height={65} offset={140} overlayColor={color.darkPinkBackground}>
        <h1 className="hidden-seo-header">{getString('spend.public.title').toUpperCase()}</h1>
        <RewardsFilterWrapper isPublic={true} />
      </CurvedContainer>
      <div className="pink" />
      <RewardsListPublicSpend />
      <MobileAppsSplash />
    </>
  )
}
