import React, { ReactNode } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import classNames from 'classnames'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'
import { color as globalColor, media, breakpoints } from '@virgin-core/styles'
import { Dimensions, optimizeContentfulImageUrl } from '../../helpers/images'
import { Button } from '../Buttons/Button'
import ContentfulCopy from '../richText/contentfulCopy'
import { convertToKebabCase } from '../../helpers/text'
import { Body, Heading } from '..'
import { HeroBlockFields } from './types'

const HeroBlockMobileTextPosition = {
  top: 'Top',
  bottom: 'Bottom',
}
const HeroBlockDesktopTextPosition = {
  right: 'Right',
  left: 'Left',
}

export interface HeroBlockProps {
  sectionName: string
  children?: ReactNode
  fields: HeroBlockFields
  testId?: string
  fixedAspectRatio?: boolean
  textColour?: string
  topHero?: boolean
}

export const NewHeroBlock = ({ sectionName, children, fields, testId, textColour, fixedAspectRatio, topHero }: HeroBlockProps) => {
  const isTabletOrHigher = useMediaPredicate(media.tabletLargeAndHigher)
  const foregroundImageDimensions: Dimensions = { width: 1000, height: 750 }
  const foregroundUrl = optimizeContentfulImageUrl(fields?.foregroundImage?.fields?.file?.url, 'stretch', foregroundImageDimensions)
  const cx = classNames

  return (
    <>
      <style jsx>{`
        .hero-block {
          width: 100%;
          position: relative;
          height: 100%;
          overflow: auto;
        }
        .hero-block::after {
          display: none;
          content: '';
          width: 100%;
          height: 120px;
          position: absolute;
          bottom: 0;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%);
        }
        .component-container {
          height: 100%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: ${fields?.mobileTextPosition === HeroBlockMobileTextPosition.bottom ? 'column-reverse' : 'column'};
        }
        .text-panel {
          min-width: 45%;
          padding: 16px 0 0;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        .top-hero .text-panel {
          padding-bottom: 50px;
        }
        .top-hero :global(.grid-item.image-area) {
          padding-top: 36px;
        }
        .text-panel :global(.header-one),
        .text-panel :global(.header-two) {
          text-align: center;
        }
        .foreground-image {
          display: block;
          width: 100%;
          height: auto;
          position: relative;
          z-index: 1;
        }
        .buttons-wrapper {
          display: flex;
          margin-top: 8px;
          position: relative;
          justify-content: center;
          z-index: 1;
        }
        .primary-button {
          margin-right: 12px;
          max-width: 150px;
        }
        .secondary-button {
          max-width: 160px;
        }
        .children-container {
          position: relative;
          z-index: 1;
        }
        .children-container-mobile {
          margin: 0 -8px 24px;
        }
        .hero-block :global(.rich-text-copy p) {
          margin-top: 0;
        }

        @media ${media.tabletAndHigher} {
          .children-container-mobile {
            margin: 0 -12px 24px;
          }
        }

        @media ${media.tabletLargeAndHigher} {
          .component-container {
            ${fixedAspectRatio ? 'aspect-ratio: 2.35;' : 'height: 100%;'}
            flex-direction: ${fields?.desktopTextPosition === HeroBlockDesktopTextPosition.right ? 'row-reverse' : 'row'};
            justify-content: space-between;
            margin-bottom: ${children ? '-50px' : 0};
          }
          :global(.text-area) {
            align-self: center;
          }
          .text-panel {
            text-align: left;
            padding: 0 0 42px;
            margin-bottom: ${children ? '25px' : 0};
          }
          .text-panel :global(.header-one),
          .text-panel :global(.header-two) {
            text-align: left;
          }
          :global(.grid-item.image-area) {
            padding-top: 0;
            align-self: flex-end;
          }
          .top-hero :global(.grid-item.image-area) {
            align-self: center;
          }
          .foreground-image {
            object-fit: contain;
          }
          .hero-block :global(.rich-text-copy p) {
            font-size: 16px;
          }
          .buttons-wrapper {
            justify-content: flex-start;
          }
        }

        @media ${media.desktopAndHigher} {
          .component-container {
            justify-content: ${fields?.desktopTextPosition === HeroBlockDesktopTextPosition.left ? 'right' : 'left'};
            margin-bottom: ${children ? '-100px' : 0};
          }
          .text-panel {
            margin-bottom: ${children ? '80px' : 0};
          }
        }

        @media ${media.hiResDesktop} {
          .hero-block {
            ${fixedAspectRatio ? 'height: 610px;' : ''}
          }
          .hero-block :global(.rich-text-copy p) {
            font-size: unset;
          }
          .text-panel {
            padding: 0 0 100px;
            margin-bottom: 0;
          }
          .children-container {
            margin-top: -100px;
          }
          .component-container {
            margin-bottom: 0;
          }
        }
      `}</style>
      <div className={cx('hero-block', { 'top-hero': topHero })}>
        <GridWrapper>
          <GridContainer>
            <div className="component-container">
              <GridItem xs={12} md={6} lg={topHero ? 6 : 5} xl={5} className="text-area">
                <div className="text-panel">
                  <Heading
                    fontStyle="Normal"
                    color={textColour ? globalColor.white : globalColor.textStandard}
                    weight={600}
                    textAlign={window.innerWidth < breakpoints.tabletMin ? 'center' : 'left'}
                    marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                    marginBottom={{ mobile: 16, tablet: 16, desktop: 24 }}
                    fontSize={{ mobile: 28, tablet: 24, desktop: 50 }}
                    seoHeadline={topHero}
                  >
                    {fields?.headline}
                  </Heading>
                  <Body
                    color={textColour ? globalColor.white : globalColor.textStandard}
                    marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
                    marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
                    isDesktopTextBig
                  >
                    <ContentfulCopy content={fields?.body} />
                  </Body>
                  {children && !isTabletOrHigher && <div className="children-container-mobile">{children}</div>}
                  <div className="buttons-wrapper">
                    <div className="primary-button">
                      <Button
                        id={`${sectionName}_button_${convertToKebabCase(fields.button1Text!)}`}
                        hasBorder={Boolean(textColour)}
                        onClick={() => (window.location.href = fields?.button1Target!)}
                        text={fields?.button1Text!}
                        isPrimary
                        display="block"
                        dataAttributes={{ testid: `${testId}-button1` }}
                      />
                    </div>
                    <div className="secondary-button">
                      <Button
                        id={`${sectionName}_button_${convertToKebabCase(fields.button2Text!)}`}
                        onClick={() => (window.location.href = fields?.button2Target!)}
                        text={fields?.button2Text!}
                        display="block"
                        dataAttributes={{ testid: `${testId}-button2` }}
                      />
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} md={6} xl={topHero ? 7 : 6} className="image-area">
                {fields?.foregroundImage && (
                  <img
                    width={375}
                    height={290}
                    alt={fields?.foregroundImage?.fields?.description}
                    src={foregroundUrl}
                    className="foreground-image"
                  />
                )}
              </GridItem>
            </div>
          </GridContainer>
          {children && isTabletOrHigher && <div className="children-container">{children}</div>}
        </GridWrapper>
      </div>
    </>
  )
}
