import React from 'react'
import { color } from '../../../style/variables'
import { PersonalDetailsProps } from './types'
import { obfuscate } from '../../../helpers/text'
import { media } from '../../../style/media'
import { Page } from '../../../components'
import { Button } from '../../../components/Buttons/Button'
import { MyAccountBackground } from '../MyAccountBackground'
import { getLocalisedString, getRegionDisplayName } from '../../../../../data/src/utils/helperFunctions'
import { getViewingRegion } from '../../../dataImplementation/viewingRegionWeb'
import { getString } from '../../../../../data/src/utils'
import { FEATURE_FLAGS, VID_ACCOUNT } from '../../../config'

const PersonalDetails: React.FC<PersonalDetailsProps> = ({ profile, accounts, vidAccountCallbackParam, doDeleteAccountPrompt }) => {
  const viewingRegion = getViewingRegion()
  const openDeleteAccountRequestPage = () => window.open(getString('link.account.deleteAccountRequest'))
  const { vidAuth } = FEATURE_FLAGS

  const doUpdateAccount = () => {
    sessionStorage.setItem('didUpdateAccount', 'true')
    window.location.replace(`${VID_ACCOUNT}?callback=${vidAccountCallbackParam}` as string)
  }

  return (
    <Page title="Your personal details | Virgin Red" testId="page-account-personal-details">
      <MyAccountBackground>
        <style jsx>
          {`
            a {
              color: ${color.brandPrimary};
              font-weight: 600;
              text-decoration: none;
            }

            a.email-edit {
              font-weight: 400;
              margin-left: 16px;
            }

            .heading {
              font-weight: bold;
              margin-bottom: 8px;
              margin-top: 10px;
            }

            .subheading {
              color: ${color.lighterGrey};
              font-size: 12px;
            }

            .value {
              margin-bottom: 40px;
            }

            .support-note {
              margin-bottom: 25px;
            }

            .delete-account {
              margin: 25px 0;
            }

            .line {
              background-color: ${color.secondaryButtonBorder};
              height: 1px;
              width: 100%;
              margin-bottom: 40px;
              margin-top: 50px;
            }

            @media ${media.tabletAndHigher} {
              .line {
                margin-bottom: 50px;
                margin-top: 70px;
              }
            }
          `}
        </style>
        <div className="heading">Name</div>
        <div className="value" data-testid="name">{`${obfuscate(profile?.firstName)} ${obfuscate(profile?.lastName)}`}</div>
        <div className="heading">{getString(`profile.labels.phone${getLocalisedString(viewingRegion)}`)}</div>
        <div className="value" data-testid="mobile-number">{`${obfuscate(profile?.mobile!, true, 2, 2)}`}</div>
        <div className="heading">
          Email address
          {!vidAuth && (
            <a className="email-edit" href="/virgin-red/account/update-email">
              Edit
            </a>
          )}
        </div>
        <div className="value" data-testid="email">{`${profile?.email}`}</div>
        <div className="heading">Date of birth</div>
        <div className="value" data-testid="date-of-birth">
          {'**/**/****'}
        </div>
        <div className="heading">Country</div>
        <div className="value" data-testid="region">
          {(profile?.country && getRegionDisplayName(profile?.country)) || 'unknown'}
        </div>
        <div className="heading">{getString(`profile.labels.postcode${getLocalisedString(viewingRegion)}`)}</div>
        <div className="value" data-testid="postcode">
          {obfuscate(profile?.postcode, false)}
        </div>
        <div className="heading">Virgin Red ID</div>
        <div className="value" data-testid="red-id">
          {profile?.redUserId}
        </div>
        {accounts.VAA && accounts.VAA.partnerUserId ? (
          <>
            <div className="heading">Flying Club Number</div>
            <div className="value">{accounts.VAA.partnerUserId}</div>
          </>
        ) : null}
        {vidAuth ? (
          <>
            <p>{getString('profile.updateDetailsNote')}</p>
            <Button
              isPrimary
              dataAttributes={{ testid: 'update-account' }}
              buttonType="submit"
              isFullWidth={false}
              display={'inline-block'}
              text={'Update my details'}
              onClick={() => doUpdateAccount()}
            />
          </>
        ) : (
          <>
            <div className="support-note" data-testid="support-note">
              To help keep your data safe and secure, we only display partial information on this page. You can request the full information
              by contacting customer support.
            </div>
            <div>
              If you need to update any of the information on this page, please contact{' '}
              <a href="https://membersupport.red.virgin.com/hc/en-gb" data-testid="customer-support">
                customer support.
              </a>
            </div>
          </>
        )}
        <div className="delete-account">
          <Button
            dataAttributes={{ testid: 'delete-account' }}
            buttonType="submit"
            isFullWidth={false}
            display={'inline-block'}
            text={'Delete Account'}
            onClick={() => doDeleteAccountPrompt(openDeleteAccountRequestPage)}
          />
        </div>
      </MyAccountBackground>
    </Page>
  )
}

export { PersonalDetails }
