/**
 * Parses the errors of the object returned by Joi's `schema.validate()` function.
 * These errors, if any, are in a nested array that is converted into an object:
 * ```
 * const [errors, setErrors] = useState<PersonalDetailsType>({})
 * // ...
 * const formValidation = validatePersonalDetails(formState)
 * if (formValidation.error) {
 *   setErrors(parseJoiErrors(formValidation.error.details))
 * }
 * ```
 *
 * The returned object will look like:
 * ```
 * {
 *   firstName: 'any.required',
 *   lastName: 'any.required',
 *   country: 'any.required',
 *   // etc
 * }
 * ```
 *
 * which has a simpler structure to use than the complex object returned by Joi.
 *
 * If no errors found `undefined` is returned.
 */
export const parseJoiErrors = (errors: any[] | undefined) => {
  return errors?.reduce((acc, curr) => {
    acc[curr.context.key] = curr.type
    return acc
  }, {})
}

export const parseJoiErrorsMobile = (errors: any[], screenId: string) =>
  errors.reduce((acc, curr) => {
    const {
      context: { key },
      type,
    } = curr
    if (key) {
      acc[key] = `${screenId}.${key}.error.${type}`
    }
    return acc
  }, {})
