import { connect } from 'react-redux'

import { doLoginWeb } from 'data/src/redux/auth/dispatchers'
import { DispatchFunction } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'

import { PartnerLoginSuccess } from './PartnerLoginSuccess'
import { getPath } from '../../dataImplementation/viewingRegionWeb'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(getPath() || undefined),
    },
    dispatch
  )

const PartnerLoginSuccessContainer = connect(null, mapDispatchToProps)(PartnerLoginSuccess)

export { PartnerLoginSuccessContainer }
