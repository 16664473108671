import { RewardEarn } from '../redux/rewardsEarn/types'
import { Reward } from '..//redux/rewards/types'
import { RedDataState } from '../types'
import { getAllRewardsIds, getRewardById } from '../redux/rewards/selectors'
import { getAllRewardsIds as getAllRewardsEarnIds, getRewardById as getRewardEarnById } from '../redux/rewardsEarn/selectors'

const MAX_NUMBER_ITEMS = 8

export const getRecommendedItemsCarousel = (state: RedDataState, addToStart?: Reward): (Reward | RewardEarn)[] => {
  const spendIds = getAllRewardsIds(state).slice(0, MAX_NUMBER_ITEMS)
  const earnIds = getAllRewardsEarnIds(state).slice(0, MAX_NUMBER_ITEMS)
  const mixIds: string[] = []
  const minLength = Math.min(spendIds.length, earnIds.length)

  for (let i = 0; i < minLength; i++) {
    mixIds.push(spendIds[i], earnIds[i])
  }
  mixIds.push(...spendIds.slice(minLength), ...earnIds.slice(minLength))

  const itemsResult = mixIds.slice(0, MAX_NUMBER_ITEMS).map((mixId) => getRewardById(state, mixId) || getRewardEarnById(state, mixId))

  if (addToStart) {
    itemsResult.unshift(addToStart)
    itemsResult.pop()
  }

  return itemsResult
}
