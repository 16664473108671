import { logger } from '../../../utils'
import { DispatchFunction, RedDataState } from '../../types'

import { showDialog } from '../../dialog/actions'
import { logoutStart, logoutSuccess, logoutStop } from '../actions'
import { clearContent } from '../../actions'
import { DialogType } from '../../dialog/types'
import { getRedDataConfig } from '../../../config'
import { fetchFeatures } from '../../features/features.thunk'
import { EVIDENTLY_PROJECT } from '../../features/features.types'

const doLogout = (redirectPath?: string) => async (dispatch: DispatchFunction, getState: () => RedDataState) => {
  logger.log('doLogout()')
  if (getState().auth.isUnauthenticating) {
    logger.log('doLogout cancelled as already unauthenticating')
    return
  }
  dispatch(logoutStart())
  const config = getRedDataConfig()
  try {
    config.loggedInUserChanged && (await config.loggedInUserChanged())
    config.navigate(redirectPath || config.navTargets.Public)
    dispatch(logoutSuccess())
    dispatch(clearContent())
    await dispatch(fetchFeatures({ project: EVIDENTLY_PROJECT, sessionId: 'loggedOutUser' }))
    await config.auth.logout(`${redirectPath}`)
  } catch (error) {
    logger.warn('doLogout.ts', error)
    if ((error as auth0.Auth0Error)?.error?.match(/user_cancelled/)) {
      dispatch(logoutStop())
    }
  }
}

const doLogoutPrompt = () => (dispatch: DispatchFunction) => {
  dispatch(
    showDialog({
      callbackOnConfirm: () => dispatch(doLogout()),
      type: DialogType.ALERT,
      titleTextId: 'logoutPrompt.title',
      bodyTextId: 'logoutPrompt.description',
      buttonCancelTextId: 'actions.cancel',
      buttonConfirmTextId: 'actions.logOut',
    })
  )
}

const doCancelOnboardingPrompt = () => (dispatch: DispatchFunction) => {
  dispatch(
    showDialog({
      callbackOnConfirm: () => dispatch(doLogout()),
      type: DialogType.ALERT,
      titleTextId: 'cancelOnboardingPrompt.title',
      bodyTextId: 'cancelOnboardingPrompt.description',
      buttonCancelTextId: 'actions.no',
      buttonConfirmTextId: 'actions.yes',
    })
  )
}

export { doLogout, doLogoutPrompt, doCancelOnboardingPrompt }
