import { connect } from 'react-redux'

import { doSubmitOnboarding } from 'data/src/redux/profile/dispatchers'
import { getIsUpdating } from 'data/src/redux/profile/selectors'
import { startAutoLinkAccountsFlow } from 'data/src/redux/accounts/actions'

import { CommunicationPreferences } from './CommunicationPreferences'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

const mapStateToProps = (state: RootState) => ({
  isUpdating: getIsUpdating(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doSubmitOnboarding,
      startAutoLinkAccountsFlow,
    },
    dispatch
  )

const CommunicationPreferencesContainer = connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferences)

export { CommunicationPreferencesContainer }
