import { PointsRange } from '../redux/categories/types'
import { AsYouType } from 'libphonenumber-js'
import { logger } from './'
import { getISODateAndTime } from './dates'
import { Buffer } from 'buffer'

export const cacheBustCheck = async () => {
  logger.log('cacheBustCheck')
  // get cache bust hash
  const cacheBustHashLS = localStorage.getItem('savedCacheBustHash')
  const newestCacheBustHash = (<any>window).cacheBustHash
  if (cacheBustHashLS) {
    // if hashes are out of sync, cache-bust the redux store
    if (newestCacheBustHash !== cacheBustHashLS) {
      localStorage.removeItem('persist:root')
      window.location.reload()
    }
  } else if (newestCacheBustHash) {
    localStorage.setItem('savedCacheBustHash', newestCacheBustHash)
  }
}

export const partition = <T>(array: Array<T>, predicate: (item: T) => boolean) =>
  array.reduce(
    (acc, e) => {
      acc[predicate(e) ? 0 : 1].push(e)
      return acc
    },
    [[] as Array<T>, [] as Array<T>]
  )

// https://stackoverflow.com/questions/39468022/how-do-i-know-if-my-code-is-running-as-react-native/39473604
export const isWeb = () => {
  return typeof document !== 'undefined'
}

export const numberWithCommas = (x: number | string): string => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const getPointsLabels = (obj: PointsRange): string[] => {
  const labels: string[] = []
  Object.entries(obj).forEach((item) => {
    if (item[0].includes('+')) {
      const parts = item[0].split('+')
      if (parts.length > 0) labels.push(numberWithCommas(parts[0]) + '+')
    } else if (item[0].includes('-')) {
      const parts = item[0].split('-')
      if (parts.length > 1) labels.push(numberWithCommas(parts[0]) + ' - ' + numberWithCommas(parts[1]))
    }
  })
  return labels
}

export enum Locale {
  EN_GB = 'en-gb',
  EN_US = 'en-us',
}

export enum ViewingRegion {
  GB = 'GB',
  US = 'US',
}

export enum LinkingJourneyOrigins {
  ONBOARDING = 'onboarding',
  ACCOUNT = 'account',
  PARTNER = 'partner',
}

export enum ViewingRegionDisplayName {
  GB = 'United Kingdom',
  US = 'United States',
}

export const getRegionCode = (key: ViewingRegion): ViewingRegion => ViewingRegion[key]

export const getRegionDisplayName = (key: ViewingRegion): ViewingRegionDisplayName => ViewingRegionDisplayName[key]

export const getPhoneMaxLengthByRegion = (region?: ViewingRegion, isRawNumber?: boolean): number => {
  if (isRawNumber) {
    return region === ViewingRegion.GB ? 11 : 10
  }

  return region === ViewingRegion.GB ? 12 : 14
}

export const getLocalisedString = (region?: ViewingRegion) => (region !== ViewingRegion.GB ? `.${region}` : '')

export const parseDigits = (input?: string) => (input?.match(/\d+/g) || []).join('')

export const formatPhoneNumber = (input: string, country?: ViewingRegion, isRawNumber?: boolean) => {
  const newCountry = country || ViewingRegion.GB
  if (!input) return ''
  if (input.includes('(') && !input.includes(')')) {
    return input.replace('(', '')
  }
  const digits = parseDigits(input).substr(0, getPhoneMaxLengthByRegion(newCountry, isRawNumber))
  return new AsYouType(newCountry).input(digits)
}

export const delay = async (delayTime: number) => new Promise((resolve) => setTimeout(resolve, delayTime))

export const enumCaseToSentenceCase = (enumCaseText: string) => {
  let text = enumCaseText.trim().toLowerCase()
  text = text.replace('_', ' ')
  const sentenceCaseText = text[0].toUpperCase() + text.slice(1)
  return sentenceCaseText
}

export const jsonToBase64 = <Record>(object: Record) => {
  const json = JSON.stringify(object)
  return Buffer.from(json).toString('base64')
}

export const dateOfBirthToAgeBand = (dateOfBirthStr: string): string => {
  const dateStr = getISODateAndTime()
  const today = new Date(dateStr)
  const dateOfBirth = new Date(dateOfBirthStr)
  let age = today.getFullYear() - dateOfBirth.getFullYear()
  const monthDiff = today.getMonth() - dateOfBirth.getMonth()
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
    age--
  }

  if (age < 18) return '< 18'
  if (age <= 24) return '18-24'
  if (age <= 29) return '25-29'
  if (age <= 39) return '30-39'
  if (age <= 49) return '40-49'
  if (age <= 59) return '50-59'
  if (age <= 69) return '60-69'
  return '70+'
}
