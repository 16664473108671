import { connect } from 'react-redux'

import { getCategories, getCategoryCostCounts } from 'data/src/redux/categories/selectors'
import { doUpdateRewardsPaginated } from 'data/src/redux/discovery/dispatchers'
import { doFetchCategories } from 'data/src/redux/categories/dispatchers'
import {
  getIsFiltersChanged,
  getIsLastResultFiltered,
  getSelectedCategories,
  getSelectedCategoryCostCounts,
  getIsFiltersSet,
} from 'data/src/redux/discovery/selectors'
import {
  clearCategoryFilter,
  clearCategoryCostCountFilter,
  clearAllFilters,
  toggleCategorySelected,
  toggleCategoryCostCountSelected,
  resetPagination,
} from 'data/src/redux/discovery/actions'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'

import { RewardsFilter } from './RewardsFilter'

const mapStateToProps = (state: RootState) => ({
  categories: getCategories(state),
  categoryCostCounts: getCategoryCostCounts(state),
  selectedCategories: getSelectedCategories(state),
  selectedCategoryCostCounts: getSelectedCategoryCostCounts(state),
  isFiltersChanged: getIsFiltersChanged(state),
  isLastResultsFiltered: getIsLastResultFiltered(state),
  isFiltersSet: getIsFiltersSet(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      toggleCategorySelected,
      toggleCategoryCostCountSelected,
      doFetchCategories,
      clearCategoryFilter,
      clearCategoryCostCountFilter,
      clearAllFilters,
      doUpdateRewards: doUpdateRewardsPaginated,
      clearPaginationFromView: resetPagination,
    },
    dispatch
  )

export const PrivateRewardsFilterSpendContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsFilter)
