import { logger } from '../../../utils/logger'
import { getRedDataConfig } from '../../../config'
import { DispatchFunction } from '../../types'
import { stepUpStart, stepUpFailure, stepDownStart, stepDownSuccess, stepUpSuccess } from '../actions'

export const doStepUp = () => async (dispatch: DispatchFunction) => {
  logger.log('doStepUp()')
  dispatch(stepUpStart())
  const config = getRedDataConfig()
  try {
    const { accessToken } = (await config.auth.stepUp()) || {}
    if (accessToken) {
      await config.secrets.save('stepUpAccessToken', accessToken)
      dispatch(stepUpSuccess())
    } else {
      dispatch(stepUpFailure())
      throw Error
    }
  } catch (error) {
    dispatch(stepUpFailure())
    throw error
  }
}

export const doStepDown = () => async (dispatch: DispatchFunction) => {
  logger.log('doStepDown()')
  dispatch(stepDownStart())
  const config = getRedDataConfig()
  await config.secrets.delete('stepUpAccessToken')
  dispatch(stepDownSuccess())
}
