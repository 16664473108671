import React, { FC, useEffect, useState } from 'react'
import { getProfileData } from 'data/src/redux/profile/selectors'
import { getIsAutoLinking, getAutoLinkComplete } from 'data/src/redux/accounts/selectors'
import { doPatchProfile } from 'data/src/redux/profile/dispatchers'
import { doAutoLinkAccounts } from 'data/src/redux/accounts/dispatchers'

import { useAppSelector, useAppDispatch } from '../../../redux/hooks/hooks'
import { getPartnerLoginParams, removeAllPartnerLoginParams } from '../../../dataImplementation/partnerConnectWeb'
import H3Header from '../../../components/typography/Red/H3Header'
import { Spacer, Page, LoadSpinner } from '../../../components'
import { media } from '../../../style/media'

import { OnboardingBackground } from '../OnboardingBackground'
import { AutoLinkAccountsSuccess } from './AutoLinkAccountsSuccess'
import { AutoLinkAccountsError } from './AutoLinkAccountsError'

const AutoLinkAccounts: FC = () => {
  const [isAutoLinkAccountsResolved, setIsAutoLinkAccountsResolved] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const isAutoLinking = useAppSelector(getIsAutoLinking)
  const autoLinkComplete = useAppSelector(getAutoLinkComplete)
  const profile = useAppSelector(getProfileData)
  const isVAALinked = profile?.linkedAccountsState?.VAA

  const linkAccounts = async () => {
    const { connectPartnerCode: partnerCode, onConnectSuccessUrl, onConnectFailUrl } = getPartnerLoginParams()
    const idToken = localStorage.getItem('partnerLinkIdToken')
    let response

    if (idToken && partnerCode) {
      if (!autoLinkComplete) {
        response = await dispatch(doAutoLinkAccounts(idToken, partnerCode, onConnectSuccessUrl, onConnectFailUrl))
      }
      if (!isAutoLinking) {
        removeAllPartnerLoginParams()
        dispatch(doPatchProfile(isVAALinked ? { complete: true } : { linkedAccountsState: { VAA: false }, complete: true }))
        return response?.done ? setIsAutoLinkAccountsResolved(true) : setIsAutoLinkAccountsResolved(false)
      }
    } else {
      return autoLinkComplete ? setIsAutoLinkAccountsResolved(true) : setIsAutoLinkAccountsResolved(false)
    }
    return true
  }

  useEffect(() => {
    linkAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AutoLinkAccountsPending = () => (
    <>
      <style jsx>{`
        html,
        body {
          height: 100vh;
        }
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parentContainer {
          animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
        }
        .parentContainer :global(.header-three) {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        .loading {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;
        }
        @media ${media.tabletAndHigher} {
          .parentContainer {
            margin: 0 100px;
          }
        }
      `}</style>
      <div className="parentContainer">
        <H3Header marginTop={{ mobile: 0 }} marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
          Connecting to partner...
        </H3Header>
        <Spacer size="xLarge" />
        <div className="loading">
          <LoadSpinner />
        </div>
      </div>
    </>
  )

  return (
    <Page
      testId="partner-auto-link"
      title="Welcome to the club | Virgin Red"
      showNavigation={false}
      showRedFooter={false}
      showFooter={false}
    >
      <OnboardingBackground showSteps={false}>
        {isAutoLinking && <AutoLinkAccountsPending />}
        {!isAutoLinking && (isAutoLinkAccountsResolved ? <AutoLinkAccountsSuccess /> : <AutoLinkAccountsError />)}
      </OnboardingBackground>
    </Page>
  )
}

export { AutoLinkAccounts }
