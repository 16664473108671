import React, { FC } from 'react'
import { AuthWrapper, Page } from '../../components'
import { EarnPublic } from './EarnPublic'
import { EarnPrivateContainer as EarnPrivate } from './EarnPrivateContainer'
import { getString } from 'data'

const Earn: FC = () => {
  return (
    <Page testId="page-earn" title="Earn Virgin Points | Virgin Red" metaDescription={getString(`meta.description.earn`)}>
      <AuthWrapper publicComponent={<EarnPublic />} privateComponent={<EarnPrivate />} />
    </Page>
  )
}

export { Earn }
