import React, { FC } from 'react'

import { RedemptionType } from 'data/src/redux/rewards/types'

import { CustomLink, H3Header, Body, Picture } from '../../../components'
import { CurvedContainer } from '../../../components/CurvedContainer'
import { Points } from '../../Points'
import { WishListIcon } from '../../WishListIcon'

import { PATHS } from '../../../router/paths'
import { slugStructureForLinking } from '../../../router/seoHelpers'
import { optimizeContentfulImageUrl, brandLogoImages } from '../../../helpers/images'
import { media } from '../../../style/media'
import { color, ZIndex } from '../../../style/variables'

export interface RewardTileProps {
  type: 'reward'
  rewardId: string
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string
  cost: number
  redemptionType?: string
  onClick?: (e: React.MouseEvent) => void
  height?: number
  slug?: string
  showWishListIcons?: boolean
  isWished?: boolean
  onWishListIconClick?: () => void
  isLoadingWishlist?: boolean
}

const RewardTile: FC<RewardTileProps> = ({
  rewardId,
  imageUrl,
  brandLogoUrl,
  brandName,
  title,
  lead,
  cost,
  redemptionType,
  onClick,
  onWishListIconClick,
  height,
  slug,
  showWishListIcons = false,
  isWished,
  isLoadingWishlist,
}) => {
  const rewardPath = slugStructureForLinking({ rewardId, slug, baseUrl: PATHS.SPEND })

  const responsiveImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 350, height: 197 }),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 700, height: 394 }),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 422, height: 237 }),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 844, height: 474 }),
    },
  }
  return (
    <>
      <style jsx>{`
        .reward-card-content {
          margin: 0 16px;
          ${height && `height: ${height}px;`}
        }
        .reward-card-content :global(.header-three) {
          font-size: 28px !important;
          line-height: 32px !important;
        }
        .reward-card-image {
          border-radius: 8px 8px 0 0;
          display: block;
          height: auto;
          overflow: hidden;
          width: 100%;
        }
        .reward-card-image-container {
          position: relative;
          width: 100%;
        }
        .reward-card-logo {
          bottom: -10px;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          height: 72px;
          left: 16px;
          position: absolute;
          width: 72px;
          z-index: 1;
        }
        .reward-card-points {
          color: ${color.brandPrimary};
          font-weight: 600;
          font-style: italic;
          font-size: 28px;
          letter-spacing: 0.6px;
          line-height: 1.14;
        }
        .wishlist-icon-container {
          cursor: pointer;
          position: absolute;
          top: 8px;
          right: 8px;
          z-index: ${ZIndex.carouselBoxShadow};
        }
        @media ${media.tabletAndHigher} {
          .reward-card-content {
            padding-bottom: 70px;
          }
          .reward-card-points {
            bottom: 0;
            position: absolute;
          }
        }
      `}</style>
      {showWishListIcons && (
        <div className="wishlist-icon-container" data-testid="wishlist-icon">
          <WishListIcon isWished={isWished} onWishListIconClick={onWishListIconClick} isLoading={isLoadingWishlist} />
        </div>
      )}
      <CustomLink to={rewardPath} onClick={onClick}>
        <>
          <div className="reward-card-image-container">
            <div className="reward-card-image">
              <Picture
                altText={`${title} reward`}
                width={422}
                fallbackImg={optimizeContentfulImageUrl(imageUrl, 'fill', { width: 422, height: 237 }, 65)}
                height={237}
                responsiveImages={responsiveImages}
                loading={'lazy'}
                isWebP={true}
              />
              <CurvedContainer position="bottom" rewards={true} />
            </div>
            {brandLogoUrl && (
              <div className="reward-card-logo">
                <Picture
                  altText={`${brandName} logo`}
                  width={144}
                  fallbackImg={optimizeContentfulImageUrl(brandLogoUrl, 'fill', { width: 144, height: 144 })}
                  height={144}
                  responsiveImages={brandLogoImages(brandLogoUrl)}
                  loading={'lazy'}
                  isWebP={true}
                />
              </div>
            )}
          </div>
          <div className="reward-card-content">
            <H3Header
              textAlign="left"
              weight={600}
              marginTop={{
                mobile: 28,
                tablet: 28,
                desktop: 28,
              }}
              marginBottom={{
                mobile: 24,
                tablet: 24,
                desktop: 24,
              }}
            >
              {title}
            </H3Header>
            {lead && <Body>{lead}</Body>}
            <p className="reward-card-points">
              <Points value={cost} showPlus={redemptionType === RedemptionType.SIMPLE_LINK} />
              <span> point{cost !== 1 ? 's' : ''}</span>
            </p>
          </div>
        </>
      </CustomLink>
    </>
  )
}

export { RewardTile }
