import { get } from '../utils/httpClient'
import { getRedDataApiPaths, getRedDataConfig } from '../config'
import { Reward, RewardResponse, RewardTags } from '../redux/rewards/types'
import { RewardEarn, RewardEarnResponse } from '../redux/rewardsEarn/types'
import { CategoriesResponse, RewardCategory } from '../redux/categories/types'
import { CategoriesParameters } from './types'
import { RewardsSort } from '../redux/discovery/types'
import { SearchResponse } from '../redux/search/types'

const LIMIT = 8

export const apiGetAllCategories = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES}?${queryString}`, true)).categories as RewardCategory[]
}

export const apiGetAllCategoriesAndCategoryCostCounts = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES}?${queryString}`, true)) as CategoriesResponse
}

export const apiGetAllCategoriesEarn = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN}?${queryString}`, true)).categories as RewardCategory[]
}

export const apiGetEarnRewardsByCategory = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categories }, undefined, limit)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategory = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categories }, undefined, limit)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCount = async (categoriesData: CategoriesParameters, sort?: RewardsSort) => {
  const queryString = getApiUrlParameters(categoriesData, sort)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetAllCategoriesPublic = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_PUBLIC}?${queryString}`)) as CategoriesResponse
}

export const apiGetAllCategoriesEarnPublic = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN_PUBLIC}?${queryString}`)).categories as RewardCategory[]
}

export const apiGetSearchByKeyword = async (keyword: string) => {
  const queryString = getApiUrlParameters(undefined, undefined, undefined, keyword)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_SEARCH}?${queryString}`, true)) as SearchResponse
}

export const apiGetEarnRewardsByCategoryPaginated = async ({
  isPublicEndpoint,
  categories,
  limit,
  offset,
}: {
  isPublicEndpoint: boolean
  categories: string[]
  limit: number
  offset: number
}) => {
  const queryString = getApiUrlParameters({ categories })
  return (await get(
    `${
      isPublicEndpoint ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN
    }?limit=${limit}&offset=${offset}${queryString ? `&${queryString}` : ''}`,
    isPublicEndpoint ? false : true
  )) as RewardEarnResponse
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated = async ({
  isPublicEndpoint,
  categoriesData,
  limit,
  offset,
  sort,
}: {
  isPublicEndpoint: boolean
  categoriesData: CategoriesParameters
  limit: number
  offset: number
  sort?: RewardsSort
}) => {
  const queryString = getApiUrlParameters(categoriesData, sort)
  return (await get(
    `${
      isPublicEndpoint ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND
    }?limit=${limit}&offset=${offset}${queryString ? `&${queryString}` : ''}`,
    isPublicEndpoint ? false : true
  )) as RewardResponse
}

export const apiGetEarnRewardsByCategoryPublic = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categories }, undefined, limit)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC}?${queryString}`)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategoryPublic = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categories }, undefined, limit)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}?${queryString}`)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCountPublic = async (categoriesData: CategoriesParameters, sort?: RewardsSort) => {
  const queryString = getApiUrlParameters(categoriesData, sort)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}?${queryString}`)).rewards as Reward[]
}

// **** getRewardById **** //
export const apiGetRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}/${rewardId}?${queryString}`, true)) as Reward
}

export const apiGetEarnRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}/${rewardId}?${queryString}`, true)) as RewardEarn
}

export const apiGetPublicRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}/${rewardId}?${queryString}`)) as Reward
}

export const apiGetPublicEarnRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC}/${rewardId}?${queryString}`)) as RewardEarn
}

export const getApiUrlParameters = (
  categoriesData?: CategoriesParameters,
  sort?: RewardsSort,
  limit?: number,
  searchKeyword?: string,
  tag?: string
) => {
  const viewingRegion = getRedDataConfig().getViewingRegion()
  return [
    categoriesData?.categories?.map((category) => `categoryName=${encodeURIComponent(category)}`).join('&'),
    categoriesData?.categoryCostCounts?.map((categoryCostCount) => `costRangeId=${encodeURIComponent(categoryCostCount)}`).join('&'),
    sort ? `sort=${sort}` : '',
    tag ? `tag=${tag}` : '',
    limit && limit > 0 ? `limit=${limit}` : '',
    `viewingRegion=${viewingRegion}`,
    searchKeyword ? `keyword=${encodeURIComponent(searchKeyword)}` : '',
  ]
    .filter(Boolean)
    .join('&')
}

export const apiGetLatestEarnRewards = async () => {
  const queryString = getApiUrlParameters(undefined, RewardsSort.CreatedDate, LIMIT)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetLatestSpendRewards = async () => {
  const queryString = getApiUrlParameters(undefined, RewardsSort.CreatedDate, LIMIT)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetPopularEarnRewards = async () => {
  const queryString = getApiUrlParameters(undefined, undefined, LIMIT, undefined, RewardTags.Popular)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetPopularSpendRewards = async () => {
  const queryString = getApiUrlParameters(undefined, undefined, LIMIT, undefined, RewardTags.Popular)
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}
