import React, { useCallback } from 'react'
import { View, Text, Tooltip, XStack } from '@red-ui/components/'
import { CARD_WIDTH } from '../styles'
import { PlaceholderIcon, QuestionIcon } from './styles'

type Props = {
  description: string
  extendedDescription?: string
}

export const Feature = ({ description, extendedDescription }: Props) => {
  const renderDescription = useCallback(() => {
    const hasTooltip = !!extendedDescription

    if (hasTooltip) {
      return (
        <Tooltip placement="top" size="$2">
          <Tooltip.Trigger flexShrink={1}>
            <Text fontSize="$4" minWidth={0}>
              {description}
              <Tooltip.Anchor display="inline" marginLeft="$2" top="$0.5">
                <QuestionIcon>
                  <Text color="$white" fontSize="$1">
                    ?
                  </Text>
                </QuestionIcon>
              </Tooltip.Anchor>
            </Text>
          </Tooltip.Trigger>
          <Tooltip.Content
            borderRadius="$4"
            enterStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
            maxWidth={CARD_WIDTH}
            animation={[
              'quick',
              {
                opacity: {
                  overshootClamping: true,
                },
              },
            ]}
          >
            <Tooltip.Arrow size="$4" />
            <Text>{extendedDescription}</Text>
          </Tooltip.Content>
        </Tooltip>
      )
    }

    return (
      <View flexShrink={1}>
        <Text fontSize="$4" minWidth={0}>
          {description}
        </Text>
      </View>
    )
  }, [description, extendedDescription])

  return (
    <li>
      <XStack alignItems="center" paddingVertical="$1">
        <PlaceholderIcon />
        {renderDescription()}
      </XStack>
    </li>
  )
}
