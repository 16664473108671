import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { selectFeatureById } from 'data/src/redux/features/features.selectors'

import { Login, LoginSuccess, LogoutSuccess, NotFound, EmailUpdated, PartnerLoginSuccess } from '../pages'
import { PATHS, virginRedPath } from './paths'
import { RegionalisedRoutes } from './regionalizedRoutes'
import { useAppSelector } from '../redux/hooks/hooks'
import { Locale } from 'data/src/utils'
import { FeatureName } from 'data/src/redux/features/features.types'

export const RootRouter = () => {
  const partnerConnectFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_PARTNER_CONNECT_PARAMS))

  return (
    <Routes>
      <Route path={virginRedPath + PATHS.LOGIN_SUCCESS} element={<LoginSuccess />} />
      <Route path={virginRedPath + PATHS.LOGOUT_SUCCESS} element={<LogoutSuccess />} />
      <Route path={virginRedPath + PATHS.LOGIN} element={<Login />} />
      <Route path={virginRedPath + PATHS.EMAIL_UPDATED} element={<EmailUpdated />} />
      {partnerConnectFlag && <Route path={virginRedPath + PATHS.PARTNER_LOGIN_SUCCESS} element={<PartnerLoginSuccess />} />}

      <Route path={`${virginRedPath}/*`} element={<RegionalisedRoutes />} />
      <Route path={`/${Locale.EN_US}/virgin-red/*`} element={<RegionalisedRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
