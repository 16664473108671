import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { RedAppThemeProvider } from '@virgin-core/components'
import { color, FontFamilies } from '@virgin-core/styles'
import { store } from '../redux/configureStore'
import { BrazeProvider } from '../context/BrazeProvider'
import { RegionSelectorProvider, useRegionSelector } from '../context/RegionSelectorProvider'
import { LoadingScreen, RegionSelectorModal } from '../components'
import { App } from './App'
import { media } from '../style/media'
import { BrowserRouter } from 'react-router-dom'
import { TamaguiProvider, config } from '@red-ui/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})

const AppWrapper: FC = (props) => {
  const { isRegionSelectorModalVisible } = useRegionSelector()
  const isScrollable = !isRegionSelectorModalVisible

  return (
    <>
      <style jsx global>{`
        html,
        body {
          height: 100%;
          margin: 0;
        }
        body {
          color: ${color.textStandard};
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          overflow-x: hidden;
          overflow-y: ${isScrollable ? 'auto' : 'hidden !important'};
        }
        h1 {
          font-weight: 700;
        }
        * {
          box-sizing: border-box;
        }
        .second-level-nav {
          display: none;
        }
        @media ${media.desktopAndHigher} {
          .second-level-nav {
            display: block;
          }
        }
      `}</style>
      <QueryClientProvider client={queryClient}>
        <TamaguiProvider config={config} defaultTheme="light">
          <RedAppThemeProvider>
            <BrazeProvider>
              <RegionSelectorProvider>
                <Provider store={store}>
                  <BrowserRouter>
                    <App {...props} />
                    <RegionSelectorModal />
                    <LoadingScreen />
                  </BrowserRouter>
                </Provider>
              </RegionSelectorProvider>
            </BrazeProvider>
          </RedAppThemeProvider>
        </TamaguiProvider>
      </QueryClientProvider>
    </>
  )
}

export { AppWrapper }
