import React from 'react'
import { Document } from '@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types'
import { ColorTokens, YStack, styled } from '@red-ui/components'
import { RichTextRenderer } from './RichTextRenderer'

interface DynamicRichTextProps {
  fields: {
    text: Document
    colour?: string
    align?: 'left' | 'center' | 'right'
  }
}

const BlockContainer = styled(YStack, {
  flex: 1,
  width: '100%',
  alignItems: 'center',
})

const colourMap: { [key: string]: ColorTokens } = {
  black: '$black10',
  white: '$white',
  red: '$red6',
}

export const DynamicRichText = ({ fields: { text, colour = 'black', align = 'left' } }: DynamicRichTextProps) => (
  <BlockContainer>
    <RichTextRenderer content={text} color={colourMap[colour]} style={{ textAlign: align, width: '100%' }} />
  </BlockContainer>
)
