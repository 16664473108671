import { connect } from 'react-redux'
import { doLogoutWebSuccess } from 'data/src/redux/auth/dispatchers'
import { getIsUnauthenticating } from 'data/src/redux/auth/selectors'
import { LogoutSuccess } from './LogoutSuccess'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { DispatchFunction, RootState } from '../../redux/types'

const mapStateToProps = (state: RootState) => ({
  isUnauthenticating: getIsUnauthenticating(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogoutWebSuccess,
    },
    dispatch
  )

const LogoutSuccessContainer = connect(mapStateToProps, mapDispatchToProps)(LogoutSuccess)

export { LogoutSuccessContainer }
