import React, { FC, useEffect } from 'react'
import { PendingPointsData, StatementData } from 'data/src/redux/statement/types'
import { getString } from 'data'
import { Content, LoadSpinner, Spacer } from '../index'
import { FontFamilies, color } from '../../style/variables'
import { media } from '../../style/media'
import { OldActivityStatementItem } from '../OldActivityStatementItem/OldActivityStatementItem'
import { Button } from '../Buttons/Button'
import LinkButton from '../Buttons/LinkButton'
import { ButtonStyle } from '../Buttons/LinkButton/linkButton'
import { TextId } from '../TextId/TextId'
import DashedDivider from '../DashedDivider'
import { localizePath } from '../../dataImplementation/viewingRegionWeb'
import { PATHS } from '../../router/paths'

export interface ActivityStateProps {
  statement: StatementData
  total: number
  isRefreshing: boolean
  hasLoaded: boolean
  showLoadMore: boolean
  pendingPoints: PendingPointsData
}

export interface ActivityDispatchProps {
  onMount: () => Promise<void>
  onLoadMore: () => Promise<void>
}

type ActivityProps = ActivityStateProps & ActivityDispatchProps
let keyId = 0
const OldActivityStatement: FC<ActivityProps> = ({ onMount, statement, onLoadMore, showLoadMore, hasLoaded, total, pendingPoints }) => {
  useEffect(() => {
    onMount().then()
  }, [onMount])

  if (!hasLoaded && total === 0) {
    return <LoadSpinner verticalMargin={130} />
  }
  return (
    <>
      <style jsx>{`
        .activity-date-wrapper {
          margin-bottom: 24px;
          margin-top: 24px;
        }
        .transaction-date {
          color: ${color.black};
          font-family: ${FontFamilies.barlow};
          font-size: 16px;
          line-height: 1.25;
          margin-bottom: 24px;
          text-align: center;
        }
        .load-more-button {
          margin: 0px auto;
          width: 140px;
        }
        .no-activity-container {
          margin: 0px auto;
        }
        .no-activity-button {
          height: 48px;
          margin: 0px auto;
          width: 200px;
        }
        .no-activity-text {
          font-size: 28px;
          line-height: 1.43;
          text-align: center;
        }
        .title {
          font-style: italic;
          font-weight: 600;
          margin: 40px 0;
          text-transform: uppercase;
        }
        .section-header {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin-top: 40px;

          text-align: center;
        }
        .credit-time-subheading {
          font-style: italic;
          text-align: center;
        }
        .page-order__divider {
          margin-top: 40px;
        }
        @media ${media.tabletAndHigher} {
          .credit-time-subheading {
            text-align: right;
            margin-top: -24px;
          }
        }
      `}</style>
      <Content>
        {pendingPoints.dates.length > 0 && (
          <>
            <div className="text-wrapper">
              <div className="section-header" data-testid="pending-points-header">
                {getString('activity.pendingPointsHeader')}
              </div>
              <div className="credit-time-subheading">{getString('activity.pendingPointsCreditTime')}</div>
            </div>
            {pendingPoints.dates.map((date) => (
              <>
                <div key={date} className="activity-date-wrapper">
                  <div className="transaction-date" data-testid="pending-date">
                    {date}
                  </div>
                  {pendingPoints.idsByDate[date].map((id) => (
                    <OldActivityStatementItem
                      key={`activityItem${keyId++}`}
                      name={pendingPoints.transactions[id].description}
                      points={pendingPoints.transactions[id].points}
                    />
                  ))}
                </div>
              </>
            ))}
            <div className="page-order__divider">
              <DashedDivider />
            </div>
          </>
        )}

        {total > 0 && statement.dates.length > 0 && (
          <>
            <div className="section-header" data-testid="activity-header">
              {getString('activity.title')}
            </div>
            {statement.dates.map((date) => (
              <>
                <div key={date} className="activity-date-wrapper">
                  <div className="transaction-date" data-testid="transaction-date">
                    {date}
                  </div>
                  {statement.idsByDate[date].map((id) => (
                    <OldActivityStatementItem
                      key={`activityItem${keyId++}`}
                      name={statement.transactions[id].description}
                      points={statement.transactions[id].pointsInt}
                      showPlus={statement.transactions[id].pointsInt > 0}
                    />
                  ))}
                </div>
              </>
            ))}
          </>
        )}
        {showLoadMore && (
          <div className="load-more-button">
            <Button dataAttributes={{ testid: 'btn-load-more' }} onClick={onLoadMore} text="Load more" isPrimary />
          </div>
        )}
        {(total === 0 || statement.dates.length === 0) && (
          <div className="no-activity-container">
            <Spacer size="medium" />
            <div className="no-activity-text title">
              <TextId id="activity.noActivityTitle" />
            </div>
            <div className="no-activity-text">
              <TextId id="activity.noActivityText" />
            </div>
            <Spacer size="large" />
            <div className="no-activity-button">
              <LinkButton
                style={ButtonStyle.Primary}
                href={localizePath(PATHS.EARN)}
                text={getString('activity.browseRewardsButton')}
                display="block"
              />
            </div>
          </div>
        )}
      </Content>
    </>
  )
}

export { OldActivityStatement }
