import React, { useEffect } from 'react'
import { media } from '@virgin-core/styles'
import { doSpecificPageFetch } from 'data/src/redux/pages/dispatchers'
import { getCurrentPage } from 'data/src/redux/pages/selectors'
import { getSectionBackgroundColor } from 'data/src/utils/contentfulPages'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { NewHeroBlock, DownloadAppBlock, ImagesTextBlock, BrandTiles, SpendCategories, FaqBlock } from '../../components/ContentfulBlock'
import { PageLoader } from '../../components'

export const HomePublic = () => {
  const currentPage = useAppSelector(getCurrentPage)
  const dispatch = useAppDispatch()
  const viewingRegion = localeToRegion(getLocale())
  const content = currentPage?.content?.fields
  const { topHeroSection, spendSection, earnSection, howItWorksSection, faqSection, appDownloadSection } = content || {}
  const earnTiles = earnSection?.fields?.brandTiles ?? []
  const spendCategories = spendSection?.fields?.spendCategories ?? []

  useEffect(() => {
    dispatch(
      doSpecificPageFetch({
        slugName: 'new-home-page-logged-out',
        ...(viewingRegion && { viewingRegion: `EN_${viewingRegion}` }),
      })
    )
  }, [viewingRegion, dispatch])

  return content ? (
    <>
      <style jsx>{`
        .top-hero-section {
          background-color: ${getSectionBackgroundColor(topHeroSection)};
        }
        .spend-section {
          background-color: ${getSectionBackgroundColor(spendSection)};
        }
        .earn-section {
          background-color: ${getSectionBackgroundColor(earnSection)};
        }
        .how-it-works-section {
          background-color: ${getSectionBackgroundColor(howItWorksSection)};
        }
        .faq-section {
          background-color: ${getSectionBackgroundColor(faqSection)};
        }
        .download-hero-section {
          background-color: ${getSectionBackgroundColor(appDownloadSection)};
        }
        .earn-section,
        .spend-section,
        .how-it-works-section,
        .faq-section {
          padding: 40px 0;
        }

        @media ${media.tabletAndHigher} {
          .earn-section {
            padding: 40px 0 28px;
          }
          .spend-section {
            padding: 40px 0 28px;
          }
        }

        @media ${media.tabletLargeAndHigher} {
          .top-hero-section :global(.text-panel) {
            padding-top: 40px;
          }
        }

        @media ${media.hiResDesktop} {
          .top-hero-section :global(.text-panel) {
            padding-top: 100px;
          }
          .earn-section {
            padding: 96px 0 85px;
          }
          .spend-section {
            padding: 96px 0 72px;
          }

          .how-it-works-section,
          .faq-section {
            padding: 96px 0;
          }
        }
      `}</style>
      {topHeroSection && (
        <div data-testid="top-hero-section" className="top-hero-section">
          <NewHeroBlock
            sectionName="top_hero_section"
            fields={topHeroSection.fields.topHero?.fields}
            textColour={topHeroSection.fields.textColour?.fields.textColour}
            topHero
          />
        </div>
      )}
      {earnSection && (
        <div data-testid="earn-hero-section" className="earn-section">
          <NewHeroBlock
            sectionName="earn_section"
            fields={earnSection.fields.earnHero?.fields}
            textColour={earnSection.fields.textColour?.fields.textColour}
          >
            {earnTiles.length > 0 && <BrandTiles tiles={earnTiles} />}
          </NewHeroBlock>
        </div>
      )}
      {spendSection && (
        <div data-testid="spend-hero-section" className="spend-section">
          <NewHeroBlock
            sectionName="spend_section"
            fields={spendSection.fields.spendHero?.fields}
            textColour={spendSection.fields.textColour?.fields.textColour}
          >
            {spendCategories.length > 0 && <SpendCategories sectionName="spend_section" categories={spendCategories} />}
          </NewHeroBlock>
        </div>
      )}
      {howItWorksSection && (
        <div data-testid="how-it-works-section" className="how-it-works-section">
          <ImagesTextBlock sectionName="how_it_works_section" fields={howItWorksSection.fields} />
        </div>
      )}
      {faqSection && (
        <div data-testid="faq-section" className="faq-section">
          <FaqBlock sectionName="faq_section" fields={faqSection.fields} />
        </div>
      )}
      {appDownloadSection && (
        <div data-testid="download-hero-section" className="download-hero-section">
          <DownloadAppBlock
            sectionName="download_section"
            fields={appDownloadSection.fields.appDownloadHero?.fields}
            textColour={appDownloadSection.fields.textColour?.fields.textColour}
          />
        </div>
      )}
    </>
  ) : (
    <PageLoader />
  )
}
