import { Reward } from 'data/src/redux/rewards/types'
import { RewardEarn } from 'data/src/redux/rewardsEarn/types'
import { getLocale } from '../../dataImplementation/viewingRegionWeb'
import { Locale } from 'data/src/utils'

export interface regionalLinks {
  rel: string
  hrefLang?: string
  href: string
}

const isSpendEarnInfo = (path: string) => ({
  spendEarnType: path.includes('/spend-virgin-points/') ? 'spend' : path.includes('/earn-virgin-points/') ? 'earn' : null,
  isSpendEarnType: path.includes('/spend-virgin-points/') || path.includes('/earn-virgin-points/'),
})

export const seoLinks = (selectedPublicSpendReward: Reward | null, selectedPublicEarnReward: RewardEarn | null): regionalLinks[] => {
  const { href } = window.location
  const pathAfterVirginRed = href.split('virgin-red')[1] // '' | /locale
  const savedLocale = getLocale()

  const { spendEarnType, isSpendEarnType } = isSpendEarnInfo(href)
  const campaignPath = spendEarnType === 'spend' ? '/virgin-red/spend-virgin-points' : '/virgin-red/earn-virgin-points'

  const activeRewardData = spendEarnType === 'earn' ? selectedPublicEarnReward : selectedPublicSpendReward
  const rewardIsGlobal = activeRewardData?.content.tags.some((tag: any) => tag.includes('region.global'))
  const regionTagsCount = activeRewardData?.content.tags.filter((tag: any) => tag.includes('region.')).length
  const rewardHasSpecificRegionTag = (region: string) => activeRewardData?.content.tags.some((item) => item.includes(`region.${region}`))

  // generate alternate links
  const altLinks = Object.values(Locale).reduce((acc, locale) => {
    const isLocaleAsRegionTag = !!rewardHasSpecificRegionTag(locale.split('-')[1].toUpperCase())
    const buildGBDefaultHref = () => {
      if (isSpendEarnType) {
        // if reward
        if (regionTagsCount === 0) {
          // reward has no region tags
          return savedLocale === 'en-gb' ? href : `https://virgin.com${pathAfterVirginRed ? campaignPath : '/virgin-red'}`
        } else {
          // reward has region tags
          if (!rewardHasSpecificRegionTag('GB') && !rewardIsGlobal) {
            // reward has a GB tag
            return `https://virgin.com${pathAfterVirginRed ? campaignPath : '/virgin-red'}`
          } else {
            // reward does not have a GB tag
            return `https://virgin.com/virgin-red${pathAfterVirginRed ?? ''}`
          }
        }
      } else {
        // not reward
        return savedLocale === 'en-gb' ? href : `https://virgin.com/virgin-red${pathAfterVirginRed ?? ''}`
      }
    }

    if (locale === 'en-gb') {
      acc.push({
        rel: 'alternate',
        hrefLang: 'x-default',
        href: buildGBDefaultHref(),
      })
    } else {
      acc.push({
        rel: 'alternate',
        hrefLang: locale,
        href:
          !isLocaleAsRegionTag && !rewardIsGlobal && isSpendEarnType
            ? locale === savedLocale
              ? href
              : `https://virgin.com/${locale}${pathAfterVirginRed ? campaignPath : '/virgin-red'}`
            : `https://virgin.com/${locale}/virgin-red${pathAfterVirginRed ?? ''}`,
      })
    }

    return acc
  }, [] as regionalLinks[])

  // generate canonical link
  const rewardContainsSlug = activeRewardData?.content.slug
  const stringAfterFinalSlash = href.split('/')[href.split('/').length - 1]
  const rewardIdAtEndOfSlug = stringAfterFinalSlash.split('-')[stringAfterFinalSlash.split('-').length - 1]
  const rewardHrefContainsRewardIdOnly = /^\+?\d+$/.test(stringAfterFinalSlash)

  const nonSlugRewardIdURL = `https://virgin.com${
    savedLocale === 'en-gb' ? '/' : `/${savedLocale}/`
  }virgin-red/${spendEarnType}-virgin-points/${stringAfterFinalSlash}`
  const slugRewardIdURL = `https://virgin.com${
    savedLocale === 'en-gb' ? '/' : `/${savedLocale}/`
  }virgin-red/${spendEarnType}-virgin-points/${rewardContainsSlug}-${rewardIdAtEndOfSlug}`

  const canonicalLink = {
    rel: 'canonical',
    href: isSpendEarnType ? (!rewardHrefContainsRewardIdOnly ? nonSlugRewardIdURL : rewardContainsSlug ? slugRewardIdURL : href) : href,
  }

  return [...altLinks, canonicalLink]
}
