import React, { useState, MouseEvent, useRef } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { color } from 'web/src/style/variables'
import { optimizeContentfulImageUrl } from 'web/src/helpers/images'
import { CustomLink } from '../links'
import { Picture } from '../Picture'
import { media } from 'web/src/style/media'
import { virginRedPath } from 'web/src/router/paths'
import { apiGetAllBrands } from 'data/src/api/brands.api'
import IconMapper, { Icon } from '../icons/IconMapper'
import { useMediaPredicate } from 'react-media-hook'
import ReactDOM from 'react-dom'
import { getTransitionProperty } from 'react-alice-carousel/lib/utils'
import { localeToRegion, getLocale } from 'web/src/dataImplementation/viewingRegionWeb'
import { useQuery } from '@tanstack/react-query'

export const BrandsCarousel = () => {
  const [index, setIndex] = useState(0)
  const viewingRegion = localeToRegion(getLocale())
  const carousel = useRef(null)

  const { data: { brands = [] } = {} } = useQuery({
    queryKey: ['brands', viewingRegion],
    queryFn: async () => await apiGetAllBrands(viewingRegion),
  })

  let isSlideChanging = false
  const isLargeDesktop = useMediaPredicate('(min-width: 1300px)')
  const maxVisibleItems = isLargeDesktop ? 4 : 3

  const setTransition = (animationDuration: number = 0) => {
    const carouselList = carousel.current
      ? (ReactDOM.findDOMNode(carousel.current) as Element).getElementsByClassName('alice-carousel__stage')[0]
      : null
    if (carouselList) {
      ;(carouselList as HTMLElement).style.transition = getTransitionProperty({
        animationDuration,
      })
    }
  }

  const responsiveImages = (brandLogo: string) => ({
    mobile: {
      imgSrc: optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 }),
      hiResImgSrc: optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 }),
    },
  })

  const handleDragStart = (e: MouseEvent) => e.preventDefault()

  const handleOnClick = (e: MouseEvent) => (preventDefault: boolean) => {
    if (preventDefault) {
      e.preventDefault()
    }
  }

  if (brands.length === 0) return null

  return (
    <>
      <style jsx>{`
        .logo-container {
          padding: 16px 0;
          background: linear-gradient(to bottom, ${color.darkPinkBackground} 0%, ${color.darkPinkBackground} 50%, white 50%, white 0);
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }
        .logo {
          box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
          background: white;
          width: 144px;
          height: 144px;
          flex-shrink: 0;
          margin: 0 auto;
        }
        h3 {
          color: ${color.textHeader};
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 24px;
          font-weight: 600;
        }
        .card {
          width: 242px;
          text-align: center;
          cursor: pointer;
          text-decoration: none;
          margin-top: 15px;
        }
        .card :global(a) {
          margin-right: 16px;
          width: 224px;
          display: inline-block;
          border: 1px solid ${color.lightGrey};
          border-radius: 8px;
          user-select: none;
        }
        .brand-carousel-wrapper {
          position: relative;
        }
        .brand-carousel-wrapper :global(.alice-carousel__wrapper) {
          padding: 0 15px !important;
        }

        .brand-carousel-wrapper :global(.alice-carousel__prev-btn),
        .brand-carousel-wrapper :global(.alice-carousel__next-btn) {
          display: none;
          cursor: pointer;
          height: 50px;
          left: 25px;
          padding: 0;
          position: absolute;
          top: 50%;
          width: 50px;
          z-index: 1;
        }
        .brand-carousel-wrapper :global(.alice-carousel__prev-btn .alice-carousel__icon-btn),
        .brand-carousel-wrapper :global(.alice-carousel__next-btn .alice-carousel__icon-btn) {
          align-items: center;
          background: ${color.brandPrimary};
          border: none;
          border-radius: 100%;
          cursor: pointer;
          display: flex;
          height: 50px;
          justify-content: center;
          padding: 0;
          width: 50px;
          box-shadow: 2px 2px 10px ${color.greyTransparent2};
        }
        .brand-carousel-wrapper :global(.alice-carousel__next-btn) {
          left: unset;
          right: 25px;
        }

        @media ${media.tabletAndHigher} {
          .brand-carousel-wrapper :global(.alice-carousel__wrapper) {
            padding: 0 44px !important;
          }
          .card {
            width: 237px;
          }
          .card :global(a) {
            margin-right: 24px;
            width: 211px;
          }
        }

        @media ${media.desktopAndHigher} {
          .brand-carousel-wrapper :global(.alice-carousel__wrapper) {
            padding: 0 60px !important;
          }
          h3 {
            font-size: 24px;
          }
          .card {
            width: 334px;
          }
          .card :global(a) {
            width: 318px;
          }
          .brand-carousel-wrapper :global(.alice-carousel__wrapper) {
            padding-left: 60px !important;
          }
          .brand-carousel-wrapper :global(.alice-carousel__prev-btn),
          .brand-carousel-wrapper :global(.alice-carousel__next-btn) {
            display: block;
          }
        }
        @media ${media.hiResDesktop} {
          .brand-carousel-wrapper {
            max-width: 1440px;
            margin: 0px auto;
          }
          .brand-carousel-wrapper :global(.alice-carousel__wrapper) {
            padding: 0 60px !important;
          }
        }
      `}</style>
      <div className="brand-carousel-wrapper" ref={carousel}>
        <AliceCarousel
          autoWidth
          activeIndex={index}
          disableDotsControls
          disableButtonsControls
          mouseTracking
          onSlideChange={() => (isSlideChanging = true)}
          onSlideChanged={(e) => {
            isSlideChanging = false
            setIndex(e.item)
          }}
          items={brands.map(({ brandLogo, brandName, slug }, index) => (
            <div key={index} className="card" onDragStart={handleDragStart}>
              <CustomLink to={`${virginRedPath}/brands/${slug}`} onClick={(e) => handleOnClick(e)(isSlideChanging)}>
                <div className="logo-container">
                  <div className="logo">
                    <Picture
                      altText={`${brandName} logo`}
                      className="responsive-img--override"
                      width={160}
                      fallbackImg={optimizeContentfulImageUrl(brandLogo, 'fill', { width: 144, height: 144 })}
                      height={160}
                      responsiveImages={responsiveImages(brandLogo)}
                      loading={'lazy'}
                      isWebP={true}
                    />
                  </div>
                </div>
                <h3>{brandName}</h3>
              </CustomLink>
            </div>
          ))}
        />
        {index > 0 && (
          <div className="alice-carousel__prev-btn">
            <button
              type="button"
              aria-label="Previous"
              className="alice-carousel__icon-btn"
              onClick={() => {
                setIndex(index - maxVisibleItems)
                setTransition(400)
              }}
            >
              <IconMapper icon={Icon.ArrowLeft} color={color.white} size={20} />
            </button>
          </div>
        )}
        {index < brands.length - maxVisibleItems && (
          <div className="alice-carousel__next-btn">
            <button
              type="button"
              aria-label="Next"
              className="alice-carousel__icon-btn"
              onClick={() => {
                setIndex(index + maxVisibleItems)
                setTransition(400)
              }}
            >
              <IconMapper icon={Icon.ArrowRight} color={color.white} size={20} />
            </button>
          </div>
        )}
      </div>
    </>
  )
}
