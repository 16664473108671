import { useState, useEffect, useCallback } from 'react'

export function useQuery<TData>(queryFn: () => Promise<TData>) {
  const memoCallback = useCallback(() => queryFn(), [queryFn])
  const { error, loading, mutate, value } = useMutation({ mutateFn: memoCallback })

  useEffect(() => {
    mutate()
  }, [mutate])

  return { loading, error, value }
}

export function useMutation<TData>({ mutateFn }: { mutateFn: () => Promise<TData> }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [value, setValue] = useState<TData | null>(null)

  const reset = useCallback(() => {
    setLoading(false)
    setError(null)
    setValue(null)
  }, [])

  const callbackMemoized = useCallback(async () => {
    setLoading(true)
    setError(null)
    setValue(null)

    try {
      const data = await mutateFn()
      setValue(data)
    } catch (error) {
      const reportedError = error instanceof Error ? error : new Error('An error occurred')
      setError(reportedError)
    } finally {
      setLoading(false)
    }
  }, [mutateFn])

  return { loading, error, value, reset, mutate: callbackMemoized }
}
