import { TilesType } from '../..'
import formatEarnTiles from '../formatEarnTiles'
import { EarnType } from 'data/src/redux/rewardsEarn/types'

export interface RewardEarnBrandDetails {
  brandName: string
  brandLogo: string
}

export interface RewardEarnContent {
  imageUrl: string
  description: string
  title: string
  lead?: string
  brandDetails?: RewardEarnBrandDetails
  body?: Document
  redemption?: {
    method: Document
  }
  termsAndConditions?: Document
  termsUrl: string | null
  partnerDetails?: {
    partnerName: string
    partnerLogo: string
    descriptor: string
  }
  slug?: string
}

export interface RewardEarn {
  rewardId: string
  campaignId: string
  earnUrl: string
  earnType: EarnType
  name: string
  cost: number
  content: RewardEarnContent
}

export type CmsEarnIds = Array<string>
export type CmsEarnLookup = {
  [rewardId: string]: RewardEarn
}

export interface CmsEarnProps {
  rewardIds: CmsEarnIds
  rewards: CmsEarnLookup
}

const convertCmsEarnsToTiles = (
  earnIds: Array<string>,
  rewardsEarn: {
    [rewardId: string]: RewardEarn
  }
): TilesType => earnIds.map((id) => formatEarnTiles(rewardsEarn[id]))

export { convertCmsEarnsToTiles }
