import React, { FC, useState } from 'react'
import cn from 'classnames'

import { getString } from 'data'
import { RewardsSort as RewardsSortType } from 'data/src/redux/discovery/types'

import { color } from '../../style/variables'
import { media } from '../../style/media'
import { SortIcon } from '../icons'

export interface RewardsSortProps {
  selectedSort?: string
  doUpdateRewards: () => void
  setSort: (sort?: RewardsSortType) => void
}

export const RewardsSort: FC<RewardsSortProps> = ({ selectedSort, setSort, doUpdateRewards }) => {
  const sortOptions = [
    { key: undefined, value: getString('sort.option.featured') },
    { key: RewardsSortType.CostAsc, value: getString('sort.option.lowToHigh') },
    { key: RewardsSortType.CostDesc, value: getString('sort.option.highToLow') },
  ]

  const [isVisible, setVisible] = useState(false)

  const handleKeyPressSelect = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()
      handleClickSelect()
    }
  }

  const handleClickSelect = () => {
    setVisible(!isVisible)
  }

  const handleKeyPressCategoryItem = (event: React.KeyboardEvent, sort?: RewardsSortType) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()
      handleClickSortBy(sort)
      handleClickSelect()
    }
  }

  const handleClickSortBy = (sort?: RewardsSortType) => {
    setSort(sort)
    doUpdateRewards()
    handleClickSelect()
  }

  return (
    <>
      <style jsx>{`
        .sort {
          display: inline-block;
          position: relative;
        }
        .sort__button {
          align-items: center;
          border: solid 1px ${color.grey02};
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          height: 38px;
          font-weight: bold;
          padding: 6px 15px;
        }
        :global(.sort__button-icon) {
          color: ${color.brandPrimary};
          margin-left: 10px;
        }
        .sort__button--selected,
        .sort__button:focus,
        .sort__button:hover {
          border-color: ${color.brandPrimary};
          color: ${color.brandPrimary};
          outline: none;
        }
        .sort__button--selected :global(.sort__button-icon),
        .sort__button:focus :global(.sort__button-icon),
        .sort__button:hover :global(.sort__button-icon) {
          fill: ${color.brandPrimary};
        }
        .sort__mask {
          cursor: default;
          height: 100%;
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2;
        }
        .sort__dropdown {
          background-color: ${color.white};
          border: solid 1px ${color.secondaryButtonBorder};
          border-radius: 8px 8px 0 0;
          box-shadow: 0 0 40px 0 rgba(${color.blackRGB}, 0.3);
          left: 0;
          padding: 0;
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 3;
        }
        .sort__dropdown-title {
          border-bottom: solid 1px ${color.grey03};
          font-size: 22px;
          font-style: italic;
          font-weight: 600;
          height: 60px;
          padding: 15px 25px;
          text-transform: uppercase;
        }
        .sort__dropdown-content {
          max-height: 70vh;
          overflow-y: auto;
        }
        .sort__section-item {
          font-size: 17px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          height: 60px;
          padding: 20px 55px 20px 30px;
          position: relative;
        }
        .sort__section-item:after {
          content: '';
          border: 2px solid ${color.lightGrey};
          border-radius: 50%;
          height: 22px;
          position: absolute;
          right: 25px;
          top: 18px;
          width: 22px;
        }
        .sort__section-item:before {
          background: transparent;
          border-radius: 50%;
          content: '';
          height: 14px;
          position: absolute;
          right: 31px;
          top: 24px;
          width: 14px;
        }
        .sort__section-item:hover,
        .sort__section-item:focus {
          background-color: ${color.darkPinkBackground};
          cursor: pointer;
          outline: none;
        }
        .sort__section-item--selected {
          background-color: ${color.darkPinkBackground};
          font-weight: 600;
        }
        .sort__section-item--selected:before {
          background: ${color.brandPrimary};
        }
        .sort__section-item--selected:after {
          border-color: ${color.brandPrimary};
        }

        @media ${media.tabletAndHigher} {
          :global(.sort--open) {
            overflow-y: auto !important;
          }
          .sort__dropdown {
            border-radius: 8px;
            bottom: auto;
            position: absolute;
            top: 38px;
            width: 300px;
            z-index: 3;
          }
          .sort__dropdown-content {
            max-height: auto;
            overflow-y: visible;
          }
        }

        @media ${media.mobileAndLower} {
          :global(body) {
            overflow-y: ${isVisible ? 'hidden !important;' : ''};
          }
        }
      `}</style>
      <div className="sort" data-testid="sort">
        <div
          role="button"
          className={cn('sort__button', {
            'sort__button--selected': isVisible,
          })}
          onKeyPress={handleKeyPressSelect}
          onClick={handleClickSelect}
          tabIndex={0}
        >
          {getString('sort.title')} <SortIcon size={18} className="sort__button-icon" hasCircle={!!selectedSort} />
        </div>
        {isVisible && (
          <>
            <div className="sort__dropdown" role="listbox">
              <div className="sort__dropdown-title">{getString('sort.title')}</div>
              {sortOptions && (
                <div className="sort__dropdown-content">
                  {sortOptions.map(({ key, value }, index) => (
                    <div
                      role="listitem"
                      key={index}
                      className={cn('sort__section-item', {
                        'sort__section-item--selected': key === selectedSort,
                      })}
                      data-category-name={key}
                      tabIndex={0}
                      onClick={() => handleClickSortBy(key)}
                      onKeyPress={(event) => handleKeyPressCategoryItem(event, key)}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div role="button" className="sort__mask" onKeyPress={handleKeyPressSelect} onClick={handleClickSelect} tabIndex={0} />
          </>
        )}
      </div>
    </>
  )
}
