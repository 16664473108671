import React from 'react'
import { View, Text, Button, ButtonText } from '@red-ui/components/'
import { Feature } from '../Feature'
import { Card, Chip, featuresListStyle } from './styles'
import { getString } from 'data'
import { SubscriptionPlan } from 'data/src/api/subscriptions.api'
import { CustomLink } from '../../../components'
import { virginRedPath } from '../../../router/paths'

const PLANS = {
  Premium: 'Premium',
  RedPlus: 'Red+',
  VirginRed: 'Virgin Red',
}

export const Plan = ({ planId, name, cost, features }: SubscriptionPlan) => {
  let includedPlans: string = PLANS.VirginRed

  if (name === PLANS.Premium) {
    includedPlans += ` and ${PLANS.RedPlus}`
  }

  return (
    <Card>
      <View>
        <Chip>
          <Text fontWeight="600">{name}</Text>
        </Chip>
        <Text fontSize="$10" fontWeight="600" marginTop="$3">
          {cost}
        </Text>
        <View marginTop="$2" marginBottom="$4">
          <Text fontSize="$6" fontWeight="bold">
            {getString('subscribe.page.content.plan.title').replace('{{plan}}', name)}
          </Text>
          <Text fontSize="$4" marginTop="$1">
            {getString('subscribe.page.content.plan.subtitle').replace('{{includedPlans}}', includedPlans)}
          </Text>
          <ul style={featuresListStyle}>
            {features.map((feature) => (
              <Feature key={feature.description} {...feature} />
            ))}
          </ul>
        </View>
      </View>
      <View>
        <CustomLink to={`${virginRedPath}/subscription-payment/${planId}`}>
          <Button width="100%" height="$6">
            <ButtonText fontSize="$3" fontWeight="bold" lineHeight="$2">
              {getString('subscribe.page.content.plan.button').replace('{{plan}}', name)}
            </ButtonText>
          </Button>
        </CustomLink>
        <Text alignSelf="center" marginTop="$3">
          <Text fontSize="$2">{getString('subscribe.page.content.plan.trialDuration')}</Text>
          <Text fontWeight="600">{getString('subscribe.page.content.plan.cost').replace('{{cost}}', cost)}</Text>
        </Text>
      </View>
    </Card>
  )
}
