import React from 'react'

import { getString, transformInterpolatedString } from 'data'

import H3Header from '../typography/Red/H3Header'
import Cross from '../icons/Cross'
import { color, ZIndex } from '../../style/variables'
import { media } from '../../style/media'
import { Trans } from 'react-i18next'

export interface PointsSupportProps {
  closeModal: () => void
}

const PointsSupport: React.FC<PointsSupportProps> = ({ closeModal }) => {
  const questions = Array.from({ length: 7 }, (_, i: number) => `${i + 1}. ${getString(`activity.pointsSupport.section${i + 1}.header`)}`)
  const answers = Array.from({ length: 7 }, (_, i: number) => getString(`activity.pointsSupport.section${i + 1}.body`))

  const content = []
  let i: number

  for (i = 0; i < 7; i++) {
    content.push(questions[i], answers[i])
  }

  const pageHeight = () => {
    return Math.max(document.body.offsetHeight, document.body.scrollHeight) + 132
  }

  return (
    <>
      <style jsx>{`
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .modal-container {
          position: absolute;
          top: -132px;
          left: 0;
          width: 100%;
          height: ${pageHeight()}px;
          background: rgba(26, 26, 26, 0.6);
          z-index: ${ZIndex.Banner};
        }
        .card {
          z-index: ${ZIndex.Modal};
          top: 10px;
          position: absolute;
          max-height: 705px;
          border-radius: 8px;
          padding: 9px 16px 24px 24px;
          margin: 15px;
          animation: 0.5s cubic-bezier(0, 0, 0.25, 1) 0.4s in both;
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
          border: solid 1px ${color.secondaryButtonBorder};
          background: ${color.white};
        }
        .close-icon-wrapper {
          display: flex;
          margin-top: 5px;
          width: 100%;
          justify-content: flex-end;
        }
        .header :global(.header-three) {
          margin-bottom: 24px;
        }
        .content {
          font-size: 17px;
          margin-bottom: 24px;
        }
        .question-answer-section {
          padding-right: 20px;
          max-height: 600px;
          overflow-y: scroll;
        }
        .question-answer-section > :nth-child(odd) {
          font-weight: 600;
        }
        .support-link {
          color: ${color.brandPrimary};
          text-decoration: none;
          font-weight: 600;
        }
        @media ${media.tabletAndHigher} {
          .card {
            top: 110px;
            padding: 19px 19px 48px 44px;
            max-width: 646px;
          }
          .question-answer-section {
            max-height: 427px;
          }
        }
        @media ${media.desktopAndHigher} {
          .card {
            top: 180px;
            max-width: 648px;
            max-height: 607px;
            padding: 19px 19px 48px 44px;
          }
          .question-answer-section {
            padding-right: 50px;
            max-height: 458px;
          }
        }
      `}</style>
      <div className="modal-container" onClick={closeModal}></div>
      <div className="card">
        <div className="close-icon-wrapper">
          <Cross aria-label="Close button" onClick={closeModal} className="close" size={15} color={color.blackUnderline} />
        </div>
        <div className="header">
          <H3Header
            textTransform="none"
            color={color.textHeader}
            marginTop={{ mobile: 0 }}
            marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
          >
            {getString('activity.pointsSupport.title').toUpperCase()}
          </H3Header>
        </div>
        <div className="question-answer-section">
          {content.map((section, index) => (
            <div key={index} className="content">
              <Trans
                defaults={transformInterpolatedString(section)}
                components={{
                  supportLink: (
                    <a className="support-link" href={transformInterpolatedString(getString('activity.pointsSupport.memberSupportLink'))}>
                      {' '}
                    </a>
                  ),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export { PointsSupport }
