import React, { useState, ChangeEvent, FocusEvent, FormEvent } from 'react'

import { getString } from 'data'
import { getStringOrNull } from 'data/src/utils/getString'
import { TextField } from '@virgin-core/components'

import H3Header from '../../../components/typography/H3Header'
import { VerifyEmailProps, VerifyEmailForm } from './types'
import { spacing } from '../../../style/variables'
import { validateVerificationCode } from '../../../../../data/src/redux/profile/validations'
import { parseJoiErrors } from '../../../../../data/src/utils/validations'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'

const VerifyEmail: React.FC<VerifyEmailProps> = ({ doVerifyEmail, isUpdating, newEmail }) => {
  const screenId = 'profile.verifyEmail'
  const [formState, setFormState] = useState<Partial<VerifyEmailForm>>({})
  const [errors, setErrors] = useState<Partial<VerifyEmailForm>>({})

  const onChange = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target
    setErrors((prevState) => ({
      ...prevState,
      [id]: null,
    }))
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const onSubmit = () => {
    const formValidation = validateVerificationCode(formState)
    if (formValidation.error) {
      const newErrors = parseJoiErrors(formValidation.error.details)
      return setErrors((prevState) => ({
        ...prevState,
        ...newErrors,
      }))
    }
    formState.email = newEmail
    return doVerifyEmail(formState)
  }

  return (
    <Page title="Verify email | Virgin Red" testId="page-account-verify-email">
      <MyAccountBackground>
        <H3Header textAlign="left" weight={600} marginTop={spacing.zero}>
          {getString('profile.verifyEmail.title')}
        </H3Header>
        <div className="text-field-wrap">
          <p>{`${getString('profile.verifyEmail.description')} ${newEmail}`}</p>
        </div>
        <form
          onSubmit={(event: FormEvent) => {
            event.preventDefault()
          }}
        >
          <div className="text-field-wrap">
            <TextField
              id="code"
              fullWidth
              label={getString('profile.labels.verifyCode')}
              error={Boolean(errors.code)}
              helperText={Boolean(errors.code) && getStringOrNull(`${screenId}.formError`)}
              onChange={onChange}
              required
            />
          </div>
          <Spacer size={'medium'} />
          <Button
            isPrimary
            buttonType="submit"
            isFullWidth={false}
            display={'inline-block'}
            text={getString('profile.verifyEmail.submit')}
            loading={isUpdating}
            onClick={onSubmit}
          ></Button>
        </form>
      </MyAccountBackground>
    </Page>
  )
}

export { VerifyEmail }
