import { Spinner } from '@red-ui/components'
import type { Entry } from 'contentful'
import { apiGetRewardGroups, apiGetRewardsByGroupId, RewardGroup } from 'data/src/api/rewardGroups.api'
import { getFeatureIsLoading, selectFeatureById } from 'data/src/redux/features/features.selectors'
import { FeatureName } from 'data/src/redux/features/features.types'
import { convertRewardToCard } from 'data/src/utils/convertRewardToCard'
import React, { useCallback } from 'react'
import { useQuery } from '../../helpers/asyncHooks'
import { useAppSelector } from '../../redux/hooks/hooks'
import { IntroCarouselBlockPrivate } from '../ContentfulBlock/IntroCarouselBlock'
import {
  IntroCarouselBlock,
  IntroCarouselType,
  type IntroCarouselBlockProps,
} from '../ContentfulBlock/IntroCarouselBlock/IntroCarouselBlock'
import { CardTypes } from '../ContentfulBlock/types'
import { DynamicLayout } from './DynamicLayout'

export type DynamicCarouselFields = {
  fields: Pick<IntroCarouselBlockProps, 'title'> & { sliderContentType: CardTypes; header?: Entry<IntroCarouselBlockProps['header']> }
}

const RewardGroupContainer = ({ header, title }: React.ComponentProps<typeof IntroCarouselBlockPrivate>) => {
  const useApiData = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_CREDIT_CARD_BENEFITS_USE_API))
  const isFeatureLoading = useAppSelector(getFeatureIsLoading)

  const { value: groups, loading } = useQuery(
    useCallback(async () => {
      if (isFeatureLoading) return []

      const groups = await apiGetRewardGroups({ useMock: !useApiData })

      const groupsWithRewards: (RewardGroup & { rewards: ReturnType<typeof convertRewardToCard> })[] = await Promise.all(
        groups.map(async (group) => {
          const rewards = await apiGetRewardsByGroupId({ rewardGroupId: group.id, useMock: !useApiData })
          return { ...group, rewards: convertRewardToCard(rewards) }
        })
      )

      return groupsWithRewards
    }, [isFeatureLoading, useApiData])
  )

  const isLoading = loading || isFeatureLoading

  return (
    <>
      {isLoading ? (
        <Spinner size="large" color="$red6" />
      ) : (
        groups?.map((group) => (
          <IntroCarouselBlock
            key={group.id}
            items={() => group.rewards}
            doGetItems={() => ({})}
            isLoading={false}
            type={IntroCarouselType.REWARD}
            title={title}
            header={header}
            disableWishlist
          />
        ))
      )}
    </>
  )
}

export const DynamicCarousel = ({ fields }: DynamicCarouselFields) => {
  let Wrapper = IntroCarouselBlockPrivate
  if (fields.sliderContentType === CardTypes.ELIGIBLE_BENEFITS) Wrapper = RewardGroupContainer

  return (
    <DynamicLayout isSection fields={{}}>
      <Wrapper type={fields.sliderContentType} title={fields.title} header={fields?.header?.fields} />
    </DynamicLayout>
  )
}
