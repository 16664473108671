import React from 'react'
import { Page } from '../../components'
import { YStack, H2, H3, ListItem, YGroup, Text } from '@red-ui/components/'
import { getString } from 'data'

export const SubscriptionConfirmation = () => (
  <Page title={getString('subscriptionConfirmation.page.title')}>
    <YStack paddingLeft="$7" paddingTop="$4" height="100vh" $mobile={{ paddingLeft: '$12' }}>
      <H2>You have successfully paid for a subscription!</H2>
      <H3>You received:</H3>
      <YGroup paddingTop="$4">
        {['1000 points per month', '1 free Vue Cinema ticket per month', 'costs £5 per month'].map((item) => (
          <YGroup.Item key={item}>
            <ListItem size="$4" title={item} icon={<Text fontSize="$4">•</Text>} />
          </YGroup.Item>
        ))}
      </YGroup>
    </YStack>
  </Page>
)
