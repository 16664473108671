import React, { FC } from 'react'

import { getString } from 'data'

import RewardCta from '../RewardDetails/RewardCta'
import RewardInfoContent from '../RewardDetails/RewardInfoContent'
import ContentH1 from '../typography/Red/ContentH1'
import Copy from '../richText/copy'
import { Button } from '../Buttons/Button'
import { layout } from '../../style/variables'
import { media } from '../../style/media'

interface LoginBoxProps {
  isEarn: boolean
  doLogin: (path?: string) => void
}

const LoginBox: FC<LoginBoxProps> = ({ isEarn, doLogin }) => {
  return (
    <>
      <style jsx>{`
        .reward-cta-actions {
          display: flex;
          flex-direction: column;
          max-width: ${layout.copyWidths.tablet}px;
          margin-left: auto;
          margin-right: auto;
        }
        .cta-action {
          width: 100%;
        }
        .cta-action:first-child {
          margin-right: 24px;
          margin-bottom: 15px;
        }
        @media ${media.tabletAndHigher} {
          .reward-cta-actions {
            flex-direction: row;
          }
          .cta-action {
            max-width: 270px;
          }
        }
        @media ${media.desktopAndHigher} {
          .reward-cta-actions {
            max-width: ${layout.copyWidths.desktop}px;
          }
        }
      `}</style>
      <RewardCta testId="join-virgin-red">
        <RewardInfoContent>
          <Copy>
            <ContentH1>
              {isEarn ? getString('publicRewardDetailsEarn.loginBox.title') : getString('publicRewardDetails.login.box.title')}
            </ContentH1>
            <p>
              {isEarn ? getString('publicRewardDetailsEarn.loginBox.description') : getString('publicRewardDetails.login.box.description')}
            </p>
          </Copy>
          <div className="reward-cta-actions">
            <div className="cta-action">
              <Button
                onClick={() => doLogin()}
                text={getString('loginBox.button')}
                isPrimary
                display="block"
                dataAttributes={{ testid: 'loginbox-join-button' }}
              />
            </div>
            <div className="cta-action">
              <Button
                onClick={() => doLogin()}
                text={`${getString('loginBox.memberQuery')} ${getString('loginBox.memberQuery.link')}`}
                isPrimary={false}
                display="block"
                dataAttributes={{ testid: 'loginbox-login-button' }}
              />
            </div>
          </div>
        </RewardInfoContent>
      </RewardCta>
    </>
  )
}

export { LoginBox }
