import { Locale, ViewingRegion } from 'data/src/utils'

const pathStorageKey = 'path'
const redBasePath = '/virgin-red'
const regionBannerKey = 'regionBannerSeen'

export const defaultLocale = Locale.EN_GB

export const localizePath = (path: string, newLocale?: Locale) => {
  const locale = newLocale || getLocale()
  const relative = createRelativePath(path)

  if (locale !== Locale.EN_GB) {
    return `/${locale}${redBasePath}${relative}`
  }
  return `${redBasePath}${relative}`
}

export const regionToLocale = (region: ViewingRegion | string) => {
  return region === ViewingRegion.US ? Locale.EN_US : Locale.EN_GB
}

export const localeToRegion = (locale: Locale | string) => {
  return locale === Locale.EN_US ? ViewingRegion.US : ViewingRegion.GB
}

export const regionalizePath = (path: string, region?: ViewingRegion) => {
  return localizePath(path, region && regionToLocale(region))
}

export const storePath = (path: string) => {
  localStorage.setItem(pathStorageKey, path)
}

export const getPath = () => {
  return localStorage.getItem(pathStorageKey)
}

export const getLocale = () => (window.location.pathname.includes(Locale.EN_US + redBasePath) ? Locale.EN_US : Locale.EN_GB)

export const getViewingRegion = () => {
  return localeToRegion(getLocale())
}

export const setRegionBannerSeen = () => {
  sessionStorage.setItem(regionBannerKey, 'true')
}

export const getRegionBannerSeen = () => sessionStorage.getItem(regionBannerKey) === 'true'

export const createRelativePath = (path: string) => path.replace(redBasePath, '').replace(Locale.EN_US, '')
