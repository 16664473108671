import React, { FC } from 'react'
import { formatIsoDateToDateWithMonthString } from 'data/src/utils/formatters'
import { color } from '../../style/variables'
import { media } from '../../style/media'
import { Spacer } from '../../components'
import { TextId } from '../../components/TextId/TextId'
import { Button } from '../../components/Buttons/Button'
import { RewardImage } from '../../components/RewardDetails/RewardImage'
import H3Header from '../../components/typography/Red/H3Header'
import RewardInfoContent from '../../components/RewardDetails/RewardInfoContent'

export interface Voucher {
  voucherId: string
  code: string
  image: string
  url: string
  validFrom: string
  validTo: string
  content?: any
}

interface UrlCodeVoucherProps {
  voucher: Voucher
}

const UrlCodeVoucher: FC<UrlCodeVoucherProps> = ({ voucher }) => {
  const {
    content: {
      title,
      brandDetails: { brandLogo, brandName },
      imageUrl,
    },
    validFrom,
    validTo,
    url,
    code,
  } = voucher

  const copyCode = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      document.execCommand('copy', true, text)
    }
  }

  return (
    <>
      <style jsx>{`
        .voucher-details {
          display: flex;
          flex-direction: column;
        }
        .voucher-dates {
          margin-top: 24px;
          font-size: 18px;
          text-align: center;
        }
        .voucher-code-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          border: 2px dashed ${color.brandPrimary};
          border-radius: 8px;
          background-color: rgba(225, 10, 10, 0.03);
          padding: 24px 20px;
          margin-bottom: 24px;
        }
        .voucher-code {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.6;
          letter-spacing: 4.17px;
          color: ${color.black};
          text-align: center;
          background: transparent;
          overflow: auto;
          resize: none;
          border: none;
          outline: none;
          width: 100%;
          height: auto;
          padding: 0;
          word-break: break-all;
        }
        .reward-image {
          display: none;
        }
        .brand-logo {
          display: block;
          background-color: ${color.white};
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
          height: 64px;
          width: 64px;
          margin-left: auto;
          margin-right: auto;
        }
        .external-site-button {
          font-size: 16px;
          line-height: 24px;
          padding: 12px 24px;
          border-radius: 6px;
          text-decoration: none;
          text-align: center;
          font-weight: bold;
          background-color: ${color.brandPrimary};
          color: ${color.white};
        }
        .external-site-button:hover {
          cursor: pointer;
          background-color: ${color.redHover};
        }
        @media ${media.tabletAndHigher} {
          .header-three-wrapper :global(.header-three) {
            font-size: 28px;
            margin: 30px 0 40px;
          }
          .voucher-dates {
            font-size: 20px;
          }
          .voucher-code-box {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 40px 40px 40px 66px;
            margin-bottom: 40px;
            column-gap: 40px;
          }
          .voucher-code {
            font-size: 22px;
            text-align: left;
          }
          .brand-logo {
            height: 96px;
            width: 96px;
          }
        }
        @media ${media.desktopAndHigher} {
          .reward-image :global(.container) {
            width: 870px;
            margin-top: -40px;
          }
          .reward-image {
            display: block;
          }
          .brand-logo {
            display: none;
          }
          .voucher-details {
            align-items: center;
          }
        }
      `}</style>
      <div className="reward-image">
        <RewardImage rewardImageUrl={imageUrl} rewardImageAlt={title} brandLogoUrl={brandLogo} brandLogoAlt={brandName} isEarn={false} />
      </div>
      {brandLogo && <img className="brand-logo" alt="" src={brandLogo} />}
      <Spacer size="xLarge" />
      <RewardInfoContent>
        <div className="header-three-wrapper">
          <H3Header
            color={color.textHeader}
            textAlign="center"
            marginBottom={{ mobile: 24, tablet: 32, desktop: 32 }}
            textTransform="uppercase"
          >
            {title}
          </H3Header>
        </div>
        <div className="voucher-details">
          <div className="voucher-code-box">
            <code data-testid="voucher-code" className="voucher-code">
              {code}
            </code>
            <Button onClick={() => copyCode(code)} isFullWidth={false} text="Copy code" dataAttributes={{ testid: 'copy-button' }} />
          </div>
          <a href={url} className="external-site-button" target="_self" rel="noopener noreferrer" data-testid="external-site-button">
            Continue to {brandName}
          </a>
          <div className="voucher-dates">
            {validFrom && (
              <div>
                <TextId id="voucher.createdOn" />
                {': '}
                {formatIsoDateToDateWithMonthString(validFrom)}
              </div>
            )}
            {validTo && (
              <div>
                <TextId id="voucher.validUntil" />
                {': '}
                {formatIsoDateToDateWithMonthString(validTo)}
              </div>
            )}
          </div>
        </div>
      </RewardInfoContent>
    </>
  )
}

export { UrlCodeVoucher }
