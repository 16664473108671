import React, { FC } from 'react'
import { media } from '@virgin-core/styles'
import { ContentfulEntry, contentTypeOf, getSectionBackgroundColor } from 'data/src/utils/contentfulPages'
import {
  ImageBlock,
  PlaceholderBlock,
  RichTextBlock,
  HeroBlock,
  RedHotBannerBlock,
  EmbeddedContentBlock,
  ImagesTextBlock,
  ImageEmbeddedContentBlock,
  FaqBlock,
} from '.'
import {
  BlockTypes,
  HeroBlockFields,
  ImageBlockFields,
  RichTextBlockFields,
  RedHotBannerBlockFields,
  EmbeddedContentBlockFields,
  ImageEmbeddedContentBlockFields,
} from './types'

interface BlockProps {
  block: ContentfulEntry
}

export const Block: FC<BlockProps> = ({ block }) => {
  switch (contentTypeOf(block)) {
    case BlockTypes.RICH_TEXT_BLOCK:
      return <RichTextBlock fields={block.fields as RichTextBlockFields} />
    case BlockTypes.IMAGE_BLOCK:
      return <ImageBlock fields={block.fields as ImageBlockFields} />
    case BlockTypes.HERO_BLOCK:
      return <HeroBlock fields={block.fields as HeroBlockFields} />
    case BlockTypes.RED_HOT_BANNER:
      return <RedHotBannerBlock fields={block.fields as RedHotBannerBlockFields} />
    case BlockTypes.SOCIAL_MEDIA_POST:
    case BlockTypes.EMBEDDED_CONTENT_BLOCK:
      return <EmbeddedContentBlock fields={block.fields as EmbeddedContentBlockFields} />
    case BlockTypes.IMAGE_EMBEDDED_CONTENT:
      return <ImageEmbeddedContentBlock fields={block.fields as ImageEmbeddedContentBlockFields} />
    case BlockTypes.HOW_IT_WORKS:
      return (
        <>
          <style jsx>{`
            .how-it-works-section {
              background-color: ${getSectionBackgroundColor(block)};
              padding: 60px 0 28px;
            }
          `}</style>
          <div className="how-it-works-section">
            <ImagesTextBlock sectionName="how_it_works_section" fields={block.fields} />
          </div>
        </>
      )
    case BlockTypes.FAQ_BLOCK:
      return (
        <>
          <style jsx>{`
            .faq-section {
              background-color: ${getSectionBackgroundColor(block)};
              padding: 20px 0;
            }
            @media ${media.desktopAndHigher} {
              .faq-section {
                padding: 32px 0 96px;
              }
            }
          `}</style>
          <div data-testid="faq-section" className="faq-section">
            <FaqBlock sectionName="faq_section" fields={block.fields} />
          </div>
        </>
      )
    default:
      return <PlaceholderBlock block={block} />
  }
}
