import React, { FC } from 'react'
import { AuthWrapper, Page } from '../../components'

import { SpendPublic } from './SpendPublic'
import { SpendPrivateContainer as SpendPrivate } from './SpendPrivateContainer'
import { getString } from 'data'

const Spend: FC = () => {
  return (
    <Page testId="page-spend" title="Spend Virgin Points | Virgin Red" metaDescription={getString('meta.description.spend')}>
      <AuthWrapper publicComponent={<SpendPublic />} privateComponent={<SpendPrivate />} />
    </Page>
  )
}

export { Spend }
