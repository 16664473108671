import React, { FC, PropsWithChildren } from 'react'
import { GridWrapper, GridContainer, GridItem } from '@virgin-core/components'

import { selectFeatureById } from 'data/src/redux/features/features.selectors'

import { PATHS } from '../../router/paths'
import { media } from '../../style/responsive'
import { color } from '../../style/variables'
import { Steps } from '../../components'
import TopBarNav from '../../components/Navigation/MainNavigation/TopBarNav'
import { StepItemProps } from '../../components/Steps/Steps'
import { getPath } from '../../dataImplementation/viewingRegionWeb'
import { useAppSelector } from '../../redux/hooks/hooks'
import { FeatureName } from 'data/src/redux/features/features.types'

interface OnboardingBackgroundProps {
  showSteps?: boolean
}

const OnboardingBackground: FC<PropsWithChildren<OnboardingBackgroundProps>> = ({ showSteps = true, children }) => {
  const partnerConnectFlag = useAppSelector((state) => selectFeatureById(state, FeatureName.WEB_PARTNER_CONNECT_PARAMS))

  const stepItems: StepItemProps[] = [
    { title: 'Your Details' },
    { title: 'Terms & Conditions' },
    { title: 'Stay in Touch' },
    { title: 'Start Exploring' },
  ]

  const pathName = getPath()
  let activeStep
  switch (pathName) {
    case PATHS.ONBOARDING_PERSONAL_DETAILS:
      activeStep = 0
      break
    case PATHS.ONBOARDING_TERMS_AND_CONDITIONS:
      activeStep = 1
      break
    case PATHS.ONBOARDING_COMMUNICATION_PREFERENCES:
      activeStep = 2
      break
    case PATHS.ONBOARDING_WELCOME:
      activeStep = 3
      break
    case PATHS.ONBOARDING_AUTO_LINK_ACCOUNTS:
      if (partnerConnectFlag) activeStep = 3
      break
    case PATHS.ONBOARDING_LINK_ACCOUNTS_DEPRECATED:
      activeStep = 3
  }

  return (
    <>
      <style jsx>{`
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parent-container {
          animation: 0.5s cubic-bezier(0, 0, 0.25, 1) 0.25s in both;
        }
        .steps-container {
          transition: 0.6s;
          padding-top: 32px;
          // placing opacity here fires a full-page rendering
        }
        :global(.card) {
          position: relative;
          border-radius: 8px;
          width: 100%;
          padding: 32px 0 20px;
          margin: 0 auto 20px;
          animation: 0.5s cubic-bezier(0, 0, 0.25, 1) 0.4s in both;
        }

        @media ${media.tabletAndHigher} {
          .steps-container,
          :global(.card) {
            padding-top: 56px;
          }
        }

        @media ${media.desktopAndHigher} {
          .parent-container {
            padding-top: 72px;
          }
          .steps-container {
            padding: 0 0 56px;
          }
          :global(.card) {
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px ${color.secondaryButtonBorder};
            background: ${color.white};
            max-width: 648px;
            padding: 56px 80px 64px;
            margin-bottom: 120px;
          }
        }
      `}</style>
      <TopBarNav />
      <div className="parent-container">
        {showSteps && (
          <div className="steps-container">
            <Steps items={stepItems} active={activeStep} />
          </div>
        )}
        <GridWrapper>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={8}>
              <div className="card">{children}</div>
            </GridItem>
          </GridContainer>
        </GridWrapper>
      </div>
    </>
  )
}

export { OnboardingBackground }
