import { getString } from 'data'
import React from 'react'
import { Page } from '../../components'

export function ReferAFriend() {
  return (
    <Page title={getString('refer.a.friend.title')}>
      <div id="mmWrapper"></div>
    </Page>
  )
}
