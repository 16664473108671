import React, { FC, useEffect, useRef, useState } from 'react'
import { getBalance, getIsLoading } from 'data/src/redux/balance/balance.selectors'
import { fetchBalance } from 'data/src/redux/balance/balance.thunk'
import { getProfileData } from 'data/src/redux/profile/selectors'
import { logger } from 'data/src/utils'
import { doFetchProfile } from 'data/src/redux/profile/dispatchers'
import { getAuthTokens, setAuthTokens } from 'data/src/redux/auth/getAuthTokens'
import { GridContainer, GridItem } from '@virgin-core/components'

import { AccountMenu } from '../AccountMenu'
import { media } from '../../../../../../style/responsive'
import { AccountInfo } from '../AccountInfo'
import Avatar from '../../../../../icons/Avatar'
import ChevronDown from '../../../../../icons/ChevronDown'
import { color, FontFamilies, ZIndex } from '../../../../../../style/variables'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks/hooks'
import { virginRedPath } from 'web/src/router/paths'
import { PUBLIC_URL } from '../../../../../../config'

// This isn't ideal, sonar cloud is complaining about duplicate search code. If we reference the Search code from
// pages > Search as thats where its likely going to live. AccountPrivate should be removed when the new navbar goes live.
import { Search } from '../../../../../Page/Search'

const AccountPrivate: FC = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(getProfileData)
  const balance = useAppSelector(getBalance)
  const isBalanceLoading = useAppSelector(getIsLoading)

  const [isMenuVisible, showMenu] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  let searchRef = useRef<HTMLDivElement>(null)
  let toggleRef = useRef<HTMLDivElement>(null)

  const currentBalance = balance?.current || 0
  const firstName = profile?.firstName || ''
  const email = profile?.email || ''

  const showSearchToggle = window.location.pathname.includes(virginRedPath)

  useEffect(() => {
    dispatch(fetchBalance())
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        const didUpdateAccoount = sessionStorage.getItem('didUpdateAccount')
        const fetchNewProfile = async () => {
          const newTokens = await getAuthTokens(true)

          if (newTokens?.accessToken && newTokens?.idToken) {
            await setAuthTokens(newTokens.accessToken, newTokens.idToken)
          }

          dispatch(doFetchProfile())
          sessionStorage.removeItem('didUpdateAccount')
        }
        if (didUpdateAccoount) {
          await fetchNewProfile()
        }
      } catch (err) {
        logger.warn('PrivateAccount: Error fetching new profile: ', err)
      }
    })()
  }, [dispatch])

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      const node = searchRef.current
      const toggle = toggleRef.current

      if (!node?.contains(target as HTMLDivElement) && !toggle?.contains(target as HTMLDivElement)) {
        setShowSearch(false)
      }
    }
    showSearch && document.addEventListener('click', handleOutsideClick)

    return () => document.removeEventListener('click', handleOutsideClick)
  }, [showSearch])

  const searchToggle = (
    <>
      <style jsx>
        {`
          .toggle-search {
            align-items: center;
            display: flex;
            justify-content: center;
            pointer-events: auto;
            z-index: 6;
            width: 38px;
            height: 100%;
            ${showSearch && `background: ${color.darkPinkBackground}`}
          }
          .search-icon {
            cursor: pointer;
          }
          button {
            border: 0;
            background: none;
            cursor: pointer;
          }
        `}
      </style>
      <div className="toggle-search" ref={toggleRef}>
        <button aria-label="Virgin Red search" aria-expanded={showSearch} onClick={() => setShowSearch(!showSearch)}>
          <img alt={`${showSearch ? 'Close ' : ''}Search`} src={`${PUBLIC_URL}/img/${showSearch ? 'close-search' : 'search-icon'}.svg`} />
        </button>
      </div>
    </>
  )

  return (
    <>
      <style jsx>{`
        :global(.account__overlay) {
          position: absolute;
          top: 54px;
          left: 0;
          z-index: ${ZIndex.UserMenu};
        }
        .account__menu {
          background: white;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
          border-radius: 8px;
        }
        .account__mask {
          background: transparent;
          border: 0;
          height: 100%;
          left: 0;
          margin: 0;
          padding: 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2;
        }
        .account__button {
          align-items: center;
          background-color: ${color.white};
          border: 0;
          color: ${color.brandPrimary};
          cursor: pointer;
          display: flex;
          font-family: ${FontFamilies.barlow};
          font-size: 15px;
          padding: 0;
          margin: auto 0 auto 28px;
        }
        .account__button:focus {
          box-shadow: none;
          outline: none;
        }
        .account__arrow {
          margin-left: 5px;
          margin-top: ${isMenuVisible ? '10px' : 0};
          transform: ${isMenuVisible ? 'rotate(-180deg)' : 'none'};
        }
        .account__info {
          display: none;
        }

        @media ${media.tabletLargeAndHigher} {
          .account__menu {
            width: 230px;
            margin-left: auto;
          }
          .account__info {
            display: block;
          }
        }
      `}</style>
      {showSearchToggle && searchToggle}
      <button data-testid="account-button" className="account__button" onClick={() => showMenu(!isMenuVisible)}>
        <div className="account__info" data-testid={isBalanceLoading ? null : 'account-button-loaded'}>
          <AccountInfo points={currentBalance} firstName={firstName} />
        </div>
        <Avatar />
        <div className="account__arrow">
          <ChevronDown />
        </div>
      </button>
      {isMenuVisible && (
        <>
          <GridContainer className="account__overlay">
            <GridItem xs={12}>
              <div className="account__menu">
                <AccountMenu close={() => showMenu(false)} firstName={firstName} email={email} points={currentBalance} />
              </div>
            </GridItem>
          </GridContainer>
          <button onClick={() => showMenu(false)} className="account__mask" />
        </>
      )}
      {showSearch && <Search ref={searchRef} />}
    </>
  )
}

export { AccountPrivate }
