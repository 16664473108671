import { getRedDataApiPaths } from '../config'
import { RedemptionType, Reward } from '../redux/rewards/types'
import { get } from '../utils/httpClient'

const createRewardGroupApi = () => getRedDataApiPaths().GENERATE_B2C_API('/v1/reward-groups')

export const ARTIFICAL_DELAY = 1000

export type RewardGroup = {
  id: string
  description: string
  reference: string
}

export const MOCK_REWARD_GROUPS: RewardGroup[] = [
  { id: '1234', description: 'Cobrand $15k spend rewards', reference: 'COBRAND_15K' },
  { id: '5678', description: 'Cobrand $30k spend rewards', reference: 'COBRAND_30K' },
]

export const apiGetRewardGroups = async ({ useMock = false }: { useMock?: boolean } = {}): Promise<RewardGroup[]> => {
  if (useMock) {
    await new Promise((resolve) => setTimeout(resolve, ARTIFICAL_DELAY))
    return MOCK_REWARD_GROUPS
  }

  const response = (await get(createRewardGroupApi(), true)) as { rewardGroups: RewardGroup[] }

  return response.rewardGroups
}

const MOCK_REWARD: Reward = {
  cost: 1000,
  rewardId: '1',
  rewardParentId: '1',
  content: {
    title: 'Free Wine',
    imageUrl: '//images.ctfassets.net/w2u6i2262322/3BVlHrrRHj5c4STLPT1Nnj/69dffc5d0efe4be87370d343634adbb6/__50-Voucher_compress.jpg',
    brandDetails: {
      id: '1',
      brandDescription: 'Virgin Wines',
      brandLogo:
        '//images.ctfassets.net/w2u6i2262322/61Jsb9K2bayb4M9OdsxkRT/c16c72aaadd199076c4d45a755703370/Virgin_Wines_Homepage_logo_512x512px_Red.jpg',
      brandName: 'Virgin Atlantic',
      requiresBrandPage: false,
      slug: 'virgin-atlantic',
    },
    termsUrl: '',
    partner: '',
    tags: [],
    lead: 'Have some free wine on us as a thank you for spending so much',
  },
  name: 'Free Wine',
  redemptionType: RedemptionType.DIGITAL_FULFILMENT,
}

export const MOCK_REWARDS: Reward[] = [MOCK_REWARD, MOCK_REWARD]

export const apiGetRewardsByGroupId = async ({
  rewardGroupId,
  useMock = false,
}: {
  rewardGroupId: string
  useMock?: boolean
}): Promise<Reward[]> => {
  if (useMock) {
    await new Promise((resolve) => setTimeout(resolve, ARTIFICAL_DELAY))
    return MOCK_REWARDS
  }

  // requirement is only to get spend rewards currently
  const response = (await get(`${createRewardGroupApi()}/${rewardGroupId}/rewards?type=SPEND`, true)) as { rewards: Reward[] }

  return response.rewards
}
