import { connect } from 'react-redux'

import { getString } from 'data'
import { RewardEarn } from 'data/src/redux/rewardsEarn/types'
import { Reward } from 'data/src/redux/rewards/types'
import { getIsLoading } from 'data/src/redux/discoveryPublic/selectors'
import { getIsLoadingEarnPublic } from 'data/src/redux/discoveryEarnPublic/selectors'
import { getAllRewardsIds as getAllRewardsEarnIds, getRewardById as getRewardEarnById } from 'data/src/redux/rewardsEarn/selectors'
import { getWishlistRewards, getIsLoadingWishlist } from 'data/src/redux/wishlist/selectors'
import { getAllRewardsIds, getRewardById } from 'data/src/redux/rewards/selectors'
import { dofetchHomePageEarnRewards } from 'data/src/redux/rewardsEarn/dispatchers'
import { doFetchHomePageRewards } from 'data/src/redux/rewards/dispatchers'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist } from 'data/src/redux/wishlist/dispatchers'
import { convertRewardToCard } from 'data/src/utils/convertRewardToCard'
import { getRecommendedItemsCarousel } from 'data/src/utils/getRecommendedItemsCarousel'

import { DispatchFunction, RootState } from '../../../redux/types'
import { PATHS, virginRedPath } from '../../../router/paths'
import { CardTypes, IntroCarouselBlockPrivateContainerProps } from '../types'
import { IntroCarouselTileProps } from './IntroCarouselTile'
import { IntroCarouselBlock, IntroCarouselType } from './IntroCarouselBlock'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'
import { selectFeatureById } from 'data/src/redux/features/features.selectors'
import { FeatureName } from 'data/src/redux/features/features.types'
import { PUBLIC_URL } from '../../../config'

const MAX_NUMBER_ITEMS = 8

const SUBSCRIPTION_ITEM_DATA = {
  type: 'subscription',
  content: {
    title: getString('subscriptionCarouselTile.title'),
    lead: getString('subscriptionCarouselTile.description'),
    imageUrl: `${PUBLIC_URL}/img/subscription-carousel-item.png`,
    brandDetails: {
      brandName: 'Virgin Red',
      brandLogo: `${PUBLIC_URL}/img/virgin-logo-on-red.png`,
    },
  },
} as Reward

export const getSpendItemsCarousel = (state: RootState, start: number = 4): Reward[] => {
  const spendIds = getAllRewardsIds(state).slice(start, MAX_NUMBER_ITEMS + start)
  return spendIds.map((spendId) => getRewardById(state, spendId))
}

export const getEarnItemsCarousel = (state: RootState, start: number = 4): RewardEarn[] => {
  const earnIds = getAllRewardsEarnIds(state).slice(start, MAX_NUMBER_ITEMS + start)
  return earnIds.map((earnId) => getRewardEarnById(state, earnId))
}

export const getTitleCarousel = (type: CardTypes) => {
  let title = ''
  switch (type) {
    case CardTypes.EARN:
      title = getString('home.introCarousel.title.earn')
      break
    case CardTypes.SPEND:
      title = getString('home.introCarousel.title.spend')
      break
    case CardTypes.FEATURED:
      title = getString('home.introCarousel.title.recommended')
  }
  return title
}

export const getItemsCarousel = (state: RootState, type: CardTypes): IntroCarouselTileProps[] => {
  let items: (Reward | RewardEarn)[] = []
  switch (type) {
    case CardTypes.SPEND:
      items = getSpendItemsCarousel(state)
      break
    case CardTypes.EARN:
      items = getEarnItemsCarousel(state)
      break
    case CardTypes.FEATURED: {
      const webSubscriptionFlag = selectFeatureById(state, FeatureName.WEB_SUBSCRIPTION)
      items = getRecommendedItemsCarousel(state, webSubscriptionFlag ? SUBSCRIPTION_ITEM_DATA : undefined)
    }
  }
  return convertRewardToCard(items)
}

export const doFetchAllCarousel = async (type: CardTypes, dispatch: DispatchFunction) => {
  switch (type) {
    // case CardTypes.FEATURED:
    // (ommitted so as not to cause a duplicate call - already has info from CardTypes.EARN)

    case CardTypes.EARN:
      // we limit the rewards fetch to 12 in the private Homepage
      // (4 for Featured and 8 for Carousel) to improve performance
      await dofetchHomePageEarnRewards(12)(dispatch)
      break
    case CardTypes.SPEND:
      await doFetchHomePageRewards(12)(dispatch)
      break
    default:
  }
}

const mapStateToProps = (state: RootState, { type, showAllLink }: IntroCarouselBlockPrivateContainerProps) => ({
  title: getTitleCarousel(type),
  seeAllLink: showAllLink ? virginRedPath + (type === CardTypes.EARN ? PATHS.EARN : PATHS.SPEND) : undefined,
  items: () => getItemsCarousel(state, type),
  isLoading: type === CardTypes.EARN ? getIsLoadingEarnPublic(state) : getIsLoading(state),
  type: type === CardTypes.EARN ? IntroCarouselType.EARN : IntroCarouselType.REWARD,
  wishlist: getWishlistRewards(state),
  isLoadingWishlist: getIsLoadingWishlist(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction, { type }: IntroCarouselBlockPrivateContainerProps) => ({
  doGetItems: () => doFetchAllCarousel(type, dispatch),
  ...bindTypedActionCreators(
    {
      doRemoveRewardFromWishlist,
      doAddRewardToWishlist,
    },
    dispatch
  ),
})

export const IntroCarouselBlockPrivateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroCarouselBlock) as unknown as React.FC<IntroCarouselBlockPrivateContainerProps>
