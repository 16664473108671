import React, { FC, ReactNode, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { getString } from 'data'
import { findWishedReward, getRewardIdFromURL } from 'data/src/utils'
import { doRedeemRewardConfirmed } from 'data/src/redux/rewards/dispatchers'
import { RedemptionType, Reward } from 'data/src/redux/rewards/types'
import { color, ZIndex, layout } from '../../style/variables'
import { media } from '../../style/media'
import { AuthWrapper, Content, PageError404, PageLoading, LoginBox, HelpCTA, Page, Spacer } from '../../components'
import ContentH1 from '../../components/typography/Red/ContentH1'
import H1Header from '../../components/typography/Red/H1Header'
import Copy from '../../components/richText/copy'
import { Button } from '../../components/Buttons/Button'
import ContentfulCopy from '../../components/richText/contentfulCopy'
import { RewardImage } from '../../components/RewardDetails/RewardImage'
import RewardInfoWrapper from '../../components/RewardDetails/RewardInfoWrapper'
import RewardInfoContent from '../../components/RewardDetails/RewardInfoContent'
import RewardContent from '../../components/RewardDetails/RewardContent'
import RewardCta from '../../components/RewardDetails/RewardCta'
import { Points } from '../../components/Points'
import { DateAndLocation } from '../../components/DateAndLocation'
import { WishListIcon } from '../../components/WishListIcon'
import Header from '../../components/RewardDetails/Header'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { RewardDetailsProps } from './types'
import { getIsSteppedUp } from 'data/src/redux/auth/selectors'
import { useTrackRewardEvent } from '../../analytics/useTrackRewardEvent'

export const RewardDetails: FC<RewardDetailsProps> = ({
  isEarn,
  doRedeemReward,
  onViewReward,
  doFetchReward,
  isLoading,
  errorMessage,
  testIdPrefix,
  doAddRewardToWishlist,
  doRemoveRewardFromWishlist,
  isLoadingWishlist,
  doGetWishlist,
  getRewardById,
}) => {
  const { rewardPath = '' } = useParams() ?? {}
  const rewardId = getRewardIdFromURL(rewardPath) ?? ''
  const reward = useAppSelector((state) => getRewardById(state, rewardId))
  const isWished = useAppSelector((state) => findWishedReward(state.wishlist.wishlist.rewards, rewardId))
  const testId = `${testIdPrefix}-${rewardId}`

  const { trackRewardRedeemedEvent, trackRewardViewedEvent } = useTrackRewardEvent(reward, isEarn ? 'earn' : 'spend')

  const dispatch = useAppDispatch()
  const isSteppedUp = useAppSelector(getIsSteppedUp)
  useEffect(() => {
    if (isSteppedUp) {
      dispatch(doRedeemRewardConfirmed())
    }
  }, [isSteppedUp, dispatch])

  useEffect(() => {
    if (doGetWishlist) {
      doGetWishlist()
    }
  }, [doGetWishlist])

  useEffect(() => {
    // if we need to re-hydrate the app
    // we will fetch only the relevant reward
    if (!reward) {
      doFetchReward(rewardId)
    }
  }, [isEarn, doFetchReward, reward, rewardId])

  const onRender = useCallback(() => {
    if (reward) {
      onViewReward(reward.rewardId)
      trackRewardViewedEvent()
    }
  }, [reward?.rewardId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <Page testId={testId} title={'… | Virgin Red'}>
        <PageLoading />
      </Page>
    )
  }

  if (!reward && errorMessage) {
    return (
      <Page testId={testId} title={'Whoops. 404 Reward not found | Virgin Red'}>
        <PageError404 />
      </Page>
    )
  }

  if (reward) {
    const {
      rewardId,
      cost = 0,
      redemptionType,
      earnType,
      content: {
        title,
        description,
        brandDetails: { brandName, brandLogo },
        imageUrl,
        redemption,
        location = '',
        dateAndTime = '',
        redeemButtonTextOverride,
      },
    } = reward as Reward

    const renderContentH1 = (node: Node, children: ReactNode): ReactNode => {
      return <ContentH1>{children}</ContentH1>
    }
    const isDonation = redemptionType === RedemptionType.DONATION_LINK

    let redeemButtonText
    if (isEarn) {
      redeemButtonText = redeemButtonTextOverride ?? getString('rewardDetails.button.getPoints')
    } else {
      redeemButtonText = isDonation ? getString('rewardDetails.button.donate') : getString('rewardDetails.button.redeem')
    }
    const redeemButtonId = `${redeemButtonText.toLowerCase().replace(/\s/g, '-')}-button`

    const getOnWishListIconClick = () => {
      return isWished
        ? () => doRemoveRewardFromWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
        : () => doAddRewardToWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
    }

    return (
      <>
        <style jsx>{`
          .points {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
          }
          .points-button {
            height: 48px;
            width: 140px;
          }
          .points-value {
            font-weight: 600;
            font-style: italic;
            line-height: 1.14;
            letter-spacing: 0.6px;
            color: ${color.brandPrimary};
            margin-bottom: 16px;
            font-size: 28px;
          }
          .header-wrapper {
            display: none;
          }
          .date-location-wrapper {
            display: flex;
            justify-content: center;
          }
          .wishlist-icon-container {
            cursor: pointer;
            position: absolute;
            z-index: ${ZIndex.carouselBoxShadow};
            top: 8px;
            right: 8px;
          }
          .reward-image-container {
            margin: 0 auto;
            position: relative;
          }
          @media ${media.tabletAndHigher} {
            .points {
              flex-direction: row;
            }
            .points-value {
              margin-bottom: 0;
            }
            .reward-image-container {
              margin-top: -231px; // tablet header height
              width: ${layout.heroWidth.tablet}px;
            }
            .header-wrapper {
              display: block;
            }
            .header {
              height: 168px;
            }
            .spend {
              margin-left: 65px;
            }
            .date-location-wrapper {
              justify-content: flex-start;
            }
          }
          @media ${media.desktopAndHigher} {
            .reward-image-container {
              width: ${layout.heroWidth.desktop}px;
              margin-top: -341px;
            }
            .header {
              height: 307px;
            }
            .points-value {
              font-size: 42px;
              line-height: 1.05;
              letter-spacing: 1px;
            }
          }
          @media ${media.mobileSmallAndLower} {
            .points-value {
              font-size: 20px;
            }
          }
        `}</style>
        <Page testId={testId} title={`${title} | Virgin Red`} navigationFocusHandler={onRender} metaDescription={description}>
          <div className="header-wrapper">
            <Header>
              <div className="header"></div>
            </Header>
          </div>
          <Content>
            <div className="reward-image-container">
              <AuthWrapper
                publicComponent={''}
                privateComponent={
                  <div className="wishlist-icon-container">
                    <WishListIcon isWished={isWished} onWishListIconClick={getOnWishListIconClick()} isLoading={isLoadingWishlist} />
                  </div>
                }
              />

              <RewardImage
                rewardImageUrl={imageUrl}
                rewardImageAlt={title}
                brandLogoUrl={brandLogo}
                brandLogoAlt={brandName}
                isEarn={isEarn}
                earnType={earnType}
              />
            </div>
            <RewardInfoWrapper>
              <Spacer size="xLarge" />
              <RewardInfoContent>
                <H1Header
                  color={color.textHeader}
                  textAlign="center"
                  marginBottom={{
                    mobile: 24,
                    tablet: 32,
                    desktop: 32,
                  }}
                  textTransform="uppercase"
                >
                  {title}
                </H1Header>
                <div className="points">
                  {!isEarn ? (
                    <div className="points-value">
                      <Points value={cost} showPlus={redemptionType === RedemptionType.SIMPLE_LINK} />
                      <span> point{cost !== 1 ? 's' : ''}</span>
                    </div>
                  ) : null}
                  <AuthWrapper
                    publicComponent=""
                    privateComponent={
                      <div className={isEarn ? 'points-button' : 'points-button spend'}>
                        <Button
                          id={redeemButtonId}
                          text={redeemButtonText}
                          display="inline-block"
                          isPrimary
                          onClick={(): void => {
                            trackRewardRedeemedEvent()
                            doRedeemReward(rewardId)
                          }}
                        />
                      </div>
                    }
                  />
                </div>
                <Spacer size="medium" />
                {(location || dateAndTime) && (
                  <>
                    <div className="date-location-wrapper">
                      <DateAndLocation location={location} dateAndTime={dateAndTime} />
                    </div>
                    <Spacer size="medium" />
                  </>
                )}
                <Copy>
                  <p>{description}</p>
                </Copy>
                <Spacer size="medium" />
              </RewardInfoContent>
              <AuthWrapper
                publicComponent={<LoginBox isEarn={isEarn} />}
                privateComponent={
                  <RewardCta>
                    <RewardInfoContent>
                      {redemption && redemption.method && (
                        <ContentfulCopy
                          content={redemption.method}
                          renderNodeOverrides={{
                            [BLOCKS.HEADING_1]: renderContentH1,
                          }}
                        />
                      )}
                    </RewardInfoContent>
                  </RewardCta>
                }
              />
              <RewardContent reward={reward} />
            </RewardInfoWrapper>
          </Content>
          <HelpCTA heading={'rewardDetails.needHelp'} />
        </Page>
      </>
    )
  }
  return null
}
