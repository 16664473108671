import React from 'react'
import { YStack, XStack, useMedia } from '@red-ui/components/'
import { LoadSpinner, Page } from '../../components'
import { Content, Heading, Subheading } from './styles'
import { Plan } from './Plan'
import { getString } from 'data'
import { useQuery } from '../../helpers/asyncHooks'
import { apiGetSubscriptionPlans } from 'data/src/api/subscriptions.api'

export const Subscribe = () => {
  const media = useMedia()
  const Stack = media.gtTabletPortrait ? XStack : YStack
  const { loading, value: subscriptionPlans } = useQuery(apiGetSubscriptionPlans)

  return (
    <Page title={getString('subscribe.page.title')}>
      <Content>
        {loading ? (
          <LoadSpinner position="absolute" />
        ) : (
          subscriptionPlans?.length && (
            <>
              {getString('subscribe.page.content.title')
                .split('\n')
                .map((row) => (
                  <Heading key={row}>{row}</Heading>
                ))}
              <Subheading>{getString('subscribe.page.content.subtitle')}</Subheading>
              <Stack gap="$3">
                {subscriptionPlans.map((plan) => (
                  <Plan key={plan.planId} {...plan} />
                ))}
              </Stack>
            </>
          )
        )}
      </Content>
    </Page>
  )
}
