import { connect } from 'react-redux'

import { getCategories, getCategoryCostCounts } from 'data/src/redux/categoriesPublic/selectors'
import { doUpdateRewardsPublicPaginated } from 'data/src/redux/discoveryPublic/dispatchers'
import { doFetchCategoriesPublic } from 'data/src/redux/categoriesPublic/dispatchers'
import { resetPagination } from 'data/src/redux/discovery/actions'
import {
  getIsFiltersChanged,
  getIsFiltersSet,
  getIsLastResultFiltered,
  getSelectedCategories,
  getSelectedCategoryCostCounts,
} from 'data/src/redux/discoveryPublic/selectors'
import {
  clearCategoryPublicFilter,
  clearCategoryCostCountPublicFilter,
  clearAllPublicFilters,
  toggleCategoryPublicSelected,
  toggleCategoryCostCountPublicSelected,
} from 'data/src/redux/discoveryPublic/actions'

import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'

import { RewardsFilter } from './RewardsFilter'

const mapStateToProps = (state: RootState) => ({
  categories: getCategories(state),
  categoryCostCounts: getCategoryCostCounts(state),
  selectedCategories: getSelectedCategories(state),
  selectedCategoryCostCounts: getSelectedCategoryCostCounts(state),
  isFiltersChanged: getIsFiltersChanged(state),
  isLastResultsFiltered: getIsLastResultFiltered(state),
  isFiltersSet: getIsFiltersSet(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      toggleCategorySelected: toggleCategoryPublicSelected,
      toggleCategoryCostCountSelected: toggleCategoryCostCountPublicSelected,
      doFetchCategories: doFetchCategoriesPublic,
      clearCategoryFilter: clearCategoryPublicFilter,
      clearCategoryCostCountFilter: clearCategoryCostCountPublicFilter,
      clearAllFilters: clearAllPublicFilters,
      doUpdateRewards: doUpdateRewardsPublicPaginated,
      clearPaginationFromView: resetPagination,
    },
    dispatch
  )

export const PublicRewardsFilterSpendContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsFilter)
