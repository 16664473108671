import { connect } from 'react-redux'
import { DispatchFunction } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RedLoginBanner } from './RedLoginBanner'

import { doLoginWeb } from 'data/src/redux/auth/dispatchers'
import { getPath } from '../../dataImplementation/viewingRegionWeb'

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doLogin: () => doLoginWeb(getPath() || undefined),
    },
    dispatch
  )

const RedLoginBannerContainer = connect(null, mapDispatchToProps)(RedLoginBanner)

export { RedLoginBannerContainer }
