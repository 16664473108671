import React, { FC, useCallback } from 'react'
import { getString } from 'data'
import { ContentfulLocale } from 'data/src/utils/contentfulPages'

import { Page } from '../../components'
import H1Header from '../../components/typography/Red/H1Header'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { DynamicContentContainer } from './DynamicContentContainer'
import { color, spacing } from '../../style/variables'

interface TermsAndConditionsProps {
  doFetchTermsAndConditions: (viewingRegion?: ContentfulLocale | string) => void
}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ doFetchTermsAndConditions }) => {
  const viewingRegion = localeToRegion(getLocale())

  const onRender = useCallback(
    () => (viewingRegion ? doFetchTermsAndConditions(`EN_${viewingRegion}`) : doFetchTermsAndConditions()),
    [doFetchTermsAndConditions, viewingRegion]
  )

  return (
    <Page
      testId="page-terms"
      title="Terms & Conditions | Virgin Red"
      navigationFocusHandler={onRender}
      metaDescription={getString('meta.description.terms.&.conditions')}
    >
      <H1Header
        color={color.textHeader}
        textAlign="center"
        marginTop={spacing.medium}
        marginBottom={spacing.medium}
        textTransform="uppercase"
      >
        {getString('termsAndConditions.title')}
      </H1Header>
      <DynamicContentContainer />
    </Page>
  )
}

export { TermsAndConditions }
