import { getString } from 'data'
import React, { useEffect, useState } from 'react'
import { Page } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'

export function ReferDashboard() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 500)
  }, [])

  return (
    <>
      {show && (
        <Page title={getString('account.referdashboard.title')}>
          <MyAccountBackground>
            <div id="mmWrapper"></div>
          </MyAccountBackground>
        </Page>
      )}
    </>
  )
}
