import { YStack, styled, View } from '@red-ui/components/'
import { CARD_WIDTH } from '../styles'

export const Chip = styled(View, {
  minWidth: 113,
  backgroundColor: '$white',
  paddingVertical: '$0.5',
  paddingHorizontal: '$3',
  borderRadius: '$7',
  shadowColor: '$black10',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.12,
  shadowRadius: '$1',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Card = styled(YStack, {
  justifyContent: 'space-between',
  $gtMobile: {
    minWidth: CARD_WIDTH,
  },
  maxWidth: CARD_WIDTH,
  borderRadius: '$4',
  backgroundColor: '$black2',
  padding: '$3',
})

export const featuresListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0,
}
