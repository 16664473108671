import { Profile } from '../profile/types'
import { logger } from '../../utils/logger'
import { getRedDataConfig } from '../../config'
import { GetRedDataState } from '../types'
import { ViewingRegion } from '../../utils'
import { isWeb } from '../../utils/helperFunctions'

export const postLoginRedirect = (profile: Profile | null, path?: string) => {
  logger.log('postLoginRedirect.ts: profile:', profile)
  const config = getRedDataConfig()

  const onboardingLinkingStage =
    !profile?.complete && (profile?.appState?.onboarding === 'LinkVaa' || profile?.appState?.onboarding === 'Welcome')
  const incompleteProfileOrLinkingStage = profile?.complete || onboardingLinkingStage
  const partnerParamsPresent = isWeb() ? checkPartnerParams() : null

  if (incompleteProfileOrLinkingStage && partnerParamsPresent) {
    config.navigate(config.navTargets.AutoLinkPartner, {}, profile?.country as ViewingRegion)
  } else if (profile?.appState?.onboarding) {
    const { onboarding } = profile.appState
    config.navigate(config.navTargets[onboarding], {}, profile?.country as ViewingRegion)
  } else {
    config.navigate(path || config.navTargets.Home, {}, profile?.country as ViewingRegion)
  }
}

export const postRefreshRedirect = (profile: Profile | null) => (getState: GetRedDataState) => {
  logger.log('postLoginRedirect.ts:postRefreshRedirect(): profile:', profile)
  const isAutoLinking = getState().accounts.isAutoLinking
  const config = getRedDataConfig()
  if (isAutoLinking) {
    config.navigate(config.navTargets.AutoLinkPartner, {}, profile?.country as ViewingRegion)
  }
}

const checkPartnerParams = () => {
  const idToken = localStorage.getItem('partnerLinkIdToken')
  const partnerCode = localStorage.getItem('connectPartnerCode')
  return !!idToken && !!partnerCode
}
