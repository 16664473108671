import { get } from '../utils/httpClient'
import { getRedDataApiPaths } from '../config'

export type SubscriptionPlan = {
  planId: string
  name: string
  cost: string
  description: string
  frequency: string | null
  features: Array<{
    description: string
    extendedDescription?: string
  }>
}

export const apiGetSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
  const response = await get(`${getRedDataApiPaths().B2C_API_SUBSCRIPTIONS_PLANS}`, true)
  return response.plans
}
