import React, { useCallback } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { Anchor, ColorTokens, FontSizeTokens, H1, H2, H3, H4, H5, H6, Paragraph, SizableText, useStyle, YStack } from '@red-ui/components'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Document } from '@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types'
import { isUrlExternal } from '../../helpers/urlHelper'
import { DynamicContentFactory } from './DynamicContentFactory'

const AnchorLink = Anchor.styleable(({ href, ...props }, ref) => {
  const navigate = useNavigate()
  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      event.preventDefault()
      if (href) {
        if (isUrlExternal(href)) window.location.href = href
        else navigate(href)
      }
    },
    [href, navigate]
  )
  return <Anchor {...props} href={href} onPress={handlePress} ref={ref} />
})

const useCommonStyles = () =>
  useStyle({
    color: 'inherit',
  })

const optionOverrides: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1 {...useCommonStyles()}>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2 {...useCommonStyles()}>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3 {...useCommonStyles()}>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4 {...useCommonStyles()}>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5 {...useCommonStyles()}>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6 {...useCommonStyles()}>{children}</H6>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isEmpty = node.content.every((child) => child.nodeType === 'text' && child.value === '')
      return (
        <Paragraph {...useCommonStyles()} fontSize="$3" lineHeight="$4" marginVertical={isEmpty ? 0 : '$2'}>
          {children}
        </Paragraph>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => <DynamicContentFactory content={node.data?.target} />,
    [INLINES.HYPERLINK]: (node, children) => (
      <AnchorLink {...useCommonStyles()} fontSize={'inherit' as FontSizeTokens} href={node.data.uri}>
        {children}
      </AnchorLink>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <SizableText {...useCommonStyles()} fontSize={'inherit' as FontSizeTokens} fontWeight={600}>
        {text}
      </SizableText>
    ),
  },
}

const richTextToReactConverter = (richTextDocument: Document, options?: Options) => documentToReactComponents(richTextDocument, options)

export const RichTextRenderer = YStack.styleable<{ content: Document; options?: Options; color?: ColorTokens }>(
  ({ content, options = optionOverrides, ...props }, ref) => (
    <YStack {...props} ref={ref}>
      {richTextToReactConverter(content, options)}
    </YStack>
  )
)
