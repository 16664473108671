import React from 'react'
import { Page } from 'web/src/components'
import { getString } from 'data'
import { MyAccountBackground } from '../MyAccountBackground'
import { Button, YStack, H3, styled } from '@red-ui/components/'
import { doShowDialog } from 'data/src/redux/dialog/dispatchers'
import { DialogType } from 'data/src/redux/dialog/types'
import { useAppDispatch } from 'web/src/redux/hooks/hooks'

const HeadingTitle = styled(H3, {
  textAlign: 'left',
  fontWeight: 600,
  fontSize: 24,
  $gtDesktop: {
    fontSize: 28,
  },
})

export const Subscription = () => {
  const dispatch = useAppDispatch()

  const openCancelSubscriptionDialog = () => {
    dispatch(
      doShowDialog({
        callbackOnConfirm: () => console.log('Subscription cancelation confirmed'),
        titleTextId: 'account.subscription.confirmCancelationModal.title',
        bodyTextId: 'account.subscription.confirmCancelationModal.body',
        buttonCancelTextId: 'actions.no',
        buttonConfirmTextId: 'actions.yes',
        type: DialogType.ALERT,
      })
    )
  }

  return (
    <Page title="Subscription | Virgin Red">
      <MyAccountBackground>
        <YStack gap="$2">
          <HeadingTitle>{getString('account.subscription.title')}</HeadingTitle>
          <Button onClick={openCancelSubscriptionDialog} size="$4" marginTop="$2" themeInverse fontWeight="500">
            {getString('account.subscription.cancel')}
          </Button>
        </YStack>
      </MyAccountBackground>
    </Page>
  )
}
