import React from 'react'
import { ScrollView, Text, YStack, styled } from '@red-ui/components'
import { ContentfulEntry } from 'data/src/utils/contentfulPages'

const BlockContainer = styled(YStack, {
  flex: 1,
  width: '100%',
  height: 100,
  borderColor: '$black10',
  borderWidth: 1,
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
})

const JsonScrollView = styled(ScrollView, {
  width: '100%',
  padding: '$1',
})

export const DynamicPlaceholder = ({ content }: { content: ContentfulEntry }) => (
  <BlockContainer>
    <Text>Block component for type "{content.sys.contentType?.sys.id}" not yet created.</Text>
    <JsonScrollView>{JSON.stringify(content, null, 2)}</JsonScrollView>
  </BlockContainer>
)
