import { connect } from 'react-redux'

import { getOrderIds, getOrdersEntities, getHasLoaded as getHasOrdersLoaded } from 'data/src/redux/orders/selectors'
import { doFetchOrders } from 'data/src/redux/orders/dispatchers'
import {
  getHasLoaded as getHasVouchersLoaded,
  getVoucherIds,
  getVouchers,
  getIsMoreAvailable,
  getMyRewardsRoute,
} from 'data/src/redux/wallet/selectors'
import { doRefreshVouchers, doAppendVouchers, doSaveMyRewardsRoute } from 'data/src/redux/wallet/dispatchers'
import { RootState, DispatchFunction } from '../../../redux/types'
import { MyRewardsPrivate, MyRewardsPrivateStateProps, MyRewardsPrivateDispatchProps } from './MyRewardsPrivate'

const mapStateToProps = (state: RootState): MyRewardsPrivateStateProps => {
  return {
    orderIds: getOrderIds(state),
    orders: getOrdersEntities(state),
    hasOrdersLoaded: getHasOrdersLoaded(state),
    voucherIds: getVoucherIds(state),
    vouchers: getVouchers(state),
    hasVouchersLoaded: getHasVouchersLoaded(state),
    moreAvailable: getIsMoreAvailable(state),
    myRewardRoute: getMyRewardsRoute(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction): MyRewardsPrivateDispatchProps => {
  return {
    onMount: (): void => {
      dispatch(doFetchOrders())
      dispatch(doRefreshVouchers())
    },
    onLoadMore: (): void => {
      dispatch(doAppendVouchers())
    },
    onClickTab: (key: string): void => {
      dispatch(doSaveMyRewardsRoute(key))
    },
  }
}

export const MyRewardsPrivateContainer = connect(mapStateToProps, mapDispatchToProps)(MyRewardsPrivate)
