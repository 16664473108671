import React, { FC } from 'react'

import { getString } from 'data'
import { color, spacing } from '../../style/variables'
import { media } from '../../style/media'

import { Content } from '../../components'
import H1Header from '../../components/typography/Red/H1Header'
import RedCardLayout from '../../components/RedCardLayout'
import RedSignUpHeader from '../../components/RedSignUpHeader'
import { Button } from '../../components/Buttons/Button'
import { PUBLIC_URL } from '../../config'

interface MyRewardsPublicProps {
  doLogin: Function
}

const MyRewardsPublic: FC<MyRewardsPublicProps> = ({ doLogin }) => {
  return (
    <>
      <style jsx>{`
        .buttons-wrapper {
          display: flex;
          margin-top: 16px;
          justify-content: center;
        }
        .join-red-button {
          width: 160px;
          margin-right: 12px;
        }
        .log-in-button {
          width: 140px;
        }
        .content {
          text-align: center;
        }
        .rewards-background {
          background-color: ${color.turquoiseGreen};
        }

        @media ${media.tabletAndHigher} {
          .buttons-wrapper {
            margin-bottom: 40px;
            margin-top: 40px;
          }
        }

        @media ${media.desktopAndHigher} {
          .buttons-wrapper {
            justify-content: flex-start;
            margin-bottom: 0;
            margin-top: 40px;
          }
          .content {
            text-align: left;
          }
        }
      `}</style>
      <div className="rewards-background">
        <Content>
          <H1Header
            color={color.textHeader}
            textAlign="center"
            marginTop={spacing.xLarge}
            marginBottom={spacing.small}
            textTransform="uppercase"
          >
            My Rewards
          </H1Header>
          <RedCardLayout
            imageAlt="Man in sunglasses"
            cardVerticalOffset={{ desktop: 48 }}
            contentPaddingTop={{ mobile: 0, tablet: 0 }}
            imageUrl={`${PUBLIC_URL}/img/home-logged-out.jpg`}
            imageCaption=""
            tabletAndLowerImageHeight="calc(100vw * 0.564)"
          >
            <RedSignUpHeader
              alignment={{
                desktop: 'left',
                tablet: 'center',
                mobile: 'center',
              }}
            >
              JOIN THE CLUB
            </RedSignUpHeader>
            <div className="content">
              Join our club and start grabbing life with both hands. There's a whole world of extraordinary experiences at your fingertips.
              <br />
              <br />
              Already a member? Login to explore all the ways to earn and spend Virgin Points.
            </div>
            <div className="buttons-wrapper">
              <div className="join-red-button">
                <Button onClick={() => doLogin()} text={getString('loginBox.button')} isPrimary display="block" />
              </div>
              <div className="log-in-button">
                <Button onClick={() => doLogin()} text={getString('loginBox.memberQuery.link')} isPrimary={false} display="block" />
              </div>
            </div>
          </RedCardLayout>
        </Content>
      </div>
    </>
  )
}

export { MyRewardsPublic }
