import * as React from 'react'
import { InPageNavigationItem, IPageNavItem } from './InPageNavigationItem'

interface IPageNavigation {
  navItems: IPageNavItem[]
}

export const InPageNavigation: React.FC<IPageNavigation> = ({ navItems }) => (
  <>
    <style jsx>{`
      .unstyled {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    `}</style>
    <nav>
      <ul className="unstyled">
        {navItems && navItems.map(({ linkUrl, linkText }) => <InPageNavigationItem linkUrl={linkUrl} linkText={linkText} key={linkUrl} />)}
      </ul>
    </nav>
  </>
)
