import * as React from 'react'
import { Content, ToggleSlider } from 'web/src/components'
import MyRewardsResults from './MyRewardsResults'
import OrdersLister from './OrdersLister'
import { Order } from 'data/src/redux/orders/types'
import { MyRewardsTab } from './MyRewardsPrivate'

interface RenderOrdersProps {
  hasOrdersLoaded: boolean
  myRewardRoute: string | null
  orderIds: string[]
  orderTiles: Order[]
  tabs: MyRewardsTab[]
}

export const RenderOrders: React.FC<RenderOrdersProps> = ({ hasOrdersLoaded, myRewardRoute, orderIds, orderTiles, tabs }) => (
  <>
    <style>{`
      .slider {
        display: flex;
        justify-content: center;
      }
    `}</style>
    <Content>
      <ToggleSlider selected={myRewardRoute} tabs={tabs} />
    </Content>
    <MyRewardsResults isEmpty={orderIds.length === 0} isLoading={!hasOrdersLoaded}>
      <OrdersLister orders={orderTiles} />
    </MyRewardsResults>
  </>
)
