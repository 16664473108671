import React, { FC } from 'react'

import { getString, getStringOrNull } from 'data'

import { color, FontFamilies } from '../../style/variables'
import { media } from '../../style/media'
import { Content } from '../layout'
import HelpSVG from './HelpSVG'

interface HelpCTAProps {
  heading?: string
  subheading?: string
  linkText?: string
}

const HelpCTA: FC<HelpCTAProps> = ({ heading, subheading, linkText }) => {
  return (
    <>
      <style jsx>{`
        .container {
          padding: 48px 15px;
        }
        .heading {
          font-family: ${FontFamilies.barlow};
          font-size: 32px;
          font-weight: 600;
          font-style: italic;
          letter-spacing: -0.32px;
          text-align: center;
          color: ${color.textHeader};
          margin: 32px 0;
          text-transform: uppercase;
        }
        .sub-heading {
          margin: 0 auto;
          font-size: 20px;
          text-align: center;
          font-family: ${FontFamilies.barlow};
          color: ${color.textStandard};
        }
        .wrapper {
          margin-top: 64px;
        }
        a {
          color: ${color.brandPrimary};
          text-decoration: none;
          font-weight: bold;
        }
        a:hover {
          text-decoration: unset;
        }
        @media ${media.tabletAndHigher} {
          .container {
            padding: 64px 15px;
          }
          .heading {
            font-size: 40px;
            letter-spacing: -0.4px;
          }
          .sub-heading {
            margin: 24px 20px 0;
            font-size: 28px;
          }
        }
        @media ${media.desktopAndHigher} {
          .container {
            padding: 88px 15px;
          }
          .heading {
            font-size: 44px;
            letter-spacing: -0.44px;
          }
        }
      `}</style>
      <div className="wrapper">
        <div className="container">
          <Content>
            <HelpSVG />
            {heading && <div className="heading">{getString(heading)}</div>}
            <div className="sub-heading">
              {getStringOrNull(subheading || 'helpCTA.subheading')}
              <br />
              <a href={getString('link.CTA.help')} target="_blank" rel="noopener noreferrer">
                {getString(linkText || 'helpCTA.linkText')}
              </a>
            </div>
          </Content>
        </div>
      </div>
    </>
  )
}

export { HelpCTA }
