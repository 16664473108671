import { useEffect, useMemo } from 'react'
import { doLoginWeb } from 'data/src/redux/auth/dispatchers'
import { useAppDispatch } from '../../redux/hooks/hooks'
import { useLocation } from 'react-router-dom'
import { useIncentiveCodes, INCENTIVE_CODES_KEY } from '../../helpers'

export const Login = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const queryParams = useMemo(() => new URLSearchParams(search).getAll(INCENTIVE_CODES_KEY), [search])

  // calling simply sets the codes
  useIncentiveCodes(queryParams.length > 0 ? queryParams : undefined)

  useEffect(() => {
    dispatch(doLoginWeb())
  }, [dispatch])

  return null
}
