import { connect } from 'react-redux'

import { doUpdateRewardsPaginated } from 'data/src/redux/discovery/dispatchers'
import { doFetchHomePageRewards } from 'data/src/redux/rewards/dispatchers'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist, doGetWishlist } from 'data/src/redux/wishlist/dispatchers'
import { getIsFiltersChanged, getIsLoading, getVisibleSpendRewardIds, getPagination } from 'data/src/redux/discovery/selectors'
import {
  getAllRewardsIds as getAllSpendRewardsIds,
  getIsLoading as getIsLoadingAllSpend,
  getHasLoaded as getHasLoadedAllSpend,
  getAllRewardsEntities,
} from 'data/src/redux/rewards/selectors'
import { getWishlistRewards, getIsLoadingWishlist } from 'data/src/redux/wishlist/selectors'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'
import { RewardsList } from './RewardsList'
import { getCategories } from 'data/src/redux/categories/selectors'
import { toggleCategorySelected } from 'data/src/redux/discovery/actions'

const mapStateToProps = (state: RootState, ownProps: { ignoreFilters?: boolean }) => {
  return {
    categories: getCategories(state),
    rewards: getAllRewardsEntities(state),
    rewardIds: ownProps.ignoreFilters ? getAllSpendRewardsIds(state) : getVisibleSpendRewardIds(state),
    earn: false,
    isLoading: ownProps.ignoreFilters ? getIsLoadingAllSpend(state) : getIsLoading(state),
    hasLoaded: ownProps.ignoreFilters ? getHasLoadedAllSpend(state) : !getIsFiltersChanged(state),
    pagination: getPagination(state),
    showWishListIcons: true,
    wishlist: getWishlistRewards(state),
    isLoadingWishlist: getIsLoadingWishlist(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction, ownProps: { ignoreFilters?: boolean }) =>
  bindTypedActionCreators(
    {
      doRefreshRewards: (loadMoreClicked?: boolean) =>
        ownProps.ignoreFilters ? doFetchHomePageRewards : doUpdateRewardsPaginated(loadMoreClicked),
      doGetWishlist,
      doRemoveRewardFromWishlist,
      doAddRewardToWishlist,
      toggleCategorySelected: toggleCategorySelected,
      doUpdateRewards: doUpdateRewardsPaginated,
    },
    dispatch
  )

export const PrivateSpendRewardsListContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsList)
