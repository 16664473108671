import React from 'react'
import { getAllOrderedRewardsEntities as getEarnRewards } from 'data/src/redux/rewardsEarn/selectors'
import { getAllOrderedRewardsEntities as getSpendRewards } from 'data/src/redux/rewards/selectors'
import { getRewardsEntities as getPublicEarnRewards } from 'data/src/redux/rewardsEarnPublic/selectors'
import { getAllOrderedRewardsEntities as getPublicSpendRewards } from 'data/src/redux/rewardsPublic/selectors'
import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { Reward } from 'data/src/redux/rewards/types'
import { RewardEarn } from 'data/src/redux/rewardsEarn/types'
import { convertRewardToCard } from 'data/src/utils/convertRewardToCard'
import { getIsLoadingWishlist, getWishlistRewards } from 'data/src/redux/wishlist/selectors'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist } from 'data/src/redux/wishlist/dispatchers'
import { getIsLoadingEarnPublic } from 'data/src/redux/discoveryEarnPublic/selectors'
import { getIsLoadingEarn } from 'data/src/redux/discoveryEarn/selectors'
import { getIsLoading as getIsLoadingSpendPublic } from 'data/src/redux/discoveryPublic/selectors'
import { getIsLoading } from 'data/src/redux/discovery/selectors'
import { media } from 'web/src/style/media'
import { useAppDispatch, useAppSelector } from 'web/src/redux/hooks/hooks'
import { color, tileSize } from 'web/src/style/variables'
import { IntroCarouselTile } from 'web/src/components/ContentfulBlock/IntroCarouselBlock/IntroCarouselTile'
import { LoadSpinner } from 'web/src/components'
import { Tabs } from '..'

export interface RewardsListProps {
  brandName: string
  selectedTab: Tabs
  delayRender: boolean
}

type RewardsData = {
  [rewardId: string]: RewardEarn | Reward
}

const buildRewardsList = (rewards: RewardsData, brandName: RewardsListProps['brandName']) => {
  const rewardsList = []

  for (const key in rewards) {
    const reward = rewards[key]
    if (reward.content.brandDetails.brandName === brandName) {
      rewardsList.push(reward)
    }
  }
  if (rewardsList.length === 0) return null
  return convertRewardToCard(rewardsList)
}

const itemsPerRow = {
  smallMobile: 1,
  mobile: 2,
  tablet: 3,
  desktop: 4,
}

export const RewardsList = ({ brandName, selectedTab, delayRender }: RewardsListProps) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const wishlist = useAppSelector(getWishlistRewards)
  const isLoadingWishlist = useAppSelector(getIsLoadingWishlist)

  const earnRewards = useAppSelector(getEarnRewards)
  const spendRewards = useAppSelector(getSpendRewards)
  const publicEarnRewards = useAppSelector(getPublicEarnRewards)
  const publicSpendRewards = useAppSelector(getPublicSpendRewards)

  const earnRewardsLoading = useAppSelector(getIsLoadingEarn)
  const spendRewardsLoading = useAppSelector(getIsLoading)
  const publicEarnRewardsLoading = useAppSelector(getIsLoadingEarnPublic)
  const publicSpendRewardsLoading = useAppSelector(getIsLoadingSpendPublic)

  const rewardData = {
    earn: {
      public: { ...publicEarnRewards },
      private: { ...earnRewards },
    },
    spend: {
      public: { ...publicSpendRewards },
      private: { ...spendRewards },
    },
  }

  const isLoading = [earnRewardsLoading, spendRewardsLoading, publicEarnRewardsLoading, publicSpendRewardsLoading].some(
    (loading) => loading === true
  )

  const isOnWishlist = (rewardId: string) => {
    const reward = wishlist?.rewards.find((reward) => reward.rewardId === rewardId)

    return reward !== undefined
  }

  const handleOnWishlistClick = (rewardId: string, type: string) => {
    const wishlistType = type === 'reward' ? 'SPEND' : 'EARN'

    return isOnWishlist(rewardId)
      ? dispatch(doRemoveRewardFromWishlist(rewardId, wishlistType))
      : dispatch(doAddRewardToWishlist(rewardId, wishlistType))
  }

  const rewardsForBrand = {
    earn: buildRewardsList(rewardData['earn'][isAuthenticated ? 'private' : 'public'], brandName),
    spend: buildRewardsList(rewardData['spend'][isAuthenticated ? 'private' : 'public'], brandName),
  }
  const rewardsList = rewardsForBrand[selectedTab] ?? rewardsForBrand.spend ?? []
  return (
    <>
      <style jsx>{`
        .tiles-wrapper {
          display: flex;
          flex-wrap: wrap;
          -webkit-box-pack: start;
          justify-content: flex-start;
          margin-top: 10px;
          margin-bottom: 24px;
        }
        .tile-wrapper {
          position: relative;
          background-color: ${color.white};
          border: solid 1px ${color.secondaryButtonBorder};
          border-radius: 8px;
          margin-top: 16px;
          flex-direction: column;
          display: flex;
        }
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
        .tile-lister-spinner {
          align-items: center;
          display: flex;
          min-height: 200px;
        }
        .tile-wrapper :global(.inventory-card-logo) {
          width: 66px;
          height: 66px;
        }
        .tile-wrapper :global(.inventory-card-content) {
          height: auto;
          min-height: 157px;
        }
        @media ${media.mobileLargeAndLower} {
          .tile-wrapper :global(.inventory-card-content .header-three) {
            font-size: 20px !important;
          }
          .tile-wrapper :global(.inventory-card-content .body) {
            font-size: 16px !important;
          }
          .tile-wrapper :global(.inventory-card-content .inventory-card-points) {
            font-size: 20px !important;
          }
        }
        @media ${media.mobileLargeAndHigher} {
          .tiles-wrapper {
            margin-left: -24px;
            margin-bottom: 40px;
          }
          .tile-wrapper {
            width: calc(100% / ${itemsPerRow.mobile} - ${tileSize.margin}px);
            margin-left: 24px;
          }
        }
        @media ${media.tabletAndHigher} {
          .tile-wrapper {
            width: calc(100% / ${itemsPerRow.tablet} - ${tileSize.margin}px);
            margin-top: 24px;
          }
          .tile-wrapper :global(.inventory-card-logo) {
            width: 45px;
            height: 45px;
          }
          .tile-wrapper :global(.inventory-card-content) {
            height: auto;
            min-height: 166px;
          }
        }
        @media ${media.desktopAndHigher} {
          .tiles-wrapper {
            margin-bottom: 72px;
          }
          .tile-wrapper {
            width: calc(100% / ${itemsPerRow.desktop} - ${tileSize.margin}px);
          }
          .tile-wrapper :global(.inventory-card-logo) {
            width: 66px;
            height: 66px;
          }

          .tile-wrapper :global(.inventory-card-content) {
            height: auto;
            min-height: 229px;
          }
        }
      `}</style>
      {isLoading || delayRender ? (
        <div className="tile-lister-spinner">
          <LoadSpinner />
        </div>
      ) : (
        <>
          <div role="status" className="sr-only">
            {`${rewardsList.length} results returned`}
          </div>
          <div className="tiles-wrapper" role="listbox">
            {rewardsList.map((reward) => (
              <div className="tile-wrapper border" key={reward.rewardId}>
                <IntroCarouselTile
                  {...reward}
                  brandLogoMobileSize={66}
                  isWished={isOnWishlist(reward.rewardId)}
                  onWishListIconClick={() => handleOnWishlistClick(reward.rewardId, reward.type)}
                  isLoadingWishlist={isLoadingWishlist}
                  wishlistIconPosition={6}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}
