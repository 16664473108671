import { AuthOptions, WebAuth } from 'auth0-js'

import { logger } from 'data/src/utils'

import { AUTH0_AUTH_URL, AUTH0_PARTNER_CONNECT_CLIENT_ID, AUTH0_API_AUDIENCE } from '../config'
import { getPartnerLoginParams, persistPartnerLinkIdToken, setPartnerLoginParams } from './partnerConnectWeb'

const redBasePath = `${window.location.protocol}//${window.location.host}/virgin-red`

const partnerAuthOptions: AuthOptions = {
  domain: String(AUTH0_AUTH_URL).replace('https://', '') || '',
  clientID: AUTH0_PARTNER_CONNECT_CLIENT_ID || '',
  redirectUri: `${redBasePath}/partner-login-success/`,
  responseType: 'token id_token',
  scope: 'execute_api:user openid profile email',
  audience: AUTH0_API_AUDIENCE,
}

const auth0 = new WebAuth(partnerAuthOptions)

const partnerLogin = () => {
  const { connectPartnerCode, loginEmail, connectionId } = getPartnerLoginParams()
  let authOptions
  if (connectPartnerCode && loginEmail && connectionId) {
    authOptions = {
      connection: connectionId,
      login_hint: loginEmail,
    }
    try {
      auth0.authorize(authOptions)
    } catch (error) {
      logger.warn('auth0Client.ts:authenticate() failed: ', error)
    }
  }
}

// This function must be a promise or there is a chance of sync issues
const storePartnerLoginTokens = () =>
  new Promise((resolve) => {
    auth0.parseHash((error, result) => {
      if (error) {
        logger.warn('auth0Client.ts:parseHash() failed: ', error)
      }

      if (result?.accessToken && result?.idToken) {
        const { accessToken, idToken } = result
        const authResults = [
          { name: 'partnerLinkAccessToken', value: accessToken },
          { name: 'partnerLinkIdToken', value: idToken },
        ]
        setPartnerLoginParams(authResults)
        persistPartnerLinkIdToken(idToken)
      }

      resolve(null)
    })
  })

export { partnerLogin, storePartnerLoginTokens }
