import { formatRewardTiles } from '../formatRewardTiles'
import { TilesType } from '../..'
import { Reward } from 'data/src/redux/rewards/types'

export type CmsRewardIds = Array<string>
export type CmsRewardLookup = {
  [rewardId: string]: Reward
}

export interface CmsRewardsProps {
  rewardIds: CmsRewardIds
  rewards: CmsRewardLookup
}

const convertCmsRewardsToTiles = (
  rewardIds: Array<string>,
  rewards: {
    [rewardIds: string]: Reward
  }
): TilesType => {
  return rewardIds.map((id) => formatRewardTiles(rewards[id]))
}

export { convertCmsRewardsToTiles }
