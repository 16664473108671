import React, { FC } from 'react'

import { formatIsoDateToDateWithMonthString } from 'data/src/utils/formatters'
import { getString } from 'data/src/utils'

import { color, spacing } from '../../../style/variables'
import { media } from '../../../style/media'
import { CustomLink, H3Header, Body } from '../../../components'
import { PATHS } from '../../../router/paths'

import { CurvedContainer } from '../../CurvedContainer'
import { CroppedImage } from '../../image'

import { VoucherTileProps } from './types'

const VoucherTile: FC<VoucherTileProps> = ({ voucherId, imageUrl, brandLogoUrl, brandName, title, lead, validTo }) => {
  const cutoutRadius = 13
  const headerMargins = spacing.zero
  return (
    <>
      <style jsx>{`
        .ticket {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          height: 100%;
          text-decoration: none;
        }
        .border:hover,
        .ticket:hover .border {
          border-color: ${color.authorGrey};
        }
        .border {
          border: ${color.secondaryButtonBorder} solid 1px;
        }
        .voucher-main-image {
          border-radius: 8px 8px 0 0;
          height: 200px;
          overflow: hidden;
          width: 100%;
        }
        .logo-image {
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
          height: 72px;
          left: 16px;
          position: absolute;
          top: 164px;
          width: 72px;
          z-index: 1;
        }
        .content {
          padding: 0 16px;
          width: 100%;
        }
        .title {
          flex-grow: 1;
          margin-bottom: 20px;
          margin-top: 56px;
        }
        .cutouts {
          height: ${cutoutRadius * 2}px;
          position: relative;
          width: 100%;
        }
        .cutout {
          background-color: white;
          height: ${cutoutRadius * 2}px;
          position: absolute;
          width: ${cutoutRadius}px;
        }
        .left-cutout {
          border-bottom-right-radius: ${cutoutRadius}px;
          border-left: 0;
          border-top-right-radius: ${cutoutRadius}px;
          left: -1px;
        }
        .separator {
          background-image: linear-gradient(to right, ${color.secondaryButtonBorder} 20%, ${color.white} 0%);
          background-position: top;
          background-repeat: repeat-x;
          background-size: 10px 2px;
          height: 100%;
          left: 24px;
          position: absolute;
          right: 24px;
          top: ${cutoutRadius - 1}px;
        }
        .right-cutout {
          border-bottom-left-radius: ${cutoutRadius}px;
          border-right: 0;
          border-top-left-radius: ${cutoutRadius}px;
          right: -1px;
        }
        .description {
          margin: 6px 0 20px 0;
          min-height: 72px;
        }
        .view-voucher {
          color: ${color.brandPrimary};
          font-weight: bold;
          margin-bottom: 20px;
          text-align: center;
        }
        .expiry-date {
          font-size: 16px;
          line-height: 1.5;
          color: ${color.textStandard};
          margin-bottom: 13px;
        }
        .bold {
          font-weight: 500;
        }

        @media ${media.desktopAndHigher} {
          .voucher-main-image {
            height: 246px;
          }
          .logo-image {
            top: 185px;
          }
          .title {
            margin-bottom: 24px;
            margin-top: 32px;
          }
          .description {
            margin-bottom: 24px;
            margin-top: 6px;
          }
          .view-voucher {
            margin-bottom: 24px;
          }
        }
      `}</style>
      <CustomLink to={`/virgin-red${PATHS.MY_REWARDS_VOUCHERS}/${voucherId}`}>
        <div className="ticket border">
          <CurvedContainer position="bottom" height={54}>
            <div className="voucher-main-image">
              <CroppedImage imgSrc={imageUrl} altText={`${title} voucher image`} />
            </div>
            {brandLogoUrl && <img src={brandLogoUrl} alt={`${brandName} logo`} className="logo-image" />}
          </CurvedContainer>
          <div className="content title">
            <H3Header textAlign="left" marginTop={headerMargins} marginBottom={headerMargins}>
              {title}
            </H3Header>
          </div>
          <div className="cutouts">
            <div className="cutout left-cutout border" />
            <div className="separator" />
            <div className="cutout right-cutout border" />
          </div>
          <div className="content description">
            {validTo && (
              <div className={`expiry-date ${validTo}`}>
                <span className="bold">{getString('voucher.validUntil')}: </span>
                {formatIsoDateToDateWithMonthString(validTo)}
              </div>
            )}
            {lead && (
              <Body marginTop={headerMargins} marginBottom={headerMargins}>
                {lead}
              </Body>
            )}
          </div>
          <div className="view-voucher">View voucher</div>
        </div>
      </CustomLink>
    </>
  )
}

export { VoucherTile }
