import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { getProfileData } from 'data/src/redux/profile/selectors'
import { CookiePreferenceId, getCookiePreferences } from '../helpers/cookiePreferences'
import { initDatadog } from '../dataImplementation/datadog'
import { initBraze } from '../dataImplementation/braze'
import { useEffect } from 'react'
import { useAppSelector } from '../redux/hooks/hooks'
import { useBraze } from '../context/BrazeProvider'

export function useThirdPartyInit() {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const profile = useAppSelector(getProfileData)
  const { setIsBrazeInitialised } = useBraze()

  useEffect(() => {
    const interval = setInterval(() => {
      const cookiePreferences = getCookiePreferences()
      if (!cookiePreferences) {
        return
      }

      clearInterval(interval)

      initDatadog()

      initBraze({
        areAnalyticalCookiesEnabled: cookiePreferences[CookiePreferenceId.Analytics],
        isAuthenticated,
        externalId: profile?.redUserId ?? '',
        setIsBrazeInitialised,
      })
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [setIsBrazeInitialised, isAuthenticated, profile?.redUserId])
}
