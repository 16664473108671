import React from 'react'
import { Page } from '../../components'
import { YStack, H2, H3 } from '@red-ui/components/'
import { useParams } from 'react-router-dom'
import { getString } from 'data'

export const SubscriptionPayment = () => {
  const { planId } = useParams()
  return (
    <Page title={getString('subscriptionPayment.page.title')}>
      <YStack paddingLeft="$7" paddingTop="$4" height="100vh" $mobile={{ paddingLeft: '$12' }}>
        <H2>Subscription payment form</H2>
        <H3>Plan ID: {planId}</H3>
      </YStack>
    </Page>
  )
}
