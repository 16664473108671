import { YStack, styled, H1, Text } from '@red-ui/components/'

export const CARD_WIDTH = 424

export const Content = styled(YStack, {
  minHeight: '100vh',
  padding: '$6',
  $mobile: {
    paddingHorizontal: '$2',
    paddingVertical: '$4',
  },
  backgroundColor: '$black10',
  alignItems: 'center',
})

export const Heading = styled(H1, {
  fontStyle: 'italic',
  alignSelf: 'center',
  color: '$white',
  fontWeight: 600,
  textAlign: 'center',
})

export const Subheading = styled(Text, {
  color: '$white',
  fontSize: '$5',
  textAlign: 'center',
  marginTop: '$3',
  marginBottom: '$6',
})
