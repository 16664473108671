import React, { FC } from 'react'
import SocialMediaIconMapper from './SocialMediaIconMapper'

export type SocialMediaType = 'facebook' | 'twitter' | 'instagram' | 'linkedin' | 'youtube' | 'quartz'

export interface SocialMediaIcons {
  socialMediaName: SocialMediaType
  href: string
}

export const socialMediaIcons: Array<SocialMediaIcons> = [
  {
    href: 'https://www.facebook.com/virgin',
    socialMediaName: 'facebook',
  },
  {
    href: 'https://twitter.com/virgin',
    socialMediaName: 'twitter',
  },
  {
    href: 'https://instagram.com/virgin',
    socialMediaName: 'instagram',
  },
  {
    href: 'https://www.youtube.com/virgin',
    socialMediaName: 'youtube',
  },
  {
    href: 'https://www.linkedin.com/company/virgin',
    socialMediaName: 'linkedin',
  },
]

const SocialMediaLinks: FC = () => {
  return (
    <>
      <style jsx>
        {`
          .social-icons {
            display: flex;
            justify-content: space-evenly;
            list-style-type: none;
            margin: 0 auto;
            padding: 20px 0;
            width: 200px;
          }
          .social-icon {
            align-items: center;
            display: flex;
            height: 44px;
            justify-content: center;
            margin: 0;
            transition: 0.25s;
            width: 44px;
          }
          .social-icon a {
            vertical-align: middle;
          }
          .social-icon:hover {
            opacity: 0.7;
          }
        `}
      </style>
      <div className="social-icons">
        {socialMediaIcons.map(({ socialMediaName, href }) => {
          return (
            <a className="social-icon" key={socialMediaName} href={href} target="_blank" rel="noopener noreferrer">
              <SocialMediaIconMapper icon={socialMediaName} color="#1A1A1A" size={20} />
            </a>
          )
        })}
      </div>
    </>
  )
}

export default SocialMediaLinks
