import { connect } from 'react-redux'

import {
  getIsFiltersChangedEarn,
  getIsFiltersSetEarn,
  getIsLastResultFilteredEarn,
  getSelectedCategoriesEarn,
} from 'data/src/redux/discoveryEarn/selectors'
import { doUpdateRewardsEarn } from 'data/src/redux/discoveryEarn/dispatchers'
import { clearCategoryEarnFilter, toggleCategorySelectedEarn } from 'data/src/redux/discoveryEarn/actions'
import { doFetchCategoriesEarn } from 'data/src/redux/categoriesEarn/dispatchers'
import { getCategoriesEarn } from 'data/src/redux/categoriesEarn/selectors'

import { RootState, DispatchFunction } from '../../redux/types'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'

import { RewardsFilter } from './RewardsFilter'

const mapStateToProps = (state: RootState) => ({
  categories: getCategoriesEarn(state),
  selectedCategories: getSelectedCategoriesEarn(state),
  isFiltersChanged: getIsFiltersChangedEarn(state),
  isLastResultsFiltered: getIsLastResultFilteredEarn(state),
  isFiltersSet: getIsFiltersSetEarn(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      toggleCategorySelected: toggleCategorySelectedEarn,
      clearCategoryFilter: clearCategoryEarnFilter,
      clearAllFilters: clearCategoryEarnFilter,
      doFetchCategories: doFetchCategoriesEarn,
      doUpdateRewards: doUpdateRewardsEarn,
    },
    dispatch
  )

export const PrivateRewardsFilterEarnContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsFilter)
