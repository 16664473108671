import { getIsAuthenticated } from 'data/src/redux/auth/selectors'
import { doExternalLogin } from 'data/src/redux/auth/dispatchers'
import React, { useEffect } from 'react'
import { PathRouteProps, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { PATHS, virginRedPath } from '../../router/paths'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

export interface PrivateRouteProps extends PathRouteProps {
  enforceExternalAuth?: boolean
}

/**
 * By default should the user not be auth'ed it will redirect to the home page.
 *
 * This can be change to enforce auth and take the user to the requested path
 *
 */
export const PrivateRoute = ({ enforceExternalAuth, children, ...routeProps }: PrivateRouteProps) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const { pathname, search } = useLocation()
  const navigate = useRedNavigate()

  useEffect(() => {
    if (!isAuthenticated && !enforceExternalAuth) navigate(PATHS.HOME)
  }, [enforceExternalAuth, isAuthenticated, navigate])

  if (!isAuthenticated) {
    if (enforceExternalAuth) {
      // internal navigation assumes all paths added to it are without virginRedPath
      const vRedPathname = pathname.replaceAll(virginRedPath, '')
      const isPartnerVAA = vRedPathname?.startsWith(PATHS.ONBOARDING_PARTNER_LINK_VAA)
      const searchParams = new URLSearchParams(search)
      const connection = isPartnerVAA ? searchParams.get('connectionId') || undefined : undefined

      // important to forward on any searchParams, they are used on other screens
      dispatch(doExternalLogin(`${vRedPathname}${search}`, connection))
    }
    return <></>
  }

  return <>{children}</>
}
