import React, { FC } from 'react'

import { media } from '../../style/media'
import { color, tileSize } from '../../style/variables'
import { RewardTile, RewardTileProps } from './RewardsTile'
import { CtaTile, CtaTileProps } from './CtaTile'
import DoubleCtaTile from './DoubleCtaTile'
import { DoubleCtaTileProps } from './DoubleCtaTile/DoubleCtaTile'
import { LoginCtaTile, LoginCtaTileProps } from './LoginCtaTile'
import { RewardTile as EarnTile, RewardTileProps as EarnTileProps } from './RewardsEarnTile'
import { VoucherTile } from './VoucherTile'
import { Content, H2Header } from '../../components'
import { VoucherTileProps } from './VoucherTile/types'
import { WishedType, Wishlist } from 'data/src/redux/wishlist/types'
import { findWishedReward } from 'data/src/utils'

export type TilesType = Array<RewardTileProps | CtaTileProps | DoubleCtaTileProps | LoginCtaTileProps | EarnTileProps | VoucherTileProps>

export interface TileListerProps {
  title?: string
  tiles: TilesType
  showWishListIcons?: boolean
  wishlist?: Wishlist
  doRemoveRewardFromWishlist?: (rewardId: string, rewardType: WishedType) => void
  doAddRewardToWishlist?: (rewardId: string, rewardType: WishedType) => void
  isLoadingWishlist?: boolean
}

const TileLister: FC<TileListerProps> = ({
  title,
  tiles,
  showWishListIcons = false,
  wishlist,
  doAddRewardToWishlist,
  doRemoveRewardFromWishlist,
  isLoadingWishlist,
}) => {
  const onWishlist = (rewardId: string) => {
    return wishlist?.rewards && findWishedReward(wishlist.rewards, rewardId)
  }

  const getOnWishListIconClick = (rewardId: string, type: string) => {
    const wishlistType = type === 'reward' ? 'SPEND' : 'EARN'
    if (onWishlist(rewardId)) {
      return doRemoveRewardFromWishlist ? () => doRemoveRewardFromWishlist(rewardId, wishlistType) : undefined
    } else {
      return doAddRewardToWishlist ? () => doAddRewardToWishlist(rewardId, wishlistType) : undefined
    }
  }

  return (
    <>
      <style jsx>{`
        .tiles-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-left: ${-1 * tileSize.margin}px;
        }
        .tile-wrapper {
          flex-basis: ${tileSize.width}px;
          position: relative;
          background-color: ${color.white};
          margin-left: ${tileSize.margin}px;
          margin-bottom: 8px;
          flex-direction: column;
          display: flex;
        }
        .border {
          border: solid 1px ${color.secondaryButtonBorder};
          border-radius: 8px;
        }
        .tile-container {
          max-width: ${tileSize.width}px;
          margin: 40px auto;
        }
        @media ${media.tabletAndHigher} {
          .tile-container {
            width: ${tileSize.width * 2 + tileSize.margin}px;
            max-width: initial;
            margin: 64px auto 32px;
          }
          .tile-wrapper {
            margin-bottom: 32px;
            flex: 0 0 ${tileSize.width}px;
          }

          .double-tile-wrapper {
            flex: 0 0 ${tileSize.width * 2 + tileSize.margin}px;
            max-width: ${tileSize.width * 2 + tileSize.margin}px;
          }
        }
        @media ${media.desktopAndHigher} {
          .tile-container {
            width: ${tileSize.desktopWidth * 2 + 1 * tileSize.margin}px;
            margin: 40px auto 64px;
          }
          .tile-wrapper {
            flex: 0 0 ${tileSize.desktopWidth}px;
            margin-bottom: 20px;
          }

          .double-tile-wrapper {
            flex: 0 0 ${tileSize.desktopWidth * 2 + tileSize.margin}px;
            max-width: ${tileSize.desktopWidth * 2 + tileSize.margin}px;
          }
        }
        @media ${media.hiResDesktop} {
          .tile-container {
            width: ${tileSize.desktopWidth * 3 + 2 * tileSize.margin}px;
          }
        }
      `}</style>
      <Content>
        <div className="tile-container" data-testid="tile-container">
          {title && (
            <H2Header textAlign="center" fontStyle="italic" weight={600} marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}>
              {title}
            </H2Header>
          )}
          <div className="tiles-wrapper" data-testid="tiles-wrapper">
            {tiles.map((tile) => {
              switch (tile.type) {
                case 'reward':
                  return (
                    <div
                      className="tile-wrapper border"
                      key={tile.rewardId}
                      data-testid={`reward-tile-${tile.rewardId}`}
                      data-rewardid={tile.rewardId}
                    >
                      <RewardTile
                        {...(tile as RewardTileProps)}
                        showWishListIcons={showWishListIcons}
                        isWished={onWishlist(tile.rewardId)}
                        onWishListIconClick={getOnWishListIconClick(tile.rewardId, tile.type)}
                        isLoadingWishlist={isLoadingWishlist}
                      />
                    </div>
                  )
                case 'cta':
                  return (
                    <div className="tile-wrapper border" key={tile.title}>
                      <CtaTile {...tile} />
                    </div>
                  )
                case 'doubleCta':
                  return (
                    <div className="tile-wrapper double-tile-wrapper border" key={tile.id}>
                      <DoubleCtaTile {...tile} />
                    </div>
                  )
                case 'loginCta':
                  return (
                    <div className="tile-wrapper double-tile-wrapper border" key={tile.id}>
                      <LoginCtaTile {...tile} />
                    </div>
                  )
                case 'earn':
                  return (
                    <div
                      className="tile-wrapper border"
                      key={tile.rewardId}
                      data-testid={`earn-tile-${tile.rewardId}`}
                      data-rewardid={tile.rewardId}
                    >
                      <EarnTile
                        {...tile}
                        showWishListIcons={showWishListIcons}
                        isWished={onWishlist(tile.rewardId)}
                        onWishListIconClick={getOnWishListIconClick(tile.rewardId, tile.type)}
                        isLoadingWishlist={isLoadingWishlist}
                      />
                    </div>
                  )
                case 'voucher':
                  return (
                    <div className="tile-wrapper" key={tile.voucherId}>
                      <VoucherTile {...tile} />
                    </div>
                  )
                default:
                  return <></>
              }
            })}
          </div>
        </div>
      </Content>
    </>
  )
}

export { TileLister }
