import { errorHandler } from '../errorHandler'

import {
  getProfile,
  getProfileSuccess,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  getProfileFailure,
  updateEmail,
  updateEmailFailure,
  updateEmailSuccess,
  verifyEmail,
  verifyEmailFailure,
  verifyEmailSuccess,
} from './actions'

import { clearAllFilters } from '../discovery/actions'
import { clearCategoryEarnFilter } from '../discoveryEarn/actions'
import { getProfileData } from './selectors'
import { getInAutoLinkingFlow } from '../accounts/selectors'
import { DispatchFunction, RedDataState, UserFacingError } from '../types'
import { Profile } from './types'
import { getRedDataConfig } from '../../config'
import { apiPatchProfile, apiGetProfile, apiUpdateEmail } from '../../api/member.api'
import { postLoginRedirect } from '../auth/postLoginRedirect'
import { doLogout } from '../auth/dispatchers'
import { convertUiToApiDate } from '../../utils/formatters'
import { getString } from '../../utils/getString'
import { ViewingRegion } from '../../utils'
import { getLoginStartPath } from '../auth/selectors'
import { clearLoginStartPath } from '../auth/actions'

const doFetchProfile = () => async (dispatch: DispatchFunction) => {
  dispatch(getProfile())
  try {
    const profile = await apiGetProfile()
    dispatch(getProfileSuccess(profile))
    return profile
  } catch (error) {
    errorHandler(dispatch, error, getProfileFailure)
    return null
  }
}

const doPatchProfile = (changes: Partial<Profile>, requestParams?: Record<string, string[]>) => async (dispatch: DispatchFunction) => {
  dispatch(clearAllFilters())
  dispatch(clearCategoryEarnFilter())
  dispatch(updateProfile())
  const payload = {
    ...changes,
    // This function returns undefined if dateOfBirth not in changes
    dateOfBirth: convertUiToApiDate(changes.dateOfBirth),
  } as Partial<Profile>
  try {
    const data = await apiPatchProfile(payload, requestParams)
    dispatch(updateProfileSuccess(data))
    return true
  } catch (error) {
    errorHandler(dispatch, error, updateProfileFailure)
    return false
  }
}

const doSubmitOnboarding =
  (profile: Partial<Profile>, requestParams?: Record<string, string[]>) =>
  async (dispatch: DispatchFunction, getState: () => RedDataState) => {
    const config = getRedDataConfig()
    const succeeded = await doPatchProfile({ ...profile, channel: 'RED_APP' }, requestParams)(dispatch)
    const inAutoLinkingFlow = getInAutoLinkingFlow(getState())
    const loginStartPath = getLoginStartPath(getState())

    if (succeeded && inAutoLinkingFlow) {
      const fullProfile = (await dispatch(doFetchProfile())) as Profile
      if (fullProfile?.appState?.onboarding) {
        const { onboarding } = fullProfile.appState
        onboarding === 'LinkVaa'
          ? config.navigate(config.navTargets.AutoLinkPartner, {}, profile?.country as ViewingRegion)
          : postLoginRedirect(getProfileData(getState()))
      }
    } else if (succeeded) {
      postLoginRedirect(getProfileData(getState()), loginStartPath)
      dispatch(clearLoginStartPath())
    }
  }

const doUpdateEmail = (formData: any) => async (dispatch: DispatchFunction) => {
  dispatch(updateEmail())
  const config = getRedDataConfig()
  try {
    await apiUpdateEmail(formData.email)
    try {
      await config.auth.passwordless(formData.email)
      dispatch(updateEmailSuccess(formData.email))
      config.navigate(config.navTargets.AccountVerifyEmail)
    } catch (error) {
      errorHandler(dispatch, error, updateEmailFailure)
    }
  } catch (error) {
    errorHandler(dispatch, error, updateEmailFailure)
  }
}

const doVerifyEmail = (formData: any) => async (dispatch: DispatchFunction) => {
  dispatch(verifyEmail())
  const config = getRedDataConfig()
  try {
    await config.auth.loginWithEmail(formData.email, formData.code)
    dispatch(verifyEmailSuccess())
    dispatch(doLogout(config.navTargets.EmailUpdated))
    return true
  } catch (untypedError) {
    const error = untypedError as auth0.Auth0Error | Error
    let mappedError = error
    if (
      [(error as Error).message, (error as auth0.Auth0Error).error_description].includes(
        'An account with this email address already exists.'
      )
    ) {
      mappedError = new UserFacingError(getString('profile.verifyEmail.alreadyExistsError'), error.toString())
    }
    errorHandler(dispatch, mappedError, verifyEmailFailure)
    return false
  }
}

export { doFetchProfile, doPatchProfile, doSubmitOnboarding, doUpdateEmail, doVerifyEmail }
